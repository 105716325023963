// Content Width Variable コンテンツ幅変数
// --------------------------------------------------
$cntWidth: 1024px !default;

// Font Variable フォント変数
// --------------------------------------------------
$baseFontSize: 10px !default;
$baseFontFamily: "YakuHanJP", "Noto Sans JP", "Barlow Semi Condensed",
  "Yu Gothic", yugothic, sans-serif !default;
$font-ratin: "Barlow Semi Condensed";
$font-jp: "Noto Sans JP";
// $font-loading: "Dela Gothic One", sans-serif;

// Color Variable カラー変数
// --------------------------------------------------
$color-black: #0d0a0a;
$color-primary: #0070b3;
$color-primary-200: #8ec8e4;
$color-secondary: #ffca36;
$color-secondary-200: #ffeba8;
$color-gray-100: #ededed;
$color-gray-200: #dfdfdf;
$color-gray-300: #e2e2e2;
$color-gray-350: #dbdbdb;
$color-gray: #c4c4c4;
$color-gray-500: #aaa;
$color-gray-600: #a2a2a2;
$color-gray-700: #999;
$color-gray-900: #8d8d8d;
$color-blue: #0070b3;

// Font Variable (English Ver.) 英語版フォント変数
// --------------------------------------------------
$enPrimaryFontSize: 1.8rem !default;
$enBaseFontFamily: "Roboto", "Segoe UI", "San Francisco", "Helvetica Neue", Arial,
  sans-serif !default;
$enBaseLetterSpacing: 0.02em !default;
$enHeadingFont: "Barlow Semi Condensed";


