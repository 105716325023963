.c-list-service {
  margin-top: 20px;
  padding: 0 ();

  @include mq(md) {
    display: flex;
    flex-wrap: wrap;
    margin: 110px -46px -120px;
  }

  &__item {
    @include mq(md) {
      width: 50%;
      padding: 0 46px 120px;
    }

    & + & {
      margin-top: 50px;

      @include mq(md) {
        margin-top: 0;
      }
    }
  }

  &__picture {
    position: relative;
    padding-bottom: (338/600 * 100%);

    &::before,
    &::after {
      @include mq(md) {
        content: "";
        display: block;
        position: absolute;
        bottom: -1px;
        z-index: 1;
        width: 0;
        height: 2px;
        background-color: $color-secondary;
        transition: width 0.3s ease;
      }
    }

    &::before {
      right: 50%;
    }

    &::after {
      left: 50%;
    }

    &:hover {
      &::before,
      &::after {
        @include mq(md) {
          width: 50%;
        }
      }
    }
  }

  &__image {
    display: block;
    position: absolute;

    @include mq(md) {
      transition: all 0.3s ease;
    }
  }

  &__body {
    margin-top: 15px;

    @include mq(md) {
      margin-top: 30px;
    }
  }

  &__number {
    width: 22px;
    font-family: $font-ratin;
    font-size: 1.3rem;
    font-weight: 500;
    color: $color-gray-500;

    @include mq(md) {
      width: 47px;
      margin: 0;
      font-size: 2.6rem;
    }
  }

  &__service {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 1.3rem;
    font-weight: 900;

    @include mq(md) {
      font-size: 1.8rem;
    }
  }

  &__text {
    margin-top: 10px;
    padding-left: 22px;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: (47/32);

    @include mq(md) {
      padding-left: 47px;
      font-size: 2.2rem;
      line-height: (38/22);
    }
  }
}

//以下 English Ver. 記述

.en {
  .c-list-service {
    &__service {
      font-family: $enHeadingFont;
      font-size: 1.5rem;
      font-weight: 800;
      line-height: 1.5;

      @include mq(md) {
        font-size: 2rem;
      }
    }
    &__text {
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 1.5;
      @include mq(md) {
        font-size: 2.4rem;
      }
    }
  }
}
