@charset "UTF-8";

/* ========================================
  Iframe
======================================== */

// Iframe標準モジュール(Google Maps, Youtube埋め込みレスポンシブ対応)
// --------------------------------------------------
.c-iframe {
  display: block;
  position: relative;
  overflow: hidden;
  height: 0;
  // アスペクト比 16:9
  padding-bottom: percentage(9 / 16);

  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
