.l-main {
  margin-top: 50px;

  @include mq(md) {
    margin-top: 100px;
  }

  &--m-no {
    position: relative;
    margin-top: 0;
  }
}
