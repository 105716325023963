.p-work-credit {
  margin: 60px 0;
  padding: 0 (36 / 375 * 100%);

  @include mq(md) {
    margin-top: 160px;
    padding: 0;
  }

  &__inner {
    width: 100%;
    padding: 40px 0;
    border-top: 1px solid $color-gray-200;
    border-bottom: 1px solid $color-gray-200;

    @include mq(md) {
      display: flex;
      justify-content: flex-start;
      padding: 70px 0;
    }
  }

  &__body {
    margin-top: 20px;
    padding: 0 26px;

    @include mq(md) {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin-top: 0;
      padding: 0;
    }
  }

  &__container {
    margin-bottom: 3rem;
  }

  &__list {
    letter-spacing: 0.48px;

    @include mq(md) {
      min-width: 33%;
    }

    & + & {
      @include mq(md) {
        width: 100%;
        margin-left: (190 / 1440 * 100vw);
      }
    }
  }

  &__list-item {
    margin-bottom: 20px;

    @include mq(md) {
      margin-bottom: 2rem;
    }

    &--head {
      font-family: $font-ratin;
      font-size: 1rem;
      font-weight: 500;
      color: $color-gray-600;

      @include mq(md) {
        font-size: 1.1rem;
      }
    }

    &--foot {
      overflow: hidden;
      margin-bottom: 10px;
      font-size: 1.2rem;

      @include mq(md) {
        font-size: 1.4rem;
        font-weight: normal;
      }
    }
  }

  &__list-item-job {
    display: block;
    font-weight: 300;

    @include mq(md) {
      display: inline-block;
      width: 120px;
      margin-right: 1em;
      font-size: 1.3rem;
    }
  }

  &__list-item-name {
    display: inline-block;
    vertical-align: top;
    //float: right;
    //width: calc(100% - (120px + 1em));
  }

  .p-work-credit__list-item--foot.block &__list-item-name {
    display: block;
  }

  &__title {
    position: relative;
    padding-left: 26px;
    font-family: $font-ratin;
    font-size: 1.8rem;
    font-weight: 500;
    color: $color-black;

    @include mq(md) {
      margin-right: (130 / 1440 * 100vw);
      padding-left: 36px;
      font-size: 2.4rem;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 14px;
      height: 1px;
      background-color: $color-black;
      transform: translateY(-50%);

      @include mq(md) {
        top: 2rem;
        width: 24px;
        height: 2px;
      }
    }
  }
}

//以下 English Ver. 記述

.en {
  .p-work-credit {
    &__title {
      font-family: $enHeadingFont;
      font-size: 1.8rem;
      line-height: 1.5;

      @include mq(md) {
        font-size: 2.4rem;
      }
    }

    &__list-item {

      &--head {
        font-family: $enHeadingFont;
        font-size: 1.2rem;
        line-height: 1.5;
        font-weight: 400;

        @include mq(md) {
          font-size: 1.1rem;
        }
      }

      &--foot {
        font-family: $enBaseFontFamily;
        font-size: 1.4rem;
        line-height: 1.5;
        font-weight: 400;

        @include mq(md) {
          font-size: 1.6rem;
        }

        .c-link-button {
          font-size: 1.4rem;
          line-height: 1.5;
          font-weight: 400;

          @include mq(md) {
            font-size: 1.6rem;
          }
        }
      }
    }
  }


}
