.p-service-pager {
  margin-top: 60px;
  padding: 45px 9.6% 34px;
  background-color: $color-gray-100;

  @include mq(md) {
    margin-top: 130px;
    padding: 95px 20px;
  }

  &__inner {
    @include mq(md) {
      display: flex;
      max-width: 1200px;
      margin: 0 auto;
    }
  }

  &__title {
    font-size: 1.5rem;
    font-weight: bold;

    @include mq(md) {
      flex-shrink: 0;
      margin-right: 50px;
      font-size: 2rem;
    }
  }

  &__list {
    margin: 20px 0 -8px;

    @include mq(md) {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -4px -8px;
    }
  }

  &__item {
    padding: 0 0 8px;

    @include mq(md) {
      width: 25%;
      padding: 0 4px 8px;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px 53px 20px 20px;
    letter-spacing: -0.01em;
    font-size: 1.3rem;
    font-weight: 500;
    border: 1px solid $color-gray-300;
    background-color: #fff;

    @include mq(md) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      padding: 16px 50px 16px 20px;
    }

    &.is-current {
      border-color: $color-secondary;
    }
  }

  &__link-number {
    margin-right: 15px;
    font-family: $font-ratin;
    font-size: 1.4rem;
    color: $color-gray;

    @include mq(md) {
      font-size: 1.5rem;
    }
  }

  &__link-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    width: 28px;
    height: 28px;
    transform: translateY(-50%);

    .p-service-pager__link:not(.is-current):hover & {
      @include mq(md) {
        animation: arrow-right 0.5s ease forwards;
      }
    }
  }
}

//以下 English Ver. 記述

.en {
  .p-service-pager {
    &__title {
      font-family: $enHeadingFont;
      font-size: 1.7rem;

      @include mq(md) {
        font-size: 2.2rem;
      }
    }
    &__link {
      font-size: 1.4rem;
      @include mq(sp-only) {
        letter-spacing: $enBaseLetterSpacing;
      }
      @include mq(md) {
        font-size: 1.3rem;
        padding: 16px 35px 16px 20px;
      }
    }
  }
}
