.p-creed {
  margin-top: 50px;

  @include mq(md) {
    margin-top: 100px;
  }

  &__intro {
    padding: 0 9.6%;
  }

  &__section {
    text-align: center;

    & + & {
      position: relative;
      margin-top: 50px;
      padding-top: 50px;

      @include mq(md) {
        margin-top: 100px;
        padding-top: 100px;
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        width: 32px;
        height: 2px;
        background-color: $color-secondary;
        transform: translateX(-50%);

        @include mq(md) {
          width: 64px;
          height: 4px;
        }
      }
    }
  }

  &__headline {
    text-align: center;
    font-size: 2.3rem;

    @include mq(md) {
      font-size: 3.6rem;
    }
  }

  &__title {
    margin-top: 10px;
    text-align: center;

    @include mq(md) {
      margin-top: 20px;
    }
  }

  &__title-text {
    display: inline-block;
    position: relative;
    padding: 0 10px;
    font-size: 1.4rem;
    color: $color-primary;

    @include mq(md) {
      padding: 0 18px;
      font-size: 2rem;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      width: 3px;
      height: calc(100% - 2px);
      border: 1px solid $color-gray-350;

      @include mq(md) {
        width: 4px;
      }
    }

    &::before {
      left: 0;
      border-right-width: 0;
    }

    &::after {
      right: 0;
      border-left-width: 0;
    }
  }

  &__lead {
    display: inline-block;
    margin: 20px auto 0;
    text-align: left;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: (67/28);

    @include mq(md) {
      margin-top: 50px;
      font-size: 2.2rem;
    }

    &--lh-m {
      display: block;
      text-align: center;
      line-height: (48/28);
    }
  }

  &__list {
    display: inline-block;
    margin: 20px 0 -7px 0;
    text-align: left;

    @include mq(md) {
      margin: 20px auto 0;
    }
  }

  &__list-item {
    position: relative;
    padding-bottom: 7px;
    padding-left: 17px;
    font-size: 1.2rem;

    @include mq(md) {
      font-size: 1.8rem;
    }

    .p-creed__list--s & {
      @include mq(md) {
        font-size: 1.5rem;
      }
    }

    &::before {
      content: "･";
      position: absolute;
      left: 0;
    }
    &.no-dot {
      padding-left: 0;
      text-align: center;
    }
    &.no-dot::before {
      content: "";
    }
  }

  &__guide {
    position: relative;
    margin-top: 70px;
    padding: 70px 9.6% 0;
    background: url("/assets/img/page/about/behappy_gray.svg") no-repeat center
      60px;
    background-size: 380px;

    @include mq(md) {
      max-width: 1240px;
      margin: 180px auto 0;
      padding: 180px 50px 0;
      background: url("/assets/img/page/about/behappy_gray.svg") no-repeat
        center top;
      background-size: 100%;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 9.6%;
      width: calc(100% - 19.2%);
      height: 1px;
      background-color: $color-gray-300;

      @include mq(md) {
        left: 20px;
        width: calc(100% - 40px);
      }
    }
  }

  &__guide-lead {
    margin-top: 26px;
    text-align: center;

    @include mq(md) {
      margin-top: 40px;
    }
  }

  &__guide-lead-text {
    display: inline-flex;
    position: relative;
    padding: 10px 10px 10px 20px;
    font-size: 2.7rem;
    font-weight: 900;
    line-height: (72/54);
    color: $color-secondary;

    @include mq(md) {
      padding: 10px 30px;
      letter-spacing: 0.035em;
      font-size: 4.4rem;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 23px;
      height: 23px;
      border: 1px solid $color-secondary;

      @include mq(md) {
        width: 36px;
        height: 36px;
      }
    }

    &::before {
      top: 0;
      left: 0;
      border-width: 1px 0 0 1px;

      @include mq(md) {
        border-width: 2px 0 0 2px;
      }
    }

    &::after {
      right: 0;
      bottom: 0;
      border-width: 0 1px 1px 0;

      @include mq(md) {
        border-width: 0 2px 2px 0;
      }
    }
  }

  &__guide-group {
    margin-top: 20px;

    @include mq(md) {
      position: relative;
      margin-top: 40px;
      text-align: center;
    }

    &::after {
      @include mq(md) {
        content: "";
        position: absolute;
        top: -120px;
        right: 20px;
        width: 128px;
        height: 263px;
        background: url("/assets/img/share/tamkun_03.svg") no-repeat;
        background-size: contain;
      }
    }
  }

  &__text {
    letter-spacing: 0.016em;
    font-size: 1.2rem;
    line-height: (42/24);

    @include mq(md) {
      font-size: 1.4rem;
    }

    & + & {
      margin-top: 10px;
    }
  }

  &__rule {
    margin-top: 40px;

    @include mq(md) {
      display: flex;
      position: relative;
      margin-top: 110px;
      padding: 0 30px;
    }

    &::before,
    &::after {
      @include mq(md) {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        width: 10px;
        height: calc(100% - 2px);
        border: 1px solid $color-gray-350;
      }
    }

    &::before {
      @include mq(md) {
        left: 0;
        border-right-width: 0;
      }
    }

    &::after {
      @include mq(md) {
        right: 0;
        border-left-width: 0;
      }
    }
  }

  &__rule-title {
    font-size: 1.8rem;

    @include mq(md) {
      flex-shrink: 0;
      font-size: 2.5rem;
    }
  }

  &__rule-text {
    margin-top: 10px;
    font-size: 1.2rem;
    line-height: (42/24);

    @include mq(md) {
      position: relative;
      margin-top: 0;
      padding-left: 120px;
      letter-spacing: 0.01em;
      font-size: 1.5rem;
      line-height: (33/15);
    }

    &::before {
      @include mq(md) {
        content: "";
        position: absolute;
        top: 18px;
        left: 20px;
        width: 74px;
        height: 1px;
        background-color: $color-gray-300;
      }
    }
  }

  &__rule-list {
    margin: 26px 0 -36px;
    counter-reset: item;

    @include mq(md) {
      display: flex;
      flex-wrap: wrap;
      margin: 60px -40px -70px;
    }
  }

  &__rule-item {
    position: relative;
    padding-bottom: 36px;

    @include mq(md) {
      width: 50%;
      padding: 0 40px 70px;
    }

    &::before {
      content: counter(item, decimal-leading-zero);
      font-family: $font-ratin;
      font-size: 1.7rem;
      font-weight: bold;
      color: $color-secondary;
      counter-increment: item;

      @include mq(md) {
        font-size: 2.4rem;
      }
    }

    &:nth-child(1) {
      &::after {
        content: "";
        position: absolute;
        right: -20px;
        bottom: calc(100% - 88px);
        z-index: -1;
        width: 64px;
        height: 132px;
        background: url("/assets/img/share/tamkun_03.svg") no-repeat;
        background-size: contain;

        @include mq(md) {
          display: none;
        }
      }
    }

    &:nth-child(6) {
      &::after {
        content: "";
        position: absolute;
        right: -18px;
        bottom: calc(100% - 85px);
        z-index: -1;
        width: 64px;
        height: 132px;
        background: url("/assets/img/share/tamkun_05.svg") no-repeat;
        background-size: contain;

        @include mq(md) {
          display: none;
        }
      }
    }

    &:nth-child(7) {
      &::after {
        @include mq(md) {
          content: "";
          position: absolute;
          bottom: calc(100% - 130px);
          left: -120px;
          z-index: -1;
          width: 128px;
          height: 225px;
          background: url("/assets/img/share/tamkun_05.svg") no-repeat;
          background-size: contain;
        }
      }
    }

    &:nth-child(14) {
      &::after {
        content: "";
        position: absolute;
        right: -20px;
        bottom: calc(100% - 90px);
        z-index: -1;
        width: 64px;
        height: 132px;
        background: url("/assets/img/share/tamkun_04.svg") no-repeat;
        background-size: contain;

        @include mq(md) {
          right: 350px;
          bottom: -230px;
          width: 128px;
          height: 225px;
        }
      }
    }
  }

  &__rule-item-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: $color-primary;

    @include mq(md) {
      margin-top: 5px;
      font-size: 2rem;
    }

    & + .p-creed__list {
      margin-top: 10px;
    }
  }

  &__rule-item-text {
    margin-top: 10px;
    letter-spacing: -0.01em;
    font-size: 1.2rem;
    line-height: (42/24);

    @include mq(md) {
      margin-top: 20px;
      font-size: 1.5rem;
      line-height: (31/15);
    }

    &--bold {
      font-weight: bold;
    }
  }
}

//以下 English Ver. 記述

.en {
  .p-creed {
    &__headline {
      font-size: 2.5rem;
      @include mq(md) {
        font-size: 3.8rem;
      }
    }
    &__lead {
      font-size: 1.6rem;
      @include mq(md) {
        font-size: 2.4rem;
      }
    }
    &__lead-list {
      list-style: disc;
      @include mq(sp-only) {
        margin: 20px auto 0 23px;
      }
    }

    &__lead-list li::marker {
      content: "･ ";
      @include mq(sp-only) {
        font-size: 1.2rem;
      }
    }
    &__list--s {
      .p-creed__list-item {
        @include mq(md) {
          font-size: 1.7rem;
        }
      }
    }
    &__list-item {
      font-size: 1.4rem;

      @include mq(md) {
        font-size: 2rem;
      }
    }
    &__guide-lead-text {
      letter-spacing: 0.007em;
      font-size: 2.9rem;
      padding: 10px 20px;

      @include mq(md) {
        font-size: 4.6rem;
        padding: 10px 30px;
      }
    }

    &__text {
      font-size: 1.4rem;
      letter-spacing: $enBaseLetterSpacing;

      @include mq(md) {
        font-size: 1.6rem;
      }
    }

    &__rule-title {
      font-size: 2rem;

      @include mq(md) {
        font-size: 2.7rem;
      }
    }

    &__rule-text {
      font-size: 1.4rem;
      letter-spacing: $enBaseLetterSpacing;

      @include mq(md) {
        font-size: 1.7rem;
      }
    }

    &__rule-item-title {
      font-size: 1.7rem;

      @include mq(md) {
        font-size: 2.2rem;
      }
    }

    &__rule-item-text {
      font-size: 1.4rem;
      letter-spacing: $enBaseLetterSpacing;

      @include mq(md) {
        font-size: 1.7rem;
      }
    }

    &__rule-item-list {
      margin: 10px auto 0 12px;
      list-style: disc;
    }
    &__rule-item-list li::marker {
      content: "･";
      @include mq(sp-only) {
        font-size: 1.2rem;
      }
    }
  }
}
