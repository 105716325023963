.p-recruit {
  margin-top: 60px;

  @include mq(md) {
    margin-top: 0;
  }

  &__movie {
    margin-top: 60px;

    @include mq(md) {
      margin-top: 100px;
    }
  }

  &__section {
    position: relative;
    margin-top: 100px;

    @include mq(md) {
      margin-top: 150px;
    }

    &--entry {
      @include mq(md) {
        margin-top: 32px;
        padding-top: 200px;
      }
    }
  }

  &__section-inner {
    padding: 0 (14 / 375 * 100%);

    @include mq(md) {
      position: relative;
      z-index: 2;
      padding: 0;
    }
  }

  &__headline {
    position: relative;
    z-index: 2;
    padding: 30px 0;
    text-align: center;
    font-family: $font-ratin, $font-jp;
    font-size: 2.3rem;
    font-weight: bold;

    @include mq(md) {
      padding: 0;
      font-size: 3.6rem;
    }
  }

  &__headline-bg {
    position: absolute;
    top: -10px;
    z-index: 1;
    width: 100%;
    text-align: center;
    letter-spacing: 0.15em;
    font-family: $font-ratin;
    font-size: 11rem;
    font-weight: bold;
    line-height: 1;
    color: $color-secondary-200;
    -webkit-text-fill-color: rgba(256, 256, 256, 0);
    -webkit-text-stroke-color: $color-secondary-200;
    -webkit-text-stroke-width: 1px;

    @include mq(md) {
      right: calc(((100vw - 1200px) / -2) - 70px);
      width: auto;
      font-size: 25.8rem;
      writing-mode: vertical-rl;
    }

    &--culture {
      margin: 0 -30px;
      letter-spacing: 0.05em;
    }

    .p-recruit__section:nth-child(odd) & {
      @include mq(md) {
        right: auto;
        left: calc(((100vw - 1200px) / -2) - 42px);
      }
    }
  }

  // ENTRY
  &__figure {
    position: absolute;
    top: -20px;
    right: 10px;
    z-index: 2;
    width: 95px;

    @include mq(md) {
      top: 10px;
      right: auto;
      left: 140px;
      width: 195px;
    }
  }

  &__tamkun-text {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    top: -50px;
    left: -60px;
    width: 100px;
    height: 90px;
    padding-top: 26px;
    letter-spacing: 0.05em;
    font-size: 1rem;
    font-weight: bold;
    line-height: (3/2);
    background: url("/assets/img/page/recruit/baloon_01.svg") no-repeat;
    background-size: contain;

    @include mq(md) {
      top: 30px;
      left: 190px;
      width: 152px;
      height: 140px;
      padding-top: 42px;
      font-size: 1.4rem;
      background: url("/assets/img/page/service/baloon_01.png") no-repeat;
      background-size: contain;
    }
  }

  &__list {
    position: relative;
    z-index: 2;
    margin: 30px 0 -15px 0;

    @include mq(md) {
      display: flex;
      flex-wrap: wrap;
      margin: 60px -20px -40px;
    }
  }

  &__item {
    padding: 0 0 15px 0;

    @include mq(md) {
      width: 33.333%;
      padding: 0 20px 40px;
    }
  }

  &__item-link {
    display: block;
    position: relative;
    padding: 16px 40px 16px 18px;
    border: 1px solid $color-gray-200;
    background: #fff url("/assets/img/share/link_blank.svg") no-repeat;
    background-position-x: calc(100% - 10px);
    background-position-y: center;
    background-size: 15px;

    @include mq(md) {
      height: 100%;
      padding: 53px 20px;
      text-align: center;
      background-position-x: calc(100% - 10px);
      background-position-y: calc(100% - 10px);
      background-size: 17px;
    }

    &::before,
    &::after {
      @include mq(md) {
        content: "";
        display: block;
        position: absolute;
        bottom: -1px;
        width: 0;
        height: 2px;
        background-color: $color-secondary;
        transition: width 0.3s ease;
      }
    }

    &::before {
      right: 50%;
    }

    &::after {
      left: 50%;
    }

    &:hover {
      &::before,
      &::after {
        @include mq(md) {
          width: 50%;
        }
      }
    }
  }

  &__item-title {
    display: inline-block;
    position: relative;
    padding-left: 28px;
    font-size: 1.6rem;
    font-weight: bold;
    color: $color-secondary;

    @include mq(md) {
      padding-left: 40px;
      font-size: 2.4rem;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 18px;
      height: 18px;
      background: url("/assets/img/page/recruit/wantedly.svg") no-repeat center center;
      background-size: contain;
      transform: translateY(-50%);

      @include mq(md) {
        width: 26px;
        height: 26px;
      }
    }
  }

  &__item-text {
    margin-top: 5px;
    letter-spacing: 0.05em;
    font-size: 1.2rem;
    line-height: (36/24);
    color: $color-gray-900;

    @include mq(md) {
      margin-top: 20px;
      font-size: 1.4rem;
    }
  }

  // CULTURE
  &__lead {
    position: relative;
    z-index: 2;
    margin-top: 22px;
    text-align: center;
    font-size: 1.2rem;

    @include mq(md) {
      margin-top: 0;
      font-size: 1.5rem;
      line-height: (33/15);
    }
  }

  &__lead-image {
    display: block;
    position: relative;
    z-index: 2;
    width: 227px;
    margin: 8px auto 0;

    @include mq(md) {
      top: -30px;
      left: 50%;
      width: (497/1440 * 100vw);
      max-width: 497px;
      min-width: 358px;
      margin: 0;
      transform: translateX(-50%);
    }
  }

  &__ig-list {
    display: grid;
    margin: -20px -1px -2px;
    grid-template-columns: 33.333% (33.333%/2) (33.333%/2) 33.333%;
    // for ie11
    grid-template-rows: repeat(23, auto);

    @include mq(md) {
      margin: 80px -4px -8px;
      grid-template-columns: repeat(6, 16.6%);
    }
  }

  &__ig-item {
    padding: 0 1px 2px;

    @include mq(md) {
      padding: 0 4px 8px;
    }

    // GRID LAYOUT SP
    &:nth-child(6n) {
      @include mq(sp-only) {
        grid-column: 1/5;
      }
    }

    &:nth-child(1),
    &:nth-child(10),
    &:nth-child(16),
    &:nth-child(22) {
      @include mq(sp-only) {
        grid-column: 1/3;
      }
    }

    &:nth-child(2),
    &:nth-child(11),
    &:nth-child(17),
    &:nth-child(23) {
      @include mq(sp-only) {
        grid-column: 3/5;
      }
    }

    &:nth-child(4),
    &:nth-child(8),
    &:nth-child(14),
    &:nth-child(20) {
      @include mq(sp-only) {
        grid-column: 2/4;
      }
    }

    // GRID LAYOUT PC
    &:nth-child(1) {
      @include mq(md) {
        position: relative;
        grid-column: 3/5;
        grid-row: 3/5;
      }
    }

    &:nth-child(2) {
      @include mq(md) {
        grid-column: 1/3;
        grid-row: 2/4;
      }
    }

    &:nth-child(3) {
      @include mq(md) {
        grid-column: 5/7;
        grid-row: 1/3;
      }
    }

    &:nth-child(4) {
      @include mq(md) {
        grid-column: 2/4;
        grid-row: 5/7;
      }
    }

    // GRID LAYOUT for ie11
    &:nth-child(5) {
      @include mq(md) {
        grid-column: 1/2;
        grid-row: 1/2;
      }
    }

    &:nth-child(6) {
      @include mq(md) {
        grid-column: 2/3;
        grid-row: 1/2;
      }
    }

    &:nth-child(7) {
      @include mq(md) {
        grid-column: 3/4;
        grid-row: 1/2;
      }
    }

    &:nth-child(8) {
      @include mq(md) {
        grid-column: 4/5;
        grid-row: 1/2;
      }
    }

    &:nth-child(9) {
      @include mq(md) {
        grid-column: 3/4;
        grid-row: 2/3;
      }
    }

    &:nth-child(10) {
      @include mq(md) {
        grid-column: 4/5;
        grid-row: 2/3;
      }
    }

    &:nth-child(11) {
      @include mq(md) {
        grid-column: 5/6;
        grid-row: 3/4;
      }
    }

    &:nth-child(12) {
      @include mq(md) {
        grid-column: 6/7;
        grid-row: 3/4;
      }
    }

    &:nth-child(15) {
      @include mq(md) {
        grid-column: 5/6;
        grid-row: 4/5;
      }
    }

    &:nth-child(16) {
      @include mq(md) {
        grid-column: 6/7;
        grid-row: 4/5;
      }
    }

    &:nth-child(18) {
      @include mq(md) {
        grid-column: 4/5;
        grid-row: 5/6;
      }
    }

    &:nth-child(19) {
      @include mq(md) {
        grid-column: 5/6;
        grid-row: 5/6;
      }
    }

    &:nth-child(20) {
      @include mq(md) {
        grid-column: 6/7;
        grid-row: 5/6;
      }
    }

    &:nth-child(22) {
      @include mq(md) {
        grid-column: 5/6;
        grid-row: 6/7;
      }
    }

    &:nth-child(23) {
      @include mq(md) {
        grid-column: 6/7;
        grid-row: 6/7;
      }
    }
  }

  &__ig-link {
    display: block;
    position: relative;
    z-index: 1;

    @include mq(md) {
      overflow: hidden;
    }

    &::before,
    &::after {
      visibility: hidden;
      opacity: 0;
    }

    &::before {
      @include mq(md) {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba($color-black, 0.75);
        transition: opacity 0.3s ease, visibility 0.3s ease;
      }
    }

    &::after {
      @include mq(md) {
        content: attr(data-title);
        position: absolute;
        top: 50%;
        left: (40/394 * 100%);
        z-index: 2;
        width: 80%;
        word-wrap: anywhere;
        font-size: 1.6rem;
        color: #fff;
        transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
        transform: translateY(-50%) translateY(10px);
      }

      .p-recruit__ig-item:nth-child(-n + 4) & {
        @include mq(md) {
          font-size: 1.8rem;
        }
      }
    }

    &:hover {
      &::before,
      &::after {
        @include mq(md) {
          visibility: visible;
          opacity: 1;
          transition: opacity 0.3s ease, visibility 0.3s ease;
        }
      }

      &::after {
        @include mq(md) {
          transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
          transform: translateY(-50%);
        }
      }
    }
  }

  &__ig-image {
    display: block;
  }

  // MOVIE
  &__movie-list {
    margin: 40px 0 -30px;

    @include mq(md) {
      display: flex;
      position: relative;
      z-index: 2;
      margin: 70px -5px 0;
    }
  }

  &__movie-item {
    padding-bottom: 30px;

    @include mq(md) {
      width: 33.333%;
      padding: 0 5px;
    }
  }

  &__movie-wrap {
    position: relative;
    padding-bottom: (388/690 * 100%);
  }

  &__movie-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__movie-caption {
    margin-top: 20px;
    text-align: center;
    font-size: 1.2rem;
    color: $color-gray-900;

    @include mq(md) {
      font-size: 1.4rem;
    }
  }

  // STAFF
  &__staff {
    margin-top: 50px;

    @include mq(md) {
      position: relative;
      z-index: 2;
      margin-top: 120px;
    }
  }

  &__staff-button {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 300px;
    height: 113px;
    margin: 0 auto;
    border: 1px solid $color-gray-350;
    background: url("/assets/img/page/recruit/staff_bg.png") no-repeat left top;
    background-position-x: -3px;
    background-position-y: -60px;
    background-size: 410px;

    @include mq(md) {
      max-width: 1000px;
      height: 200px;
      background-position-x: calc(50% - 100px);
      background-position-y: calc(50% + 50px);
      background-size: 1100px;
      transition: background-size 0.3s ease;
    }

    &:hover {
      @include mq(md) {
        background-size: 1050px;
      }
    }
  }

  &__staff-round {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 3;
    width: 100px;
    transform: translateY(-50%);

    @include mq(md) {
      right: 10px;
      width: 270px;
    }

    &::before {
      content: "";
      position: absolute;
      top: -100px;
      right: -140px;
      z-index: -1;
      width: 263px;
      height: 265px;
      border-radius: 140px;
      background-color: $color-secondary;

      @include mq(md) {
        top: -210px;
        right: -210px;
        width: 526px;
        height: 526px;
        border-radius: 260px;
      }
    }
  }

  &__staff-title {
    font-family: $font-ratin;
    font-size: 1.3rem;
    font-weight: bold;
    color: $color-primary;

    @include mq(md) {
      font-size: 2.7rem;
    }

    &--white {
      -webkit-text-fill-color: #fff;
      -webkit-text-stroke-color: $color-primary;
      -webkit-text-stroke-width: 0.5px;

      @include mq(md) {
        -webkit-text-stroke-width: 1px;
      }
    }
  }

  &__staff-text {
    display: block;
    letter-spacing: 0.01em;
    font-size: 1.5rem;
    line-height: 1;

    @include mq(md) {
      letter-spacing: -0.05em;
      font-size: 3.3rem;
    }
  }

  &__staff-icon {
    width: 22px;
    height: 22px;

    @include mq(md) {
      position: absolute;
      top: 50%;
      right: 25px;
      width: 40px;
      height: 40px;
      transform: translateY(-50%);
    }
  }
}

//以下 English Ver. 記述
.en {
  .p-recruit {

    &__staff-title {

      &--white {
        -webkit-text-stroke-width: 0px;
      }
    }

    &__staff-text {
      font-family: $enBaseFontFamily;
      font-size: 1.1rem;
      line-height: 1.5;

      @include mq(md) {
        font-size: 2.1rem;
        line-height: 1;
        letter-spacing: 0.02em;
      }
    }
  }
}
