.c-text-lead {
  margin-top: 30px;
  font-size: 1.4rem;
  line-height: (52/28);

  @include mq(md) {
    margin-top: 40px;
    font-size: 1.8rem;
    line-height: (38/18);
  }

  &--contact {
    margin-top: 60px;
    text-align: center;

    @include mq(md) {
      margin-top: 110px;
    }
  }

  & + & {
    margin-top: 20px;

    @include mq(md) {
      margin-top: 40px;
    }
  }
}

//以下 English Ver. 記述

.en {
  .c-text-lead {
    font-size: 1.6rem;
    line-height: 2.0;

    @include mq(md) {
      font-size: 2.0rem;
    }
  }
}
