@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.c-fadein {
  animation: fade-in 2s ease-in-out forwards;
}
