// animation
// TOP HERO --- "HELLO,"
@keyframes width-max {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes width-max-min {
  0% {
    width: 0;
  }

  50% {
    width: 100%;
  }

  100% {
    width: 0;
  }
}

@keyframes width-max-min-sec {
  0% {
    width: 0;
    -webkit-text-fill-color: #fff;
  }

  50% {
    width: 100%;
    -webkit-text-fill-color: $color-secondary;
  }

  100% {
    width: 0;
    -webkit-text-fill-color: #fff;
  }
}

// TOP HERO --- TAM KUN
@keyframes hero-picture-bounce {
  0% {
    transform: scale(1, 1) translateX(0);
  }

  30% {
    transform: scale(1.05, 1.05) translateX(-40px);
  }

  50% {
    transform: scale(1, 1) translateX(0);
  }

  64% {
    transform: scale(1, 1) translateX(-10px);
  }

  100% {
    transform: scale(1, 1) translateX(0);
  }
}

// TOP HERO --- TAM KUN -PC
@keyframes hero-picture-bounce-pc {
  0% {
    transform: scale(1, 1) translateX(0);
  }

  30% {
    transform: scale(1.1, 1.1) translateX(-140px);
  }

  50% {
    transform: scale(1, 1) translateX(0);
  }

  64% {
    transform: scale(1, 1) translateX(-80px);
  }

  100% {
    transform: scale(1, 1) translateX(0);
  }
}

// ARROW ICON BOUNCE
@keyframes arrow-right {
  0% {
    margin-right: 0;
  }

  50% {
    margin-right: -5px;
  }

  100% {
    margin-right: 0;
  }
}
@keyframes arrow-left {
  0% {
    margin-left: 0;
  }

  50% {
    margin-left: -5px;
  }

  100% {
    margin-left: 0;
  }
}

// ARROW ICON BOUNCE FOR TOP-SFUFF-BUTTON
@keyframes arrow-right-stuff {
  0% {
    transform: translate(-50%, 0);
  }

  50% {
    transform: translate(-40%, 0);
  }

  100% {
    transform: translate(-50%, 0);
  }
}

// WORK LIST HOVER
@keyframes rotate-bounce {
  0% {
    opacity: 0;
    transform: scale(0.9) rotate(-40deg);
  }

  30% {
    transform: scale(1) rotate(0);
  }

  50% {
    transform: scale(1.3);
  }

  64% {
    transform: scale(1);
  }

  100% {
    opacity: 1;
    transform: scale(1.08);
  }
}

@keyframes opacity-scale-max-min {
  0% {
    opacity: 1;
    transform: scale(1.08);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

// 汎用：Bounce - Holizontaly
@keyframes bounce-h {
  0% {
    transform: translateY(30px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}
