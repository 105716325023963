.c-button-detail {
  display: inline-block;
  position: relative;
  padding-right: 30px;
  text-align: left;
  white-space: nowrap;
  font-size: 1.2rem;
  font-weight: 500;

  @include mq(md) {
    padding-right: 50px;
  }

  &--white {
    color: #fff;
  }

  &--m {
    letter-spacing: -0.05em;
    font-size: 1.3rem;
    font-weight: normal;

    @include mq(md) {
      padding-right: 50px;
      font-size: 1.6rem;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 0;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);

    @include mq(md) {
      width: 30px;
      height: 30px;
    }

    .c-button-detail--white & {
      fill: #fff;
    }

    .c-button-detail:hover & {
      @include mq(md) {
        animation: arrow-right 0.5s ease forwards;
      }
    }
  }
}

//以下 English Ver. 記述

.en {
  .c-button-detail {
    &--m {
      letter-spacing: $enBaseLetterSpacing;
      line-height: 1.8;

      @include mq(md) {
      }
    }
  }
}
