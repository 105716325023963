.c-button-return {
  display: inline-block;
  position: relative;
  padding-left: 48px;
  text-align: left;
  white-space: nowrap;
  font-size: 1.5rem;
  font-weight: 500;

  @include mq(md) {
    padding-left: 40px;
    font-size: 1.6rem;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 0;
    width: 25px;
    height: 25px;
    transform: translateY(-50%) scale(-1, 1);

    @include mq(md) {
    }

    .c-button-return:hover & {
      @include mq(md) {
        animation: arrow-left 0.5s ease forwards;
      }
    }
  }
}
