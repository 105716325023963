.p-work-related {
  @include mq(md) {
    position: relative;
  }

  &__button-wrap {
    margin-top: 30px;

    @include mq(md) {
      position: absolute;
      top: 22px;
      right: 0;
      margin-top: 0;
    }
  }

  &__button {
    position: relative;
    padding-right: 30px;
    font-size: 1.4rem;

    @include mq(md) {
      display: inline-block;
      width: 160px;
      padding-right: 40px;
      font-size: 1.6rem;
    }
  }

  &__button-icon {
    position: absolute;
    top: 50%;
    right: 0;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);

    @include mq(md) {
      width: 30px;
      height: 30px;
      fill: $color-black;
    }

    .p-work-related__button:hover & {
      @include mq(md) {
        animation: arrow-right 0.5s ease forwards;
      }
    }
  }
}

//以下 English Ver. 記述

.en {
  .p-work-related {
    &__button {

      @include mq(md) {
        width: auto;
      }
    }
  }
}
