.c-pagers {
  display: none;

  @include mq(md) {
    display: flex;
    align-items: center;
    position: relative;
    width: 200px;
    margin-left: auto;
  }
}
