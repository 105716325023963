.c-section {
  &--parent {
    margin-top: 40px;

    @include mq(md) {
      margin-top: 100px;
    }
  }

  &--work {
    margin-top: 130px;

    @include mq(md) {
      margin-top: 250px;
    }
  }

  &--work-m {
    margin-top: 80px;

    @include mq(md) {
      margin-top: 150px;
    }
  }

  &--result {
    padding: 0 (36 / 375 * 100%);

    @include mq(md) {
      display: flex;
      margin-top: 70px;
      padding: 0 50px;
    }

    // margin-top
    & + .c-figure-section--medium {
      @include mq(md) {
        margin-top: 100px;
      }
    }
  }

  &--about {
    margin-top: 50px;

    @include mq(md) {
      margin-top: 110px;
    }
  }

  &--related {
    margin-top: 60px;
    padding: 0 9.6%;

    @include mq(md) {
      margin-top: 130px;
      padding: 0;
    }
  }

  // 下線あり
  &--line {
    padding-bottom: 60px;
    border-bottom: 1px solid $color-gray-300;

    @include mq(md) {
      padding-bottom: 150px;
    }
  }

  // PCでのマージントップサイズ
  &--pc-mt-l {
    @include mq(md) {
      margin-top: 160px;
    }
  }

  &--pc-mt-m {
    @include mq(md) {
      margin-top: 80px;
    }
  }

  &__headline {
    padding: 0 (36 / 375 * 100%);

    @include mq(md) {
      padding: 0;
    }
  }

  &__inner_for_figure {
    padding: 0 (36 / 375 * 100%);

    @include mq(md) {
      padding: 0;
    }
  }

  &__inner {
    padding: 0 (36 / 375 * 100%);

    @include mq(md) {
      padding: 0 44px;
    }

    // margin-top
    & + .c-figure-section--medium {
      @include mq(md) {
        margin-top: 100px;
      }
    }

    .c-section--pc-mt-l & {
      @include mq(md) {
        padding: 0;
      }
    }
  }

  &__info {
    margin-top: 30px;

    @include mq(md) {
      margin-top: 60px;
      padding: 0 56px;
    }
  }

  &__info-title {
    font-family: $font-ratin;
    font-size: 1.2rem;
    font-weight: 500;
    color: $color-gray-600;
  }

  &__button-more {
    max-width: 300px;
    margin: 40px auto 0;

    @include mq(md) {
      margin-top: 74px;
    }
  }
}

//以下 English Ver. 記述

.en {
  .c-section {

    &__info-title {
      font-size: 1.4rem;
    }

    &__info-link {

      .c-link-button {
        font-family: $enBaseFontFamily;
        font-size: 1.4rem;
        line-height: 1.5;

        @include mq(md) {
          font-size: 1.6rem;
        }
      }
    }
  }
}

