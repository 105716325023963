.p-top-work {
  position: relative;

  &__logos {
    @include mq(md) {
      position: absolute;
      top: 136px;
      right: (117 / 1440 * 100vw);
      width: 300px;
      padding: 40px 20px;
    }

    @include mq(pc) {
      top: (212 / 1440 * 100vw);
      width: (423 / 1440 * 100vw);
    }

    @include mq(lg) {
      top: 212px;
      width: 423px;
    }

    &::before,
    &::after {
      @include mq(md) {
        content: "";
        position: absolute;
        width: 13px;
        height: 13px;
        border: 1px solid $color-gray-350;
      }
    }

    &::before {
      @include mq(md) {
        top: 0;
        right: 0;
        border-width: 1px 1px 0 0;
      }
    }

    &::after {
      @include mq(md) {
        right: 0;
        bottom: 0;
        border-width: 0 1px 1px 0;
      }
    }
  }

  &__logos-frame {
    &::before,
    &::after {
      @include mq(md) {
        content: "";
        position: absolute;
        width: 13px;
        height: 13px;
        border: 1px solid $color-gray-350;
      }
    }

    &::before {
      @include mq(md) {
        bottom: 0;
        left: 0;
        border-width: 0 0 1px 1px;
      }
    }

    &::after {
      @include mq(md) {
        top: 0;
        left: 0;
        border-width: 1px 0 0 1px;
      }
    }
  }

  &__logo {
    margin-top: 40px;

    @include mq(md) {
      margin-top: 0;
    }
  }

  &__logos-button {
    display: flex;
    position: relative;
    width: 160px;
    margin-top: 20px;
    margin-left: auto;
    padding: 10px 25px 10px 10px;
    text-align-last: left;
    font-size: 1rem;
    font-weight: 500;
    background-color: $color-black;
    color: #fff;
    cursor: pointer;

    @include mq(md) {
      width: 180px;
      margin-top: 27px;
      padding: 12px 25px 12px 10px;
      font-size: 1.1rem;
    }
  }

  &__logos-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 10px;
    height: 10px;
    transform: translateY(-50%);
    fill: #fff;

    @include mq(md) {
      stroke: #fff;
    }
  }

  // モーダル
  &__modal {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    width: 100%;
    height: 100vh;
    background-color: rgba(256, 256, 256, 0.96);
    opacity: 0;
    transition: opacity 0.5s ease, visibility 0.5s ease;

    &.is-show {
      visibility: visible;
      z-index: 5;
      opacity: 1;
    }
  }

  // モーダル閉じるボタン
  &__modal-close {
    position: fixed;
    top: 16px;
    right: 16px;
    z-index: 5;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: $color-black;
    cursor: pointer;

    @include mq(md) {
      top: 24px;
      right: 24px;
      width: 64px;
      height: 64px;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      width: 13px;
      height: 1px;
      background-color: #fff;
      transform: translate(-50%, -50%);
      transform-origin: center;

      @include mq(md) {
        width: 20px;
        height: 2px;
      }
    }

    &::before {
      top: calc(50%);
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      top: calc(50%);
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &__modal-inner {
    /* padding: 70px 50px; */
    padding: 25px 50px;

    @include mq(md) {
      padding: 150px 0 120px;
    }
  }

  &__modal-title {
    text-align: center;
  }

  &__modal-title-text {
    display: inline-block;
    position: relative;
    padding: 8px 20px;
    font-family: $font-ratin;
    font-size: 2.7rem;
    font-weight: 900;
    line-height: 1;

    @include mq(md) {
      padding: 12px 36px;
      font-size: 5rem;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 23px;
      height: 23px;
      border: 1px solid $color-black;

      @include mq(md) {
        width: 36px;
        height: 36px;
      }
    }

    &::before {
      top: 0;
      left: 0;
      border-width: 1px 0 0 1px;

      @include mq(md) {
        border-width: 2px 0 0 2px;
      }
    }

    &::after {
      right: 0;
      bottom: 0;
      border-width: 0 1px 1px 0;

      @include mq(md) {
        border-width: 0 2px 2px 0;
      }
    }
  }

  &__modal-figure {
    max-width: 270px;
    /* margin: 40px auto 0; */
    margin: 25px auto 0;

    @include mq(md) {
      max-width: 890px;
      margin-top: 50px;
    }
  }

  &__list-wrap {
    margin-top: 55px;

    @include mq(md) {
      max-width: 1240px;
      margin: 80px auto 0;
      padding: 0 20px;
    }
  }

  &__list {
    @include mq(md) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 80px -45px -80px;
    }
  }

  &__list-item {
    @include mq(md) {
      width: 33.3333%;
      padding: 0 45px 80px;
    }

    & + & {
      margin-top: 44px;

      @include mq(md) {
        margin-top: 0;
      }
    }

    &:nth-child(2) {
      @include mq(md) {
        margin-top: 186px;
      }
    }

    &:nth-child(4),
    &:nth-child(6) {
      @include mq(md) {
        margin-top: -186px;
      }
    }

    &:nth-child(7),
    &:nth-child(9) {
      @include mq(md) {
        margin-top: -186px;
      }
    }

    &:nth-child(n + 7) {
      display: none;

      @include mq(md) {
        display: block;
      }
    }
  }

  &__list-link {
    display: block;
    position: relative;
    width: (586 / 759 * 100%);

    @include mq(md) {
      width: 100%;
    }

    .p-top-work__list-item:nth-child(2n + 1) & {
      margin-left: auto;

      @include mq(md) {
        margin-left: 0;
      }
    }
  }

  &__list-tag {
    position: absolute;
    top: -7px;
    letter-spacing: 0.01em;
    font-family: $font-ratin;
    font-size: 1.1rem;
    font-weight: 500;
    color: $color-gray;
    transform: rotate(90deg);
    transform-origin: left;

    @include mq(md) {
      font-size: 1.2rem;
    }

    .p-top-work__list-item:nth-child(2n + 1) & {
      left: -10px;

      @include mq(md) {
        right: -52px;
        left: auto;
      }
    }

    .p-top-work__list-item:nth-child(2n) & {
      right: -50px;

      @include mq(md) {
        right: -52px;
      }
    }
  }

  &__list-picture {
    position: relative;
    padding-bottom: (376/336 * 100%);

    &::before {
      @include mq(md) {
        content: "";
        visibility: hidden;
        position: absolute;
        top: -10%;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("/assets/img/share/tamkun_icon_01.svg") no-repeat center center;
        background-size: contain;
        opacity: 0;
        transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
      }
    }

    &:hover {
      &::before {
        @include mq(md) {
          visibility: visible;
          opacity: 1;
          transform: scale(1.08);
          transform-origin: center;
        }
      }
    }
  }

  &__list-image {
    display: block;
    position: absolute;

    @include mq(md) {
      transition: all 0.3s ease;
    }

    .p-top-work__list-picture:hover & {
      @include mq(md) {
        transform: scale(0.9);
        -moz-transform-origin: center;
      }
    }
  }

  &__list-body {
    margin-top: 12px;

    @include mq(md) {
      margin-top: 30px;
    }

    .p-top-work__list-item:nth-child(2n + 1) & {
      padding-right: 35px;

      @include mq(md) {
        padding: 0;
      }
    }

    .p-top-work__list-item:nth-child(2n) & {
      padding-left: 35px;

      @include mq(md) {
        padding: 0;
      }
    }
  }

  &__list-client {
    font-size: 1.2rem;
    color: $color-primary;

    @include mq(md) {
      font-size: 1.4rem;
    }
  }

  &__list-title {
    margin-top: 10px;
    letter-spacing: 0.01em;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: (45/30);

    @include mq(md) {
      margin-top: 14px;
      letter-spacing: 0.01em;
      font-size: 2rem;
    }
  }

  &__list-category {
    margin-top: 16px;
    font-size: 1.2rem;
    color: $color-gray-700;

    @include mq(md) {
      letter-spacing: 0.01em;
      font-size: 1.4rem;
    }
  }

  &__button-link {
    max-width: 300px;
    margin: 52px auto 0;

    @include mq(md) {
      max-width: 563px;
      margin-top: 80px;
    }
  }
}

//以下 English Ver. 記述

.en {
  .p-top-work {
    &__logos-button {
      font-size: 1.2rem;
      font-family: $enBaseFontFamily;
      line-height: 1.5;

      @include mq(md) {
        font-size: 1.3rem;
      }
    }

    &__list-client {
      font-size: 1.4rem;
      font-family: $enBaseFontFamily;
      line-height: 1.5;

      @include mq(md) {
        font-size: 1.6rem;
      }
    }

    &__list-title {
      font-size: 1.8rem;
      font-family: $enBaseFontFamily;
      line-height: 1.5;

      @include mq(md) {
        font-size: 2.2rem;
      }
    }

    &__list-category {
      font-size: 1.4rem;
      font-family: $enBaseFontFamily;
      line-height: 1.5;

      @include mq(md) {
        font-size: 1.6rem;
      }
    }
  }
}
