.p-top-contact {
  position: relative;
  margin-top: 70px;
  padding-top: 70px;

  @include mq(md) {
    margin-top: 90px;
    padding-top: 140px;
  }

  &__logo {
    position: absolute;
    top: 0;
    left: (32/375 * 100%);
    width: (305/375 * 100%);

    @include mq(md) {
      left: 50%;
      width: 612px;
      transform: translateX(-50%);
    }
  }

  &__inner {
    min-height: 590px;
    padding: 70px 35px 80px;
    background-color: $color-primary;
    color: #fff;

    @include mq(md) {
      display: flex;
      justify-content: center;
      min-height: 550px;
      padding: 120px 20px;
    }
  }

  &__description {
    @include mq(md) {
      margin-right: (100/1440 * 100vw);
    }
  }

  &__title {
    text-align: center;
    font-family: $font-ratin;
    font-size: 3.5rem;
    font-weight: bold;
    line-height: 1.3;

    @include mq(md) {
      text-align: left;
      font-size: 6rem;
    }
  }

  &__title-jp {
    text-align: center;
    font-family: $font-jp;
    font-size: 1.6rem;
    font-weight: bold;

    @include mq(md) {
      text-align: left;
      font-size: 2.4rem;
    }
  }

  &__text-wrap {
    margin-top: 20px;
  }

  &__text {
    font-size: 1.3rem;

    & + & {
      margin-top: 1em;
    }
  }

  &__form-wrap {
    position: relative;
    min-height: 250px;
    margin-top: 30px;

    @include mq(md) {
      width: (675/1440 * 100vw);
      min-height: auto;
      max-width: 675px;
      margin-top: 0;
    }
  }

  &__submitted {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__submitted-group {
    position: relative;
    height: 100%;
    margin-top: 40px;
    text-align: center;

    @include mq(md) {
      margin-top: 0;
      text-align: left;
    }
  }

  &__submitted-title {
    position: absolute;
    top: -20px;
    right: 110px;
    z-index: 0;
    width: 140px;
    height: 133px;
    padding: 41px 18px 30px;
    text-align: left;
    letter-spacing: 0.01em;
    font-size: 1.3rem;
    font-weight: bold;
    line-height: (38/26);
    background: url("/assets/img/page/recruit/baloon_01.svg") no-repeat top left;
    color: $color-black;

    @include mq(md) {
      top: -40px;
      right: 150px;
      width: 200px;
      height: 183px;
      padding: 59px 29px 30px;
      font-size: 1.8rem;
    }
  }

  &__submitted-text {
    display: inline;
    position: absolute;
    bottom: 40px;
    left: -20px;
    text-align: left;
    text-decoration: underline;
    font-size: 1.3rem;
    line-height: (72/26);
    text-decoration-color: $color-primary-200;
    text-underline-position: under;
    width: calc(100% - 42px);

    @include mq(md) {
      width: auto;
      bottom: -20px;
      left: -80px;
      font-size: 1.8rem;
    }

    @include mq(pc) {
      left: 0;
    }
  }

  &__submitted-figure {
    position: relative;
    top: -48px;
    right: -20px;
    text-align: right;

    @include mq(md) {
      top: -21px;
      right: 0;
    }

    @include mq(lg) {
      right: 160px;
    }
  }

  &__submitted-image {
    position: relative;
    z-index: 1;
    width: 130px;

    @include mq(md) {
      width: 177px;
    }
  }
}

// axios transition
.fadeform-enter-active,
.fadeform-leave-active {
  transition: opacity 0.5s;
}

.fadeform-enter,
.fadeform-leave-to {
  height: 0;
  opacity: 0;
}

.fademessage-enter-active,
.fademessage-leave-active {
  transition: opacity 0.5s 0.5s, height 0.5s;
}

.fademessage-enter,
.fademessage-leave-to {
  height: 0;
  opacity: 0;
}

//以下 English Ver. 記述
.en {
  .p-top-contact {
    &__text {
      font-family: $enBaseFontFamily;
      font-size: 1.5rem;
      line-height: 1.5;
    }
  }
}
