.c-section-sub {
  margin: 50px 9.6% 0;
  letter-spacing: 0.01em;
  font-size: 1.2rem;
  line-height: (46/24);

  @include mq(md) {
    max-width: 860px;
    margin: 100px auto 0;
    font-size: 1.5rem;
    line-height: (33/15);
  }

  & + & {
    margin-top: 40px;
    padding-top: 60px;
    border-top: 1px solid $color-gray-300;

    @include mq(md) {
      margin-top: 110px;
      padding-top: 110px;
    }
  }

  &__headline {
    text-align: center;
    font-size: 2.3rem;
    line-height: (60/46);

    @include mq(md) {
      font-size: 3.6rem;
    }

    &--s {
      font-size: 2rem;
      line-height: (56/40);

      @include mq(md) {
        font-size: 3.6rem;
      }
    }
  }

  &__lead {
    position: relative;
    margin-top: 25px;
    padding: 10px 20px;

    @include mq(md) {
      margin-top: 50px;
      padding: 8px 40px;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      width: 4px;
      height: 100%;
      border: 1px solid $color-gray-350;

      @include mq(md) {
        width: 8px;
      }
    }

    &::before {
      left: 0;
      border-right-width: 0;
    }

    &::after {
      right: 0;
      border-left-width: 0;
    }
  }

  &__body {
    margin-top: 36px;

    @include mq(md) {
      margin-top: 60px;
    }
  }

  &__title {
    margin-top: 30px;
    font-size: 1.5rem;
    font-weight: bold;
    color: $color-primary;

    @include mq(md) {
      margin-top: 50px;
    }

    &--l {
      font-size: 2rem;
      line-height: (62/40);

      @include mq(md) {
        font-size: 2.4rem;
      }

      & + .c-section-sub__text {
        margin-top: 25px;

        @include mq(md) {
          margin-bottom: 100px;
        }
      }
    }
  }

  &__text {
    margin-top: 10px;

    &--bold {
      font-size: 1.3rem;
      font-weight: bold;

      @include mq(md) {
        font-size: 1.5rem;
      }

      & + .c-section-sub__list {
        margin-top: 10px;
      }
    }

    &.right {
      text-align: right;
    }

    &--mt-l {
      margin-top: 26px;
    }
  }

  &__list {
    margin-top: 15px;
    margin-bottom: -5px;

    & + .c-section-sub__text {
      margin-top: 15px;

      @include mq(md) {
        margin-top: 30px;
      }
    }
  }

  &__list-item {
    position: relative;
    padding-bottom: 5px;
    padding-left: 20px;

    &::before {
      content: "・";
      position: absolute;
      left: 5px;
    }
  }

  &__link {
    color: $color-secondary;

    &[target="_blank"] {
      padding-right: 1.5em;
      text-decoration: underline;
      background: url("/assets/img/share/link_blank_yellow.svg") no-repeat
        center right;
      background-size: 13px;
    }

    &:hover {
      @include mq(md) {
        text-decoration: none;
      }
    }
  }

  &__frame {
    position: relative;
    margin: 10px 0;
    padding: 10px 18px 0 18px;

    @include mq(md) {
      margin: 30px 0;
      padding: 10px 25px 0 25px;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      width: 4px;
      height: 100%;
      border: 1px solid $color-gray-350;

      @include mq(md) {
        width: 8px;
      }
    }

    &::before {
      left: 0;
      border-right-width: 0;
    }

    &::after {
      right: 0;
      border-left-width: 0;
    }

    & > .c-section-sub__text:first-child {
      margin-top: 0;
    }
  }

  &__foot {
    margin-top: 30px;
    text-align: right;

    @include mq(md) {
      margin-top: 60px;
    }
  }

  &__foot-privacy {
    width: 70px;
    margin-top: 20px;

    @include mq(md) {
      width: 100px;
    }
  }
}

//以下 English Ver. 記述

.en {
  .c-section-sub {
    letter-spacing: $enBaseLetterSpacing;

    &__headline {
      font-size: 2.5rem;

      @include mq(md) {
        font-size: 3.8rem;
      }
    }
    &__lead {
      font-size: 1.4rem;
      @include mq(md) {
        font-size: 1.7rem;
      }
    }
    &__title {
      font-size: 1.7rem;
      &--l {
        font-size: 2.2rem;

        @include mq(md) {
          font-size: 2.6rem;
        }
      }
    }
    &__text {
      font-size: 1.4rem;
      @include mq(md) {
        font-size: 1.7rem;
      }
    }
    &__list-item {
      font-size: 1.4rem;
      @include mq(md) {
        font-size: 1.7rem;
      }
    }
  }
}
