.p-form {
  margin-top: 50px;
  background-color: $color-primary;
  color: #fff;

  @include mq(md) {
    margin-top: 90px;
  }

  &__inner {
    position: relative;
    min-height: 550px;
    padding: 60px 9.6% 80px;

    @include mq(md) {
      min-height: 700px;
      max-width: 715px;
      margin: 0 auto;
      padding: 90px 20px 100px;
    }
  }

  &__note {
    font-size: 1.2rem;

    @include mq(md) {
      font-size: 1.5rem;
    }
  }

  &__form-wrap {
    margin-top: 35px;

    @include mq(md) {
      margin-top: 50px;
    }
  }

  &__submitted-group {
    margin-top: 60px;
    padding: 0 9.6%;
    text-align: center;

    @include mq(md) {
      margin-top: 100px;
    }
  }

  &__submitted-title {
    font-size: 2rem;
    font-weight: bold;

    @include mq(md) {
      letter-spacing: -0.01em;
      font-size: 2.6rem;
      font-weight: 500;
    }
  }

  &__submitted-text {
    margin-top: 20px;

    @include mq(md) {
      font-size: 1.8rem;
    }
  }

  &__submitted-image {
    width: 90px;
    margin-top: 30px;

    @include mq(md) {
      width: 160px;
    }
  }

  &__submitted-button {
    max-width: 300px;
    margin: 50px auto 0;
    text-align: left;

    @include mq(md) {
      max-width: 368px;
    }
  }
}

//以下 English Ver. 記述

.en {
  .p-form {
    &__note {
      font-size: 1.4rem;

      @include mq(md) {
        font-size: 1.7rem;
      }
    }

    &__submitted-title {
      font-size: 2.2rem;
      letter-spacing: $enBaseLetterSpacing;

      @include mq(md) {
        font-size: 2.8rem;
      }
    }
    &__submitted-text {
      font-size: $enPrimaryFontSize;

      @include mq(md) {
        font-size: 2rem;
      }
    }
  }
}
