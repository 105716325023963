.c-text-link {
  text-decoration: underline;
  color: $color-secondary;

  &--mail {
    padding-left: 26px;
    background: url("/assets/img/share/mail.svg") no-repeat center left;
    background-size: 19px;
  }

  &:hover {
    @include mq(md) {
      text-decoration: none;
    }
  }
}
