.c-movie {
  position: relative;
  padding-bottom: (420/750 * 100%);

  &__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
