.c-figure-section {
  margin: 68px 0 40px;

  @include mq(md) {
    margin: 136px 0 80px;
  }

  &--medium {
    margin-bottom: 30px;

    @include mq(md) {
      margin-top: 176px;
      margin-bottom: 40px;
      padding: 0 100px;
    }
  }
  &--medium.figure-head {
    @include mq(md) {
      margin-top: 70px;
    }
  }
  &--medium.figure-middle {
    @include mq(md) {
      margin-top: 135px;
    }
  }
  &--short {
    margin-top: 30px;
    margin-bottom: 30px;

    @include mq(md) {
      margin-top: 40px;
      margin-bottom: 40px;
      padding: 0 100px;
    }
  }

}
