@keyframes infinity-scroll-left {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.c-scroll-infinity__list {
  display: flex;
  height: 100%;
  padding: 0;
  list-style: none;

  @include mq(md) {
    display: none;
  }
}

.c-scroll-infinity__list--left {
  animation: infinity-scroll-left 40s infinite linear 0.5s both;
}

.c-scroll-infinity__item {
  width: 692px;
  height: 100%;
  margin-right: 114px;

  img {
    width: 100%;
    height: 90%;
  }
}
