.c-button-more {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 17px 20px;
  text-align: center;
  font-family: $font-ratin, $font-jp;
  font-size: 1.4rem;
  font-weight: 500;
  border: 1px solid $color-black;
  background-color: rgba(256, 256, 256, 0);
  color: $color-black;
  cursor: pointer;

  @include mq(md) {
    padding: 20px;
    font-size: 1.6rem;
  }

  &.is-hidden {
    display: none;
  }
}
