.c-button-round {
  display: flex;
  align-items: center;
  position: relative;
  height: 44px;
  padding: 0 40px 0 20px;
  font-family: $font-ratin;
  font-size: 1.2rem;
  font-weight: 500;
  border: 1px solid $color-gray-100;
  border-radius: 22px;

  @include mq(md) {
    height: 28px;
    padding: 0 22px 0 14px;
    font-size: 1.2rem;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 25px;
    width: 12px;
    height: 12px;
    background: url("/assets/img/share/link_blank.svg") no-repeat center center;
    transform: translateY(-50%);

    @include mq(md) {
      right: 10px;
      width: 9px;
      height: 9px;
    }
  }

  &--m {
    height: 33px;
    padding-right: 50px;

    @include mq(md) {
      padding-right: 30px;
    }

    &::after {
      right: 14px;
    }
  }

  &--s {
    height: 28px;
    padding: 0 22px 0 14px;
    font-size: 1rem;

    @include mq(md) {
      padding: 0 30px 0 15px;
      font-size: 1.2rem;
    }

    &::after {
      right: 10px;
      width: 8px;
      height: 8px;

      @include mq(md) {
        right: 14px;
        width: 10px;
        height: 10px;
      }
    }
  }
}

//以下 English Ver. 記述

.en {
  .c-button-round {
    &--m {
      font-family: $enBaseFontFamily;
      font-size: 1.2rem;
      @include mq(sp-only) {
        padding-left: 12px;
        padding-right: 26px;
      }
      @include mq(md) {
        font-size: 1.4rem;
      }
    }
  }
}
