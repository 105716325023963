.c-list-detail {
  font-size: 1.2rem;

  @include mq(md) {
    font-size: 1.5rem;
  }

  &--cowork {
    display: flex;
    align-items: center;
  }

  &__title {
    color: $color-gray-700;
  }

  &__text {
    margin-top: 5px;

    @include mq(md) {
      margin-top: 0;
      line-height: (33/15);
    }

    & + .c-list-detail__title {
      margin-top: 10px;
    }

    & + .c-list-detail__title--mt-l {
      @include mq(md) {
        margin-top: 40px;
      }
    }
  }

  &__cowork-text {
    margin-right: 30px;

    @include mq(md) {
      margin-right: 20px;
    }
  }

  &__cowork-link {
    margin-top: 10px;

    @include mq(md) {
      width: 100px;
    }
  }

  &__cowork-image {
    flex-shrink: 0;
    width: 100px;

    @include mq(md) {
      width: 94px;
    }
  }
}

//以下 English Ver. 記述

.en {
  .c-list-detail {
    &__title {
      font-size: 1.4rem;
      line-height: 1.5;
      @include mq(md) {
        font-size: 1.7rem;
      }
    }
    &__cowork-text {
      @include mq(sp-only) {
        .c-list-detail__title {
          font-size: 1.2rem;
        }
      }
    }

    &__cowork-link {
      margin-top: 10px;
      width: 35vw;
      @include mq(sp-only) {
        max-width: 130px;
      }
      @include mq(md) {
        width: 150px;
      }
    }
    &__text {
      font-size: 1.4rem;
      line-height: 1.8;

      @include mq(md) {
        font-size: 1.7rem;
      }
    }
  }
}
