.p-about {
  margin-top: 100px;
  padding: 0 (36 / 375 * 100%);

  @include mq(md) {
    margin-top: 190px;
    padding: 0 44px;
  }

  &__section {
    & + & {
      margin-top: 40px;
      padding-top: 100px;
      border-top: 1px solid $color-gray-300;

      @include mq(md) {
        padding-top: 200px;
      }
    }

    & + .p-about__section--pt-s {
      padding-top: 50px;

      @include mq(md) {
        margin-top: 100px;
        padding-top: 80px;
      }
    }

    & + .p-about__section--mt-l {
      @include mq(md) {
        margin-top: 100px;
      }
    }
  }

  &__headline {
    font-family: $font-ratin;
    font-size: 3.5rem;
    font-weight: bold;
    line-height: 1.1;
    color: $color-secondary;

    @include mq(md) {
      font-size: 6rem;
    }
  }

  &__title-wrap {
    @include mq(md) {
      display: flex;
      align-items: center;
    }
  }

  &__title {
    align-items: center;
    position: relative;
    font-size: 1.6rem;
    font-weight: 900;

    @include mq(md) {
      align-items: flex-start;
      flex-direction: column;
      font-size: 2.4rem;
    }

    &--sub {
      margin-left: 10px;
      font-size: 1.2rem;
      font-weight: normal;
      color: $color-gray-700;

      @include mq(md) {
        margin-left: 0;
        font-size: 1.5rem;
      }
    }
  }

  &__link-button {
    margin-top: 10px;

    @include mq(md) {
      max-width: 114px;
    }

    .p-about__title-wrap & {
      @include mq(md) {
        margin-top: 0;
        margin-left: 10px;
      }
    }
  }

  &__countries-map {
    @include mq(md) {
      display: flex;
      align-items: center;
      position: relative;
      height: 100%;
    }
  }

  &__staff {
    margin-top: 40px;

    @include mq(md) {
      margin-top: 150px;
    }

    & + .p-about__grid {
      margin-top: 70px;

      @include mq(md) {
        margin-top: 200px;
      }
    }
  }

  &__group {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -25px;
    padding: 0 10px;

    @include mq(md) {
      margin-bottom: -50px;
    }

    & > .p-about__company-title {
      width: 50%;
      padding-bottom: 25px;

      @include mq(md) {
        padding-bottom: 50px;
      }
    }

    & > .p-about__group-title {
      width: 33%;
      padding-bottom: 25px;

      @include mq(md) {
        padding-bottom: 50px;
      }
    }
  }

  &__company-title {
    font-size: 1.2rem;
    font-weight: 500;

    @include mq(md) {
      font-size: 1.8rem;
    }

    &[target="_blank"] {
      &::after {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-left: 8px;
        background: url("/assets/img/share/link_blank.svg") no-repeat center
          center;

        @include mq(md) {
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  // PCで２カラムレイアウト
  &__grid {
    margin-top: 20px;

    @include mq(md) {
      display: flex;
      flex-wrap: wrap;
      position: relative;
    }

    & + & {
      margin-top: 50px;
      padding-top: 50px;
      border-top: 1px solid $color-gray-300;

      @include mq(md) {
        margin-top: 100px;
        padding-top: 100px;
      }
    }

    & + .p-about__grid--border-none {
      margin-top: 30px;
      border-top: none;
    }
  }

  &__grid-head {
    @include mq(md) {
      flex-shrink: 0;
      position: relative;
      width: 33%;
    }

    &--narrow {
      @include mq(md) {
        width: 200px;
      }
    }
  }

  &__grid-body {
    overflow: hidden;
    margin-top: 25px;

    @include mq(md) {
      width: 67%;
      margin-top: 0;
    }
  }

  &__grid-body .p-about__group-title {
    width: 50%;
    margin-bottom: 1em;
    float: left;
    /*margin-right: 1em;*/
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 2.2;

    @include mq(md) {
      width: 33%;
      font-size: 1.8rem;
    }

    span {
      display: block;
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.5;

      @include mq(md) {
        font-size: 1.4rem;
      }
    }
  }

  &__grid-body .p-about__group-title:nth-child(n + 4) {
    @include mq(md) {
      margin-bottom: 0;
    }
  }
  &__grid-body .p-about__group-title:nth-child(n + 5) {
    margin-bottom: 0;
    @include mq(md) {
    }
  }

  &__grid-body .p-about__group-title:nth-child(2n) {
    @include mq(md) {
    }
  }

  &__grid-body .p-about__group-title:nth-child(3n) {
    @include mq(md) {
    }
  }

  &__grid-cowork {
    margin-top: 25px;

    @include mq(md) {
      position: absolute;
      top: 50px;
      left: 0;
      margin-top: 0;
    }

    .p-about__grid + .p-about__grid & {
      @include mq(md) {
        top: 150px;
      }
    }
  }

  &__global-item {
    & + & {
      margin-top: 30px;

      @include mq(md) {
        margin-top: 50px;
      }
    }
  }

  &__global-body {
    margin-top: 10px;
  }

  &__global-link {
    margin-top: 10px;

    @include mq(md) {
      max-width: 114px;
    }
  }

  &__sub-title {
    font-size: 1.3rem;
    font-weight: 500;

    @include mq(md) {
      font-size: 1.5rem;
    }

    &[target="_blank"] {
      &::after {
        content: "";
        display: inline-block;
        width: 13px;
        height: 13px;
        margin-left: 8px;
        background: url("/assets/img/share/link_blank.svg") no-repeat center
          center;

        @include mq(md) {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  &__text {
    margin-top: 8px;
    font-size: 1.2rem;

    @include mq(md) {
      margin-top: 3px;
      font-size: 1.3rem;
    }
  }

  // ページ導線
  &__pager {
    margin-top: 50px;
    padding-top: 50px;
    border-top: 1px solid $color-gray-300;

    @include mq(md) {
      display: flex;
      margin-top: 100px;
      padding-top: 100px;
    }
  }

  &__pager-title {
    font-size: 1.5rem;
    font-weight: bold;

    @include mq(md) {
      flex-shrink: 0;
      margin-right: 50px;
      font-size: 2rem;
    }
  }

  &__pager-list {
    margin-top: 20px;

    @include mq(md) {
      display: flex;
      width: 100%;
      margin-top: 0;
    }
  }

  &__pager-item {
    @include mq(md) {
      width: 33.333%;
    }

    & + & {
      margin-top: 9px;

      @include mq(md) {
        margin-top: 0;
        margin-left: 10px;
      }
    }
  }

  &__pager-link {
    display: block;
    position: relative;
    padding: 13px 60px 13px 18px;
    font-weight: 500;
    border: 1px solid $color-gray-300;

    @include mq(md) {
      padding: 20px 60px 20px 25px;
    }

    &.is-current {
      border: 1px solid $color-secondary;
    }
  }

  &__pager-link-title {
    display: block;
    font-family: $font-ratin;
    font-size: 1.1rem;
    color: $color-gray;

    @include mq(md) {
      font-size: 1.3rem;
    }
  }

  &__pager-link-headline {
    display: block;
    font-size: 1.3rem;
    color: $color-primary;

    @include mq(md) {
      font-size: 1.7rem;
      font-weight: bold;
    }
  }

  &__pager-link-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    width: 28px;
    height: 28px;
    transform: translateY(-50%);
    fill: $color-black;

    @include mq(md) {
      width: 30px;
      height: 30px;
    }

    .p-about__pager-link:not(.is-current):hover & {
      @include mq(md) {
        animation: arrow-right 0.5s ease forwards;
      }
    }
  }
}

//以下 English Ver. 記述

.en {
  .p-about {
    &__title {
      font-family: $enHeadingFont;
      font-size: 1.8rem;
      line-height: 1.5;

      @include mq(md) {
        align-items: flex-start;
        flex-direction: column;
        font-size: 2.6rem;
      }
    }
    &__grid-body .p-about__group-title {
      font-size: 1.3rem;
      line-height: 1.5;

      @include mq(md) {
        font-size: 2rem;
      }

      span {
        font-size: 1.2rem;
        line-height: 1.5;

        @include mq(md) {
          font-size: 1.6rem;
        }
      }
    }
    &__company-title {
      font-size: 1.4rem;
      line-height: 1.5;

      @include mq(md) {
        font-size: 2rem;
      }
    }
    &__headline {
      font-family: $enHeadingFont;
      line-height: 1.2;
    }
    &__pager-title {
      font-family: $enHeadingFont;
      font-size: 1.7rem;

      @include mq(md) {
        font-size: 2rem;
      }
    }
    &__pager-link-title {
      line-height: 1.5;
    }

    &__pager-link-headline {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 1.5;

      @include mq(md) {
        font-size: 1.7rem;
      }
    }
  }
}
