.p-work-result {
  margin-bottom: 30px;

  @include mq(md) {
    flex-shrink: 0;
    width: 350px;
    margin-right: (100 / 1440 * 100vw);
    margin-bottom: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    & + & {
      margin-top: 10px;

      @include mq(md) {
        margin-top: 26px;
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 32px;
    margin-right: 10px;
    padding-top: 5px;
    font-family: $font-ratin;
    font-size: 1.2rem;
    font-weight: 500;
    background: url("/assets/img/share/tamkun_icon_01.svg") no-repeat center top;

    @include mq(md) {
      width: 42px;
      height: 46px;
      margin-right: 20px;
      font-size: 1.8rem;
    }
  }

  &__value {
    display: inline-block;
    font-weight: bold;
    line-height: 1;
    color: $color-primary;
  }

  &__value-plus {
    display: inline-block;
    vertical-align: super;
    font-size: 3rem;

    @include mq(md) {
      font-size: 5rem;
    }
  }

  &__value-number {
    padding: 0 2px;
    font-family: $font-ratin;
    font-size: 5.2rem;

    @include mq(md) {
      font-size: 7.7rem;
    }
  }

  &__value-small {
    font-size: 2rem;
    font-weight: 500;

    @include mq(md) {
      font-size: 3rem;
    }
  }

  &__text {
    width: 100%;
    margin-top: 5px;
    padding-left: 35px;
    font-size: 1.2rem;
    font-weight: 500;

    @include mq(md) {
      padding-left: 55px;
      font-size: 1.8rem;
    }
  }
}

/* Modal Image */

.p-micromodal.is-open {
  z-index: 10;
  position: relative;
}
.p-micromodal__trigger-btn-wrap {
  position: absolute;
  right: 2%;
  bottom: 2.5%;
  @include mq(md) {
    right: 2%;
    bottom: 3%;
  }
}
.p-stories-sanpo__contents-image-contents {
  position: relative;
}
.p-micromodal__trigger-btn {
  width: 40px;
  height: 40px;
  background-color: #ffca36;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @include mq(md) {
    width: 53px;
    height: 53px;
    cursor: pointer;
  }
  img {
    width: 18px;
    height: 18px;
    @include mq(md) {
      width: 23px;
      height: 23px;
    }
  }
}
.p-micromodal__overlay {
  background: rgba(0,0,0,.85);
}
.p-micromodal__container {
  padding: 25% 0 0;
  @include mq(md) {
    padding: 56px 2rem 0;
    max-width: 1440px;
  }
}
.p-micromodal__inner {
  padding: 0;
  // width: 100%;
  @include mq(md) {
    width: (1180/1440*100%);
    margin: 0 auto;
  }
}
.p-micromodal__btn-wrap {
  position: absolute;
  top: 1.5%;
  right: 1.5%;
  @include mq(md) {
    top: -1.4%;
    right: 1.5%;
  }
}
.p-micromodal__btn--icon {
  width: 44px;
  height: 44px;
  background-color: #fff;
  border-radius: 100%;
  margin-bottom: 30px;
  &::before,
  &::after {
    height: 15px;
    background-color: #000;
    @include mq(md) {
      height: 19px;
    }
  }
  @include mq(md) {
    width: 64px;
    height: 64px;

  }
}
.p-micromodal__text {
  color: #fff;
  font-size: 1.3rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  &::before {
    content: "";
    display: inline-block;
    background-image: url("/assets/img/page/stories/coopkobe/03/icon_scale_hand.svg");
    width: 30px;
    height: 33px;
    margin-right: 12px;
  }
  @include mq(md) {
    display: none;
  }
}


/* Relate Banner */

.relate-banner_wrapper {
  overflow: hidden;
}
a.relate-banner:hover {
  opacity: 0.7;
}
a.relate-banner.two-column:first-child figure.c-figure-section.c-figure-section--medium {
  margin-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;

  @include mq(md) {
    width: 50%;
    float: left;
    padding-left: 0;
    padding-right: 2.5%;
  }
}
a.relate-banner.two-column:last-child figure.c-figure-section.c-figure-section--medium {
  margin-top: 40px;
  padding-left: 15px;
  padding-right: 15px;

  @include mq(md) {
    width: 50%;
    float: right;
    margin-top: 176px;
    padding-left: 2.5%;
    padding-right: 0;
  }
}


/* Image ImageList(PC) */
.c-figure-section.image-list picture {
  display: block;
  margin: 0;
  @include mq(md) {
    margin-bottom: 15px;
  }
}
.c-figure-section.image-list picture img {
  display: block;
}
