.p-top-recruit {
  position: relative;
  height: 515px;
  margin-top: 100px;
  margin-bottom: 130px;

  @include mq(md) {
    position: relative;
    max-width: 1600px;
    height: 810px;
    margin: 200px auto 0;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba($color-black, 0.6);
  }

  &__inner {
    position: relative;
    z-index: 1;
    padding: 95px 20px;
    color: #fff;

    @include mq(md) {
      padding: 170px 30px;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  &__bg-video {
    position: relative;
    top: 50%;
    left: 50%;
    min-height: 100%;
    max-width: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);

    @include mq(md) {
      min-height: 100%;
      max-width: none;
      min-width: 100%;
      max-height: none;
    }

    @media (min-width: 500px) and (max-width: 796px) {
      max-width: none;
      max-height: 100%;
    }
  }

  &__title {
    position: relative;
    z-index: 3;
    padding-bottom: 16px;
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;

    @include mq(md) {
      padding-bottom: 26px;
      font-size: 2.4rem;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 14px;
      height: 1px;
      background-color: #fff;
      transform: translateX(-50%);

      @include mq(md) {
        width: 28px;
        height: 2px;
      }
    }
  }

  &__headline {
    z-index: 3;
    margin-top: 16px;
    text-align: center;
    font-family: $font-ratin;
    font-size: 3.6rem;
    font-weight: bold;
    line-height: 1.3;

    @include mq(md) {
      margin-top: 0;
      font-size: 10.8rem;
    }
  }

  &__headline-yellow {
    color: $color-secondary;
  }

  &__lead {
    z-index: 3;
    margin-top: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: (48/28);

    @include mq(md) {
      margin-top: 30px;
      letter-spacing: normal;
      font-size: 1.8rem;
      line-height: (35/18);
    }
  }

  &__link-button {
    z-index: 3;
    max-width: 300px;
    margin: 26px auto 0;

    @include mq(md) {
      max-width: 564px;
      margin-top: 50px;
    }
  }

  &__staff {
    margin-top: 30px;
    padding: 0 35px;

    @include mq(md) {
      position: absolute;
      top: calc(100% - 100px);
      right: (100 / 1440 * 100%);
      z-index: 2;
      margin: 0;
      padding: 0;
    }
  }

  &__staff-button {
    display: block;

    @include mq(md) {
      position: relative;
      width: 150px;
      height: 150px;
      border-radius: 100px;
      background-color: $color-secondary;
      transition: transform 0.3s ease;
    }

    @include mq(pc) {
      width: (200/1440 * 100vw);
      max-width: 200px;
      height: (200/1440 * 100vw);
      max-height: 200px;
    }

    &:hover {
      @include mq(md) {
        transform: scale(1.2);
      }
    }
  }

  &__staff-en {
    @include mq(md) {
      display: block;
      position: relative;
      top: 45px;
      text-align: center;
      font-family: $font-ratin;
      font-size: 2rem;
      font-weight: bold;
      color: $color-primary;
    }
  }

  &__staff-en-white {
    @include mq(md) {
      -webkit-text-fill-color: #fff;
      -webkit-text-stroke-color: $color-primary;
      -webkit-text-stroke-width: 1px;
    }
  }

  &__staff-ja {
    @include mq(md) {
      display: block;
      position: relative;
      top: 45px;
      text-align: center;
      font-size: (24/1440 * 100vw);
    }

    @include mq(lg) {
      font-size: 2.4rem;
    }
  }

  &__staff-icon {
    @include mq(md) {
      position: absolute;
      right: auto;
      bottom: 10%;
      left: 50%;
      width: 40px;
      height: 40px;
      transform: translate(-50%, 0);
    }

    @include mq(lg) {
      bottom: 44px;
    }
  }
}

//以下 English Ver. 記述
.en {
  .p-top-recruit {

    &__inner {
      padding: 95px 0px;

      @include mq(md) {
        padding: 170px 30px;
      }
    }

    &__title {
      font-family: $enBaseFontFamily;
      font-size: 1.8rem;
      line-height: 1.5;

      @include mq(md) {
        font-size: 2.6rem;
      }
    }

    &__lead {
      font-family: $enBaseFontFamily;
      font-size: 1.6rem;
      line-height: 1.8;


      @include mq(md) {
        font-size: 2rem;
        line-height: 2;
      }
    }

    &__staff-button {

      @include mq(md) {
        width: 200px;
        height: 200px;
      }
    }

    &__staff-en-white {
        -webkit-text-stroke-width: 0px;
    }

    &__staff-ja {
      font-family: $enBaseFontFamily;

      @include mq(md) {
        font-size: 2.6rem;
        line-height: 1.5;
        font-weight: 400;
      }
    }

    &__staff-icon {
      @include mq(md) {
        bottom: 20.5%;
      }
    }
  }
}

