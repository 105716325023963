.p-top-headline {
  display: flex;
  justify-content: center;
  position: relative;

  &__chars {
    display: flex;
    justify-content: space-between;
    width: calc(100% + 40px);
    margin: 0 -20px;

    @include mq(md) {
      width: calc(100% + 80px);
      margin: 0 -40px;
    }

    &--service {
      @include mq(md) {
        width: calc(100% + 200px);
        margin: 0 -100px;
      }
    }
  }

  &__char {
    text-align: center;
    font-family: $font-ratin;
    font-size: 12.5rem;
    font-weight: bold;
    line-height: 1;
    color: $color-secondary-200;
    cursor: default;
    -webkit-text-fill-color: #fff;
    -webkit-text-stroke: $color-secondary-200;
    -webkit-text-stroke-width: 1px;

    @include mq(md) {
      font-size: 34rem;
    }

    @include mq(pc) {
      font-size: calc(480 / 1440 * 100vw);
    }

    @include mq(lg) {
      font-size: 48rem;
    }

    &--fill {
      -webkit-text-fill-color: $color-secondary;
      -webkit-text-stroke: unset;
    }

    .p-top-headline__chars--animate & {
      opacity: 0;
      transition: opacity 0.3s ease;

      &:nth-child(1) {
        opacity: 1;
      }
    }

    .p-top-headline__chars--animate.is-start & {
      opacity: 1;
    }

    .p-top-headline__chars--animate & {
      @for $i from 1 through 7 {
        &:nth-child(n + #{$i}) {
          transition-delay: $i * 0.1s;
        }
      }
    }
  }

  &__front {
    position: absolute;
    top: 50%;
    left: (72 / 750 * 100%);
    padding-left: 30px;
    font-size: 1.6rem;
    font-weight: bold;
    color: $color-primary;

    @include mq(md) {
      top: 46%;
      left: (123/ 1440 * 100%);
      padding-left: 35px;
      font-size: 1.7rem;
    }

    @include mq(pc) {
      font-size: (24 / 1440 * 100vw);
    }

    @include mq(lg) {
      font-size: 2.4rem;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 20px;
      height: 1px;
      background-color: $color-primary;
      transform: translateY(-50%);

      @include mq(md) {
        width: 28px;
        height: 2px;
      }

      .p-top-headline--about & {
        top: auto;
        bottom: 0;
        left: 50%;
        background-color: $color-black;
        transform: translate(-50%, 0);
      }
    }
  }

  &__lead {
    font-size: 3.5rem;
    font-weight: 900;
    line-height: (94/70);

    @include mq(md) {
      position: absolute;
      top: 136px;
      left: (320 / 1440 * 100%);
      font-size: 4.3rem;
      line-height: (8/6);
    }

    @include mq(pc) {
      top: (212 / 1440 * 100vw);
      font-size: (60 / 1440 * 100vw);
    }

    @include mq(lg) {
      top: 212px;
      font-size: 6rem;
    }

    &--service {
      @include mq(md) {
        left: (360 / 1440 * 100%);
      }
    }
  }
}

//以下 English Ver. 記述

.en {
  .p-top-headline {
    &__front {
      font-family: $enHeadingFont;
      line-height: 1.3;
      font-size: 1.8rem;
      @include mq(md) {
        font-size: 1.9rem;
      }

      @include mq(pc) {
        font-size: (24 / 1440 * 100vw);
      }

      @include mq(lg) {
        font-size: 2.4rem;
      }
    }
    &__front--double-row {
      &::before {
        height: 2px;
        @include mq(md) {
          top: 26%;
        }
      }
    }
    &__front--work {
      text-transform: none;
    }

    &__lead {
      font-size: 4rem;
      line-height: 1.2;
      font-family: $enHeadingFont;
      letter-spacing: 0.02em;

      @include mq(md) {
        font-size: 4.1rem;
      }

      @include mq(pc) {
        font-size: (58 / 1440 * 100vw);
      }

      @include mq(lg) {
        font-size: 6.4rem;
      }
    }

    &__lead--work {
      @include mq(lg) {
        font-size: 5.8rem;
      }
    }

    &__lead--service {
      @include mq(lg) {
        font-size: 5.8rem;
      }
    }
  }

}
