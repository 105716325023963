.p-top-about {
  margin-top: 180px;

  @include mq(md) {
    position: relative;
    margin-top: 0;
    padding: 120px 0 0 0;
  }

  &__headline {
    position: relative;
    margin: 0 -20px;
    text-align: center;
  }

  &__headline-char {
    letter-spacing: 4vw;
    font-family: $font-ratin;
    font-size: 12.5rem;
    font-weight: bold;
    line-height: 1;
    color: $color-secondary-200;
    -webkit-text-fill-color: #fff;
    -webkit-text-stroke-color: $color-secondary-200;
    -webkit-text-stroke-width: 1px;

    @include mq(md) {
      position: absolute;
      top: -180px;
      left: (370/1440 * 100%);
      letter-spacing: 0.08em;
      font-size: 18.5rem;
      transform: rotate(90deg);
      transform-origin: left;
    }

    @include mq(pc) {
      font-size: (258/1440 * 100vw);
    }

    @include mq(lg) {
      font-size: 25.8rem;
    }
  }

  &__headline-front {
    position: absolute;
    bottom: 20px;
    left: 50%;
    padding-bottom: 20px;
    font-size: 1.6rem;
    font-weight: bold;
    transform: translateX(-50%);

    @include mq(md) {
      display: none;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 14px;
      height: 2px;
      background-color: $color-black;
      transform: translateX(-50%);
    }
  }

  &__headline-front-pc {
    position: relative;
    padding-bottom: 30px;
    text-align: center;
    font-size: 2.4rem;
    font-weight: bold;

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 28px;
      height: 2px;
      background-color: $color-black;
      transform: translateX(-50%);
    }
  }

  &__body {
    position: relative;
    margin: 26px 0 10px;
    padding: 25px (45/375 * 100vw) 25px (120/375 * 100vw);
    background: url("/assets/img/page/top/hero_bg_01.svg");
    background-size: 500px;

    @include mq(md) {
      display: flex;
      justify-content: center;
      margin: 75px 0 0 0;
      padding: 24px 0;
      background-size: 640px;
    }
  }

  &__image {
    position: absolute;
    top: -10px;
    left: (10/375 * 100vw);
    width: 95px;

    @include mq(md) {
      top: -26px;
      left: 50%;
      width: 140px;
      // for IE11
      transform: translateX(-50%) translateX(-170px);
    }
  }

  &__amount {
    @include mq(md) {
      margin-left: 110px;
    }
  }

  &__amount-text {
    display: block;
    width: 80px;
    font-size: 1.2rem;

    @include mq(md) {
      width: 130px;
      font-size: 1.8rem;
    }
  }

  &__amount-number {
    display: inline-block;
    margin-top: -10px;
    white-space: nowrap;
    font-family: $font-ratin;
    font-size: 11rem;
    font-weight: bold;
    line-height: 1;
    color: $color-primary;

    @include mq(md) {
      font-size: 16.4rem;
    }

    &--s {
      font-family: $font-jp;
      font-size: 3.6rem;

      @include mq(md) {
        font-size: 5.4rem;
      }
    }
  }

  &__description {
    margin-top: 35px;
    text-align: center;
    font-size: 1.2rem;

    @include mq(md) {
      position: relative;
      z-index: 2;
      width: 368px;
      margin: 70px auto 0;
      padding: 10px;
      font-size: 1.5rem;
    }

    &::before,
    &::after {
      @include mq(md) {
        content: "";
        display: block;
        position: absolute;
        width: 8px;
        height: 8px;
        border: 1px solid $color-gray-350;
      }
    }

    &::before {
      @include mq(md) {
        top: 0;
        right: 0;
        border-width: 1px 1px 0 0;
      }
    }

    &::after {
      @include mq(md) {
        right: 0;
        bottom: 0;
        border-width: 0 1px 1px 0;
      }
    }
  }

  &__description-frame {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &::before,
    &::after {
      @include mq(md) {
        content: "";
        display: block;
        position: absolute;
        width: 8px;
        height: 8px;
        border: 1px solid $color-gray-350;
      }
    }

    &::before {
      @include mq(md) {
        bottom: 0;
        left: 0;
        border-width: 0 0 1px 1px;
      }
    }

    &::after {
      @include mq(md) {
        top: 0;
        left: 0;
        border-width: 1px 0 0 1px;
      }
    }
  }

  &__link-button {
    max-width: 300px;
    margin: 30px auto 0;

    @include mq(md) {
      position: relative;
      z-index: 2;
      max-width: 368px;
      margin-top: 40px;
    }
  }

  &__members {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    max-width: 1440px;
    height: 100%;
    transform: translateX(-50%);

    @include mq(md) {
      top: -120px;
    }
  }

  &__member {
    position: absolute;
    opacity: 0;
    transition: opacity 1s ease;

    &::after {
      @include mq(md) {
        content: attr(data-title);
        display: block;
        white-space: nowrap;
        font-family: $font-ratin;
        font-size: (13/1440 * 100vw);
        line-height: (19/13);
        color: $color-gray-500;
      }
    }

    &:nth-child(7) {
      @include mq(md) {
        top: 67.55px;
        left: 34.1167px;
        width: (125/1440 * 100%);
        max-width: 125px;
      }

      @include mq(pc) {
        top: (95/1440 * 100vw);
        left: (48/1440 * 100vw);
      }
    }

    &:nth-child(1) {
      @include mq(md) {
        top: 149.317px;
        left: 192px;
        width: (73/1440 * 100%);
        max-width: 73px;
      }

      @include mq(pc) {
        top: (210/1440 * 100vw);
        left: (270/1440 * 100vw);
      }
    }

    &:nth-child(8) {
      @include mq(md) {
        top: 302.217px;
        left: 0;
        width: (94/1440 * 100%);
        max-width: 94px;
      }

      @include mq(pc) {
        top: (425/1440 * 100vw);
      }
    }

    &:nth-child(3) {
      @include mq(md) {
        top: 258.117px;
        left: 113.767px;
        width: (73/1440 * 100%);
        max-width: 73px;
      }

      @include mq(pc) {
        top: (363/1440 * 100vw);
        left: (160/1440 * 100vw);
      }
    }

    &:nth-child(5) {
      @include mq(md) {
        top: 403.9px;
        left: 162.117px;
        width: (94/1440 * 100%);
        max-width: 94px;
      }

      @include mq(pc) {
        top: (568/1440 * 100vw);
        left: (228/1440 * 100vw);
      }
    }

    &:nth-child(6) {
      @include mq(md) {
        top: 99.55px;
        right: 169.95px;
        width: (94/1440 * 100%);
        max-width: 94px;
      }

      @include mq(pc) {
        top: (140/1440 * 100vw);
        right: (239/1440 * 100vw);
      }
    }

    &:nth-child(4) {
      @include mq(md) {
        top: 187.717px;
        right: 53.3167px;
        width: (73/1440 * 100%);
        max-width: 73px;
      }

      @include mq(pc) {
        top: (264/1440 * 100vw);
        right: (75/1440 * 100vw);
      }
    }

    &:nth-child(2) {
      @include mq(md) {
        top: 312.883px;
        right: 0;
        width: (96/1440 * 100%);
        max-width: 96px;
      }

      @include mq(pc) {
        top: (440/1440 * 100vw);
      }
    }

    &:nth-child(9) {
      @include mq(md) {
        top: 396.083px;
        right: 118.75px;
        width: (125/1440 * 100%);
        max-width: 125px;
      }

      @include mq(pc) {
        top: (557/1440 * 100vw);
        right: (167/1440 * 100vw);
      }
    }

    &.is-show {
      opacity: 1;
    }
  }
}

//以下 English Ver. 記述
.en {
  .p-top-about {
    &__headline-front {
      font-family: $enBaseFontFamily;
      font-size: 1.8rem;
      line-height: 1.5;
    }

    &__headline-front-pc {
      font-family: $enBaseFontFamily;
      font-size: 2.6rem;
      line-height: 1.5;
    }

    &__amount-number {

      &--s {
        font-family: $enBaseFontFamily;
        font-size: 2.4rem;
        line-height: 1.5;
      }
    }

    &__description {
      font-family: $enBaseFontFamily;
      font-size: 1.4rem;
      line-height: 1.5;

      @include mq(md) {
        font-size: 1.7rem;
        width: 371px;
      }
    }
  }
}
