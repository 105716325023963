.p-stories {
  margin-top: 20px;

  @include mq(md) {
    margin-top: 108px;
  }

  &__section {
    margin-top: 100px;

    @include mq(md) {
      margin-top: 200px;
    }

    &--mt-none {
      margin-top: 0;
    }

    // SPで左右のpaddingが少ない
    &--sp-narrow {
      padding: 0 (15 / 375 * 100%);

      @include mq(md) {
        max-width: 1240px;
        margin: 200px auto 0;
        padding: 0 20px;
      }
    }
  }

  &__headline {
    position: relative;
    margin: 0 auto;
    padding: 0 23px;
    text-align: center;

    @include mq(md) {
      max-width: 465px;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      width: 6px;
      height: 100%;
      border: 1px solid $color-gray-350;
    }

    &::before {
      left: 0;
      border-width: 1px 0 1px 1px;
    }

    &::after {
      right: 0;
      border-width: 1px 1px 1px 0;
    }

    &--pc-wide {
      @include mq(md) {
        max-width: 600px;
      }
    }
  }

  &__headline-title {
    display: block;
    font-family: $font-ratin;
    font-size: 2.3rem;
    font-weight: bold;
    line-height: 1;

    @include mq(md) {
      font-size: 3.6rem;
    }
  }

  &__headline-text {
    display: block;
    margin-top: 10px;
    letter-spacing: -0.01em;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: (42/22);

    @include mq(md) {
      margin-top: 20px;
      font-size: 1.6rem;
    }
  }
  &__headline-wrap {
    padding: 0 (26/750 * 100%);
    @include mq(md) {
      max-width: 1232px;
      padding: 0 20px;
      margin: 0 auto;
    }
  }
  &__headline--lg {
    max-width: initial;
    padding: 6px 11px 3px;
    @include mq(md) {
      display: flex;
      padding: 13px (80/1192 * 100%) 5px (90/1192 * 100%);
    }
  }
  &__headline-img-wrap {
    @include mq(md) {
      width: (493/1022 * 100%);
      margin-right: 0;
    }
  }
  &__headline-img {
    vertical-align: middle;
  }
  &__headline-text-wrap {
    @include mq(md) {
      background: url(/assets/img/page/stories/bg_dialog_headline.png) no-repeat
        center bottom 19px;
      background-size: contain;
      width: (529/1022 * 100%);
    }
  }
  &__headline-lead {
    font-size: 1rem;
    color: #172a88;
    font-weight: 600;
    text-align: initial;
    line-height: 1.71;
    @include mq(md) {
      font-size: 1.6rem;
      padding: 40px 0 0 (93/529 * 100%);
      line-height: 1.62;
      letter-spacing: 0.07em;
    }
    @include mq(pc-content) {
      padding: 80px 0 0 (93/529 * 100%);
    }
  }
  &__headline-category-list {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-top: 5px;
    @include mq(md) {
      flex-direction: inherit;
      padding-left: (94/529 * 100%);
      margin-top: 18px;
    }
  }
  &__headline-category-item {
    font-family: $font-ratin;
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    flex-direction: column;
    letter-spacing: 0.04em;
    line-height: 0.9;
    span {
      display: inline;
      font-size: 10px;
      background-color: #05c4fb;
      padding: 0 5px 1px;
      @include mq(md) {
        font-size: 1.2rem;
        padding: 0 5px 3px;
      }
    }
    & + .p-stories__headline-category-item {
      @include mq(sp-only) {
        margin-top: 3.5px;
      }
      @include mq(md) {
        margin-left: 8.5px;
      }
    }
  }

  //追加
  //社長ぶらり散歩
  &__dialog {
    margin-bottom: 106px;
    @include mq(md) {
      margin-bottom: 189px;
      padding: 0;
    }
  }
  &__dialog-link {
    @include mq(md) {
    }
  }

  &__dialog-list-wrap {
    position: relative;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    margin-top: 37px;
    padding: 0 0 20px (26/750 * 100%);
    @include mq(md) {
      overflow: visible;
      margin-top: 84px;
      padding: 0 20px;
    }

    @include mq(pc-content) {
      padding: 0 calc((100vw - 1200px) / 2);
    }
  }
  &__dialog-list {
    display: flex;
    // width: calc((275px * 8) + (36px * 7));
    padding-right: 9.6%;
    @include mq(md) {
      padding-bottom: 50px;
    }
  }
  &__dialog-item-wrap {
    @include mq(md) {
      background: linear-gradient(
        180deg,
        #fff 0%,
        #fff 23%,
        #f7f7f7 23%,
        #f7f7f7 100%
      );
      padding-bottom: 109px;
    }
  }
  &__dialog-figure {
    position: relative;
    padding-bottom: 56.25%;
    @include mq(md) {
    }
  }
  &__dialog-image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease, opacity 0.3s ease;
    @include mq(md) {
    }
    .p-stories__dialog-figure:hover & {
      @include mq(md) {
        transform: scale(0.95);
        transform-origin: center;
      }
    }
  }
  &__dialog-badge {
    @include mq(md) {
    }
  }
  &__dialog-badge:not(:root) {
    left: 8px;
    top: 101px;
    @include mq(md) {
      width: 50px;
      height: 50px;
      font-size: 1.2rem;
      left: 10px;
      top: initial;
      bottom: 10px;
    }
    @include mq(pc-content) {
      top: 188px;
      left: 12px;
    }
  }
  &__dialog-date {
    display: inline-block;
    margin-top: 15px;
    font-family: "Barlow Semi Condensed";
    font-size: 1.2rem;
    font-weight: 500;
    color: #c4c4c4;
    padding-right: 8px;
    @include mq(md) {
      margin-top: 30px;
      font-size: 1.7rem;
      padding-right: 10px;
    }
    &::after {
      content: "|";
      color: #c4c4c4;
      padding-left: 8px;
    }
  }
  &__dialog-campany {
    color: $color-primary;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.02em;
    @include mq(md) {
      font-size: 1.4rem;
    }
  }
  &__dialog-link {
    @include mq(md) {
    }
  }
  &__dialog-link {
    @include mq(md) {
    }
  }
  &__dialog-link {
    @include mq(md) {
    }
  }
  &__dialog-link {
    @include mq(md) {
    }
  }

  //一覧へ
  &__link-button {
    max-width: 300px;
    margin: 13px auto 0;
    @include mq(md) {
      max-width: 396px;
      margin: 74px auto 0;
    }
  }

  // TAM made By people
  &__people {
    margin-top: 40px;

    @include mq(md) {
      margin-top: 80px;
    }
  }

  &__list-wrap {
    position: relative;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    margin-top: 45px;
    padding: 0 0 20px (26/750 * 100%);

    @include mq(md) {
      overflow: visible;
      margin-top: 100px;
      padding: 0 20px;
    }

    @include mq(pc-content) {
      padding: 0 calc((100vw - 1200px) / 2);
    }
  }

  &__people-list {
    display: flex;
    // overflow-x: scroll;
    width: calc((275px * 8) + (36px * 7));
    padding-right: 9.6%;

    @include mq(md) {
      overflow-x: visible;
      width: auto;
      padding-right: 0;
      padding-bottom: 50px;
    }
  }

  &__people-item,
  &__dialog-item {
    width: 264px;

    @include mq(md) {
      width: 450px;
    }

    & + & {
      margin-left: 36px;

      @include mq(md) {
        margin: 0;
      }
    }

    &--first {
      position: relative;
      width: (600/750 * 100%);
      margin: 0 auto;

      @include mq(md) {
        width: 100%;
        max-width: 1240px;
        padding: 0 20px;
      }
    }
  }

  &__people-badge,
  &__dialog-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    top: -23px;
    left: -20px;
    width: 40px;
    height: 40px;
    font-family: $font-ratin;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 25px;
    background-color: $color-secondary;
    color: $color-primary;

    @include mq(md) {
      top: 0;
      left: 0;
      width: 67px;
      height: 67px;
      font-size: 1.5rem;
      border-radius: 35px;
    }
  }
  &__people-badge--sp-only {
    @include mq(md) {
      display: none;
    }
  }

  &__people-link {
    display: block;

    .p-stories__people-item--first & {
      @include mq(md) {
        display: flex;
        align-items: flex-start;
        flex-direction: row-reverse;
      }
    }
  }

  &__people-figure {
    position: relative;
    padding-bottom: (236/450 * 100%);

    .p-stories__people-item--first & {
      @include mq(md) {
        flex-shrink: 0;
        width: (698/1440 * 100vw);
        max-width: 644px;
        min-width: 495px;
        padding-bottom: 0;
      }
    }
  }

  &__people-image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease, opacity 0.3s ease;

    .p-stories__people-item--first & {
      @include mq(md) {
        position: static;
      }
    }

    .p-stories__people-figure:hover & {
      @include mq(md) {
        transform: scale(0.95);
        transform-origin: center;
      }
    }
  }

  &__people-body {
    .p-stories__people-item--first & {
      @include mq(md) {
        width: 100%;
        margin-right: 80px;
      }
    }
  }

  &__people-number {
    position: absolute;
    right: 10px;
    bottom: -35px;
    font-family: $font-ratin;
    font-size: 7.9rem;
    font-weight: bold;
    line-height: 1;
    color: $color-secondary;

    @include mq(md) {
      font-size: 8.6rem;
    }

    .p-stories__people-item--first & {
      @include mq(md) {
        top: -16px;
        right: calc(100% + 80px);
        bottom: auto;
      }
    }
  }

  &__people-date {
    display: block;
    margin-top: 15px;
    font-family: $font-ratin;
    font-size: 1.2rem;
    font-weight: 500;
    color: $color-gray;

    @include mq(md) {
      margin-top: 30px;
      font-size: 1.7rem;
    }

    .p-stories__people-item--first & {
      margin-top: 10px;

      @include mq(md) {
        margin-top: 20px;
        padding-left: 90px;
      }
    }
  }

  &__people-title {
    margin-top: 10px;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: (55/28);

    @include mq(md) {
      min-height: 70px;
      margin-top: 10px;
      font-size: 1.8rem;
    }

    .p-stories__people-item--first & {
      margin-top: 20px;
      font-size: 1.8rem;

      @include mq(md) {
        margin-top: 45px;
        font-size: 2.6rem;
        line-height: (42/26);
      }
    }
  }

  &__people-text {
    margin-top: 10px;
    font-size: 1.2rem;
    color: $color-gray-700;

    @include mq(md) {
      margin-top: 20px;
      font-size: 1.4rem;
      line-height: (28/14);
    }

    .p-stories__people-item--first & {
      @include mq(md) {
        margin-top: 30px;
      }
    }
  }

  // TOPICS
  &__topics-list-wrap {
    padding: 0 (26 / 750 * 100%);
  }
  &__topics-list {
    display: grid;
    margin: 40px -5px -25px;
    grid-template-columns: 50% 50%;
    // for ie11
    grid-template-rows: repeat(5, auto);

    @include mq(md) {
      // margin: 80px -20px -30px;
      max-width: 1240px;
      padding: 0 20px;
      margin: 80px auto 0;
      grid-template-columns: 33.333% 16.6% 17% 8% 25%;
      // for ie11
      grid-template-rows: repeat(3, auto);
    }
  }

  &__topics-item {
    position: relative;
    padding: 0 5px 25px;

    @include mq(md) {
      padding: 0 20px 30px;
    }

    &:nth-child(1),
    &:nth-child(6) {
      @include mq(sp-only) {
        grid-column: 1/3;
      }
    }

    &:nth-child(1) {
      @include mq(md) {
        grid-column: 1/3;
        grid-row: 1/3;
      }
    }

    &:nth-child(2) {
      @include mq(md) {
        grid-column: 3/5;
      }
    }

    &:nth-child(3) {
      @include mq(md) {
        grid-column: 5/6;
      }
    }

    &:nth-child(4) {
      @include mq(md) {
        grid-column: 3/5;
        grid-row: 2/3;
      }
    }

    &:nth-child(5) {
      @include mq(md) {
        grid-column: 5/6;
        grid-row: 2/3;
      }
    }

    &:nth-child(6) {
      @include mq(md) {
        grid-column: 1/2;
        grid-row: 3/4;
      }
    }

    &:nth-child(7) {
      @include mq(md) {
        grid-column: 2/4;
        grid-row: 3/4;
      }
    }

    &:nth-child(8) {
      @include mq(md) {
        grid-column: 4/6;
        grid-row: 3/4;
      }
    }
  }

  &__topics-icon {
    position: absolute;
    top: 7px;
    left: 10px;
    z-index: 1;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    background: $color-secondary url("/assets/img/share/stories_icon_01.svg")
      no-repeat center center;
    background-size: 46%;

    @include mq(md) {
      top: 10px;
      left: 30px;
      width: 28px;
      height: 28px;
    }

    &.press-report,
    &.press-event {
      background: $color-secondary url("/assets/img/share/stories_icon_02.svg")
        no-repeat center center;
      background-size: 50%;
    }

    &.fb-report,
    &.fb-event-dojo,
    &.fb-event-pwa,
    &.fb-seminar {
      background: $color-secondary url("/assets/img/share/stories_icon_03.svg")
        no-repeat center center;
      background-size: 28%;
    }

    // SPでは1つ目と6つ目が大サイズ
    .p-stories__topics-item:nth-child(1) &,
    .p-stories__topics-item:nth-child(6) & {
      @include mq(sp-only) {
        top: 8px;
        left: 13px;
        width: 35px;
        height: 35px;
      }
    }

    // PCでは1つ目が大サイズ
    .p-stories__topics-item:nth-child(1) & {
      @include mq(md) {
        width: 52px;
        height: 52px;
      }
    }

    // PCでは6〜8つ目が中サイズ
    .p-stories__topics-item:nth-child(n + 6) & {
      @include mq(md) {
        width: 38px;
        height: 38px;
      }
    }
  }

  &__topics-link {
    display: block;
  }

  &__topics-figure {
    position: relative;
  }

  &__topics-image {
    display: block;
    transition: all 0.3s ease;
    transition: transform 0.3s ease;

    &--border {
      border: 1px solid $color-gray-100;
    }

    .p-stories__topics-figure:hover & {
      @include mq(md) {
        transform: scale(0.9);
        transform-origin: center;
      }
    }
  }
  &__topics-date {
    display: block;
    margin-top: 15px;
    font-family: $font-ratin;
    font-size: 1.3rem;
    font-weight: 500;
    color: $color-gray;
    padding-left: 20px;

    @include mq(md) {
      margin-top: 12px;
      font-size: 1.3rem;
    }

    .p-stories__topics-item:nth-child(1) & {
      @include mq(md) {
        padding-left: 36px;
        font-size: 1.8rem;
      }
    }
  }

  &__topics-category {
    position: relative;
    margin-top: 3px;
    padding-left: 20px;
    font-size: 1.3rem;
    font-weight: 500;
    color: $color-primary;

    @include mq(md) {
      font-size: 1.4rem;
    }

    .p-stories__topics-item:nth-child(1) & {
      @include mq(md) {
        padding-left: 36px;
        font-size: 2.3rem;
      }
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 10.5px;
      left: 0;
      width: 12px;
      height: 1px;
      background-color: $color-primary;

      @include mq(md) {
        top: 11.5px;
        height: 2px;
      }

      .p-stories__topics-item:nth-child(1) & {
        @include mq(md) {
          top: 18px;
          width: 25px;
        }
      }
    }
  }

  &__topics-title {
    margin-top: 5px;
    padding-left: 20px;
    font-size: 1.2rem;
  }

  &__topics-text {
    @include mq(md) {
      margin-top: 10px;
      padding-left: 20px;
      font-size: 1.3rem;
      line-height: (25/13);
    }

    .p-stories__topics-item:nth-child(1) & {
      @include mq(md) {
        margin-top: 16px;
        padding-left: 36px;
        font-size: 2.2rem;
      }
    }
  }

  // MEDIA
  &__media-list {
    padding: 0 (26/750 * 100%);
    display: flex;
    flex-wrap: wrap;
    margin: 40px -7px -11px;

    @include mq(md) {
      // margin: 80px -20px -40px;
      max-width: 1240px;
      padding: 0 20px;
      margin: 80px auto 0;
    }
  }

  &__media-item {
    width: 50%;
    padding: 0 7px 11px;

    @include mq(md) {
      width: 33.33%;
      padding: 0 20px 40px;
    }
  }

  &__media-link {
    display: block;
    position: relative;
    height: 100%;
    padding: 25px 15px;
    border: 1px solid $color-gray-100;
    background: #fff url("/assets/img/share/link_blank.svg") no-repeat;
    background-position-x: calc(100% - 8px);
    background-position-y: calc(100% - 8px);
    background-size: 9px;

    @include mq(md) {
      padding: 60px 40px 50px 40px;
      background-position-x: calc(100% - 10px);
      background-position-y: calc(100% - 10px);
      background-size: 14px;
    }

    &::before,
    &::after {
      @include mq(md) {
        content: "";
        display: block;
        position: absolute;
        bottom: -1px;
        width: 0;
        height: 2px;
        background-color: $color-secondary;
        transition: width 0.3s ease;
      }
    }

    &::before {
      right: 50%;
    }

    &::after {
      left: 50%;
    }

    &:hover {
      &::before,
      &::after {
        @include mq(md) {
          width: 50%;
        }
      }
    }
  }

  &__media-image {
    display: block;
    max-width: 100%;
    margin: 0 auto;

    &--shakaika {
      width: 92px;

      @include mq(md) {
        width: 210px;
        margin: 10px auto 30px;
      }
    }

    &--egakou {
      width: 70px;

      @include mq(md) {
        width: 163px;
      }
    }

    &--movie {
      width: 21px;

      @include mq(md) {
        width: 48px;
      }
    }

    &--note {
      width: 93px;
      margin-top: 10px;

      @include mq(md) {
        width: 212px;
        margin-top: -10px;
      }
    }

    &--koho {
      width: 93px;
      margin-top: 10px;

      @include mq(md) {
        width: 212px;
      }
    }

    &--tips {
      width: 90px;

      @include mq(md) {
        width: 206px;
        margin: 0 auto 30px;
      }
    }

    &--monokaki {
      width: 76px;

      @include mq(md) {
        width: 174px;
      }
    }
  }

  &__media-text {
    margin-top: 7px;
    text-align: center;
    font-size: 0.9rem;
    color: $color-gray-700;

    @include mq(md) {
      margin-top: 20px;
      font-size: 1.4rem;
    }
  }
}

//以下 English Ver. 記述

.en {
  .p-stories {
    &__headline-lead {
      font-size: 1.2rem;
      letter-spacing: 0.018em;
      @include mq(md) {
        font-size: 1.8rem;
      }
    }
    &__headline-title {
      font-size: 2.5rem;
      @include mq(md) {
        font-size: 3.8rem;
      }
    }
    &__headline-text {
      letter-spacing: $enBaseLetterSpacing;
      font-size: 1.3rem;

      @include mq(md) {
        font-size: 1.8rem;
      }
    }
  }
}
