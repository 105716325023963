//以下 English Ver. 記述

.en {
  .p-staff {
    .staff-Number_Inner {
      font-size: 1.9rem;
      @include mq(md) {
        font-size: 2.8rem;
      }
    }

    .staff-Page .menu {
      .staff-Page-select select {
        font-size: 1.53333rem;
      }
      .tab {
        font-size: 1.8rem;
      }
    }
  }
}
