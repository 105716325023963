.p-service-framework {
  &__button {
    position: fixed;
    top: 330px;
    right: 13px;
    z-index: 6;
    min-width: 120px;
    padding: 5px 20px 8px 10px;
    //font-size: 1rem;
    font-size: 1rem;
    font-weight: bold;
    background-color: $color-secondary;
    cursor: pointer;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    transform: rotate(90deg);
    transform-origin: right;

    @include mq(md) {
      top: 530px;
      right: 26px;
      padding: 15px 40px 15px 20px;
      letter-spacing: 0.05em;
      //font-size: 1.8rem;
      font-size: 16px;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 11px;
      right: 11px;
      width: 7px;
      height: 1px;
      background-color: $color-black;
      transition: opacity 0.3s ease;

      @include mq(md) {
        top: 26px;
        right: 16px;
        width: 12px;
        height: 2px;
      }
    }

    &::after {
      transform: rotate(90deg);
    }

    .is-menu-open & {
      z-index: 3;
    }

    &.is-open {
      &::before {
        opacity: 0;
      }
    }

    &.is-hidden {
      visibility: hidden;
      opacity: 0;
    }
  }

  // モーダル閉じるボタン
  &__modal-close {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 5;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;

    @include mq(md) {
      top: 24px;
      right: 24px;
      width: 64px;
      height: 64px;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      width: 13px;
      height: 1px;
      background-color: $color-black;
      transform: translate(-50%, -50%);
      transform-origin: center;

      @include mq(md) {
        width: 20px;
        height: 2px;
      }
    }

    &::before {
      top: calc(50%);
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      top: calc(50%);
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &__modal {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 5;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    opacity: 0;
    transition: visibility 0.3s ease, opacity 0.5s ease, left 0.5s ease;

    @include mq(md) {
      width: 480px;
      height: 888px;
      max-height: 100vh;
    }

    &.is-show {
      visibility: visible;
      left: 0;
      opacity: 1;

      @include mq(md) {
        left: calc(100% - 480px);
      }
    }
  }

  &__inner {
    overflow-y: auto;
    height: 100%;
    padding: 50px 60px;
    background-color: rgba($color-blue, 0.95);
    color: #fff;

    @include mq(md) {
      padding: 80px 80px 60px 80px;
    }
  }

  &__title {
    white-space: nowrap;
    //font-size: 3rem;
    font-size: 22px;
    font-weight: bold;

    @include mq(md) {
      //font-size: 3.6rem;
      font-size: 24px;
    }
  }

  &__list {
    margin-top: 15px;

    @include mq(md) {
      margin-top: 30px;
    }
  }

  &__item {
    padding-bottom: 40px;

    @include mq(md) {
      padding-bottom: 40px;
    }

    & + & {
      padding-top: 40px;
      border-top: 1px solid #8ec8e4;

      @include mq(md) {
        padding-top: 50px;
      }
    }
  }

  &__item-title {
    font-size: 1.5rem;
    font-weight: bold;

    @include mq(md) {
      font-size: 1.7rem;
    }
  }

  &__item-text {
    margin-top: 10px;
    font-size: 1.3rem;
    line-height: (46/26);

    @include mq(md) {
      font-size: 1.4rem;
    }
  }

  &__item-link {
    position: relative;
    width: 100px;
    margin-top: 20px;
    margin-left: auto;
    text-align: right;
    text-align: left;

    @include mq(md) {
      width: 120px;
    }
  }
}
