@charset "UTF-8";
.c-vuejs-pagenation {
}
.c-vuejs-pagenation__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq(sp-only) {
    width: 100%;
    margin-right: 4.8rem;
  }

  @include mq(md) {
    justify-content: center;
  }
}
.c-vuejs-pagenation__item {
  @include mq(md) {
    margin-right: 4.8rem;
  }
  &:last-child {
    margin-right: 0;
  }
  &--prev,
  &--next {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 53px;
    height: 53px;
    border: 1px solid #e2e2e2;
    border-radius: 50%;
    cursor: pointer;
    flex-shrink: 0;
  }
  &.disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
  &--break-view {
    &.disabled {
      opacity: 1;
    }
  }
  &--active {
    color: $color-blue;
  }
}
.c-vuejs-pagenation__link {
  display: flex;
  font-size: 2rem;
  font-family: $font-ratin;
  font-weight: 500;
  @include mq(md) {
    font-size: 2.2rem;
  }
}

.c-vuejs-pagenation-prev-icon,
.c-vuejs-pagenation-next-icon {
  width: 25px;
  height: 25px;
  @include mq(md) {
  }
}
.c-vuejs-pagenation-prev-icon {
  transform: scale(-1, 1);
  @include mq(md) {
  }
}
.c-vuejs-pagenation-next-icon {
  @include mq(md) {
  }
}
.c-vuejs-pagenation__list {
  @include mq(md) {
  }
}
.c-vuejs-pagenation__list {
  @include mq(md) {
  }
}
.c-vuejs-pagenation__list {
  @include mq(md) {
  }
}
