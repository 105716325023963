.p-top-service {
  position: relative;
  margin-top: 80px;

  @include mq(md) {
    margin: 80px auto 0;
  }

  &__button {
    display: flex;
    position: relative;
    width: 150px;
    margin-top: 15px;
    margin-left: auto;
    padding: 10px 25px 10px 10px;
    text-align: left;
    white-space: nowrap;
    font-size: 1rem;
    font-weight: 500;
    background-color: $color-black;
    color: #fff;

    @include mq(md) {
      width: 200px;
      margin-top: 50px;
      margin-left: (360 / 1440 * 100%);
      padding: 0;
      font-size: 1.6rem;
      font-weight: normal;
      background-color: #fff;
      color: $color-black;
    }
  }

  &__button-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
    fill: #fff;

    @include mq(md) {
      right: 0;
      width: 30px;
      height: 30px;
      fill: $color-black;
    }

    .p-top-service__button:hover & {
      @include mq(md) {
        animation: arrow-right 0.5s ease forwards;
      }
    }
  }

  &__inner {
    @include mq(md) {
      display: flex;
      max-width: 1600px;
      margin: 110px auto 0;
    }
  }

  &__list-wrap {
    margin-top: 60px;
    padding: 0 15px;

    @include mq(md) {
      position: relative;
      z-index: 3;
      width: (674 / 1440 * 100%);
      margin-top: 0;
      padding: 80px 66px 80px 35px;
      background: url("/assets/img/page/top/service_list_bg.jpg") no-repeat center right;
      background-size: cover;
    }

    &::after {
      @include mq(md) {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba($color-black, 0.5);
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px -26px;

    @include mq(md) {
      display: block;
      position: relative;
      z-index: 2;
      margin: 0;
    }
  }

  &__item {
    width: 50%;
    padding: 0 5px 26px;

    @include mq(md) {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      position: relative;
      width: auto;
      height: 80px;
      padding: 0;
    }
  }

  &__item-picture {
    position: relative;

    @include mq(md) {
      display: none;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__item-image {
    display: block;
  }

  &__item-icon {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 14px;
    height: 14px;
    fill: #fff;
  }

  &__item-number {
    position: absolute;
    width: 2em;
    margin-top: 7px;
    font-family: $font-ratin;
    font-size: 1.3rem;
    font-weight: 500;
    color: $color-gray-500;

    @include mq(md) {
      position: absolute;
      position: static;
      right: 0;
      z-index: 3;
      margin-top: 0;
      text-align: right;
      font-size: 1.4rem;
      color: #fff;
      transition: color 0.3s ease;
    }

    &::before {
      @include mq(md) {
        content: "";
        display: block;
        position: absolute;
        top: 18px;
        right: -10px;
        z-index: -1;
        width: 34px;
        height: 37px;
        background: url("/assets/img/share/tamkun_icon_01.svg") no-repeat;
        opacity: 0;
        transition: opacity 0.3s ease;
      }
    }

    .p-top-service__item.is-current & {
      @include mq(md) {
        color: $color-black;
      }

      &::before {
        @include mq(md) {
          opacity: 1;
        }
      }
    }
  }

  &__item-title {
    margin-top: 7px;
    padding-left: 20px;
    letter-spacing: 0.01em;
    font-size: 1.4rem;
    font-weight: bold;

    @include mq(md) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      right: 40px;
      z-index: 3;
      height: 100%;
      margin-top: 0;
      padding: 0;
      text-align: right;
      font-size: 2rem;
      font-weight: 500;
      color: #fff;
      cursor: pointer;
      transition: font-size 0.3s ease;
    }

    .p-top-service__item.is-current & {
      @include mq(md) {
        font-size: 2.8rem;
      }
    }
  }

  &__detail {
    @include mq(md) {
      display: block;
      overflow: hidden;
      width: (767 / 1440 * 100%);
    }
  }

  // モーダル閉じるボタン
  &__detail-close {
    position: fixed;
    top: 16px;
    right: 16px;
    z-index: 5;
    width: 44px;
    height: 44px;
    border-radius: 22px;
    background-color: #fff;
    cursor: pointer;

    @include mq(md) {
      display: none;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      width: 13px;
      height: 1px;
      background-color: $color-black;
      transform: translate(-50%, -50%);
      transform-origin: center;
    }

    &::before {
      top: calc(50%);
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      top: calc(50%);
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &__detail-list {
    @include mq(md) {
      position: relative;
      max-width: calc(650px + (116 / 1440 * 100vw));
      height: 100%;
      padding: (90 / 1440 * 100vw) (116 / 1440 * 100vw) 0 0;
    }
  }

  &__detail-item {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    overflow-y: scroll;
    width: 100vw;
    height: 100vh;
    padding: 40px 35px 60px;
    color: #fff;
    opacity: 0;
    transition: opacity 0.5s ease, visibility 0.5s ease;

    @include mq(md) {
      position: absolute;
      z-index: 0;
      overflow-y: hidden;
      width: 100%;
      max-width: calc(650px + (116 / 1440 * 100vw));
      height: 100%;
      padding: (90 / 1440 * 100vw) (116 / 1440 * 100vw) 65px 0;
      background: #fff;
      color: $color-black;
      transition: opacity 0.5s ease, visibility 0.5s ease, transform 0.5s ease;
      transform: translateX(-30px);
    }

    &::after {
      content: "";
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);

      @include mq(md) {
        display: none;
      }
    }

    &.is-show {
      visibility: visible;
      z-index: 2;
      opacity: 1;

      @include mq(md) {
        transform: translateX(0);
      }
    }

    @for $i from 1 through 8 {
      &:nth-child(#{$i}) {
        @include mq(sp-only) {
          background: url("/assets/img/page/top/service_list_0#{$i}_sp.jpg") no-repeat center center;
          background-size: cover;
        }
      }
    }
  }

  // モーダル
  &__detail-modal {
    position: relative;
    z-index: 2;
    padding-bottom: 120px;
  }

  &__detail-number {
    font-family: $font-ratin;
    font-size: 1.3rem;
    font-weight: 500;
    color: #e0e0e0;

    @include mq(md) {
      display: none;
    }
  }

  &__detail-category {
    padding-bottom: 15px;
    font-size: 1.7rem;
    font-weight: 500;
    border-bottom: 1px solid #fff;

    @include mq(md) {
      position: relative;
      padding-bottom: 0;
      padding-left: 80px;
      font-size: 1.6rem;
      font-weight: bold;
      border: none;
    }

    &::before {
      @include mq(md) {
        content: "";
        position: absolute;
        top: calc(0.5em + 4px);
        left: 0;
        width: 60px;
        height: 2px;
        background-color: $color-black;
      }
    }
  }

  &__detail-body {
    margin-top: 15px;
    font-size: 1.8rem;

    @include mq(md) {
      margin-top: 0;
      padding-left: 80px;
    }
  }

  &__detail-title {
    font-size: 1.8rem;
    font-weight: bold;

    @include mq(md) {
      margin-top: 10px;
      font-size: 3rem;
      line-height: (42/26);
    }
  }

  &__detail-leader {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 30px;
    padding: 13px 18px;

    @include mq(md) {
      margin-top: 30px;
      padding: 17px 27px;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 6px;
      height: calc(100% - 2px);
      border: 1px solid $color-gray-350;
    }

    &::before {
      bottom: 0;
      left: 0;
      border-right-width: 0;
    }

    &::after {
      top: 0;
      right: 0;
      border-left-width: 0;
    }
  }

  &__detail-picture {
    flex-shrink: 0;
    width: 70px;
    margin-right: 10px;

    @include mq(md) {
      width: 90px;
      margin-right: 20px;
    }
  }

  &__detail-person {
    line-height: 1.2;
  }

  &__detail-name {
    display: block;
    font-size: 1.3rem;
    font-weight: bold;

    @include mq(md) {
      font-size: 1.8rem;
    }
  }

  &__detail-job {
    letter-spacing: -0.05em;
    font-size: 1rem;
    font-weight: bold;

    @include mq(md) {
      display: block;
      margin-top: 7px;
      font-size: 1.3rem;
    }
  }

  &__detail-comment {
    margin-top: 20px;
    font-size: 1.2rem;
    line-height: (46/24);

    @include mq(md) {
      position: relative;
      padding: 20px;
      font-size: 1.4rem;
      color: $color-gray-700;

      &::before,
      &::after {
        @include mq(md) {
          content: "";
          display: block;
          position: absolute;
          width: 13px;
          height: 13px;
          border: 1px solid $color-gray-300;
        }
      }

      &::before {
        @include mq(md) {
          top: 0;
          right: 0;
          border-width: 1px 1px 0 0;
        }
      }

      &::after {
        @include mq(md) {
          right: 0;
          bottom: 0;
          border-width: 0 1px 1px 0;
        }
      }
    }
  }

  &__detail-link-button {
    margin-top: 65px;

    @include mq(md) {
      width: 368px;
      margin-top: 65px;
    }
  }

  // モーダル内ページャー
  &__detail-pagers {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    margin: 0 -35px;
    padding: 10px 0;
    background-color: rgba($color-black, 0.8);
  }

  &__detail-pager {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding: 0 10px 0 45px;
    text-align: left;
    font-size: 1rem;
    font-weight: bold;
    background-color: rgba(256, 256, 256, 0);
    color: #fff;

    &::before {
      content: attr(data-number);
      display: block;
      padding-bottom: 5px;
      font-family: $font-ratin;
      font-size: 1.1rem;
      font-weight: 500;
    }

    & + & {
      position: relative;
      padding: 0 45px 0 15px;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 1px;
        height: 27px;
        background-color: $color-gray-350;
        transform: translateY(-50%);
      }
    }
  }

  &__detail-pager-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 22px;
    height: 22px;
    transform: translateY(-50%);
    fill: #fff;

    .p-top-service__detail-pager--prev & {
      right: auto;
      left: 10px;
      transform: translateY(-50%) scale(-1, 1);
    }
  }
}

//以下 English Ver. 記述

.en {
  .p-top-service {
    &__button {
      font-family: $enBaseFontFamily;
      font-size: 1.2rem;
      line-height: 1.5;

      @include mq(md) {
        font-size: 1.6rem;
      }
    }

    &__item-title {
      font-family: $enBaseFontFamily;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.5;

      @include mq(md) {
        font-size: 2.2rem;
      }
    }

    &__item.is-current {
      .p-top-service__item-title {
        font-size: 3rem;
      }
    }

    &__detail-category {
      font-family: $enBaseFontFamily;
      font-size: 1.7rem;
      line-height: 1.5;

      @include mq(md) {
        font-size: 1.8rem;
      }
    }

    &__detail-title {
      font-family: $enBaseFontFamily;

      @include mq(md) {
        font-size: 3.2rem;
        line-height: 1.5;
      }
    }

    &__detail-name {
      font-family: $enBaseFontFamily;

      @include mq(md) {
        font-size: 2rem;
        line-height: 1.5;
      }
    }

    &__detail-job {
      font-family: $enBaseFontFamily;

      @include mq(md) {
        font-size: 1.5rem;
        line-height: 1.5;
      }
    }
  }
}


