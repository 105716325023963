.c-list-company {
  font-size: 1.2rem;

  @include mq(md) {
    font-size: 1.5rem;
  }

  &__row {
    display: flex;
    align-items: flex-start;
    padding: 25px 0;
    letter-spacing: 0.05em;
    border-bottom: 1px solid $color-gray-300;

    @include mq(md) {
      padding: 38px 0;
    }

    &:nth-child(1) {
      padding-top: 0;
    }
  }

  &__title {
    flex-shrink: 0;
    width: 70px;
    margin-right: 1em;
    color: $color-gray-700;

    @include mq(md) {
      width: 150px;
    }
  }

  &__buttons {
    display: flex;
    margin: 10px -3px 0;

    @include mq(md) {
      float: right;
      margin: 0 0 0 20px;
    }
  }

  &__button {
    padding: 0 3px;
  }
}

//以下 English Ver. 記述

.en {
  .c-list-company {
    line-height: 1.5;

    @include mq(md) {
      font-size: 1.7rem;
    }

    &__text {
      @include mq(sp-only) {
        font-size: 1.4rem;
      }
    }
  }
}
