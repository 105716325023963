.p-about-cards {
  margin-top: 100px;

  @include mq(md) {
    margin-top: 150px;
  }

  &__list {
    @include mq(md) {
      display: flex;
      padding: 45px 60px;
      border: 1px solid $color-gray-100;
    }
  }

  &__item {
    position: relative;
    padding: 25px;
    border: 1px solid $color-gray-100;

    @include mq(md) {
      padding: 0;
      border: none;
    }

    & + & {
      margin-top: 80px;
      padding-top: 25px;
      border-top: 1px solid $color-gray-100;

      @include mq(md) {
        margin: 0 0 0 60px;
        padding: 0 0 0 60px;
        border-top: none;
        border-left: 1px solid $color-gray-100;
      }
    }
  }

  &__title {
    font-family: $font-ratin;
    font-size: 1.2rem;
    font-weight: 500;
    color: $color-gray;

    @include mq(md) {
      font-size: 1.7rem;
    }
  }

  &__headline {
    display: block;
    position: relative;
    margin-top: 5px;
    padding-bottom: 15px;
    font-size: 1.6rem;
    font-weight: bold;
    border-bottom: 1px solid $color-gray-300;
    color: $color-primary;

    @include mq(md) {
      margin-top: 0;
      font-size: 2.4rem;
    }
  }

  &__image {
    position: absolute;
    right: 10px;
    bottom: 0;
    width: 122px;

    @include mq(md) {
      width: 177px;
    }

    &--creed {
      width: 150px;

      @include mq(md) {
        width: 217px;
      }
    }
  }

  &__text {
    margin-top: 20px;
    font-size: 1.2rem;

    @include mq(md) {
      margin-top: 30px;
      font-size: 1.5rem;
      line-height: (28/15);
    }
  }

  &__link {
    margin-top: 20px;
    text-align: right;

    @include mq(md) {
      margin-top: 40px;
    }
  }
}

//以下 English Ver. 記述

.en {
  .p-about-cards {
    &__headline {
      font-family: $enHeadingFont;
      line-height: 1.6;
    }
    &__text {
      font-size: 1.4rem;
      line-height: 1.8;
      @include mq(md) {
        font-size: 1.6rem;
      }
    }
    &__list {
      @include mq(sp-only) {
        .p-about-cards__item:first-child {
          .p-about-cards__image {
            right: -9px;
            width: 110px;
          }
        }
        .p-about-cards__item:nth-child(2) {
          .p-about-cards__image {
            right: -5px;
            width: 139px;
          }
        }

      }
    }
  }
}
