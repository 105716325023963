.p-message {
  margin-top: 50px;
  padding: 0 9.6%;

  @include mq(md) {
    margin: 100px auto 0;
    padding: 0 44px;
  }

  &__headline {
    font-size: 2.3rem;
    font-weight: bold;
    line-height: (70/46);

    @include mq(md) {
      font-size: 4rem;
      line-height: (66/40);
    }
  }

  &__inner {
    @include mq(md) {
      display: flex;
    }
  }

  &__body {
    margin-top: 40px;
  }

  &__text {
    margin-top: 20px;
    letter-spacing: 0.01em;
    font-size: 1.4rem;
    line-height: (52/28);

    @include mq(md) {
      margin-top: 30px;
      font-size: 1.8rem;
      line-height: (38/18);
    }

    & + .p-message__text--mt-l {
      margin-top: 40px;

      @include mq(md) {
        margin-top: 60px;
      }
    }
  }

  &__title {
    margin-top: 50px;
    font-family: 2rem;
    font-weight: bold;

    @include mq(md) {
      margin-top: 70px;
      font-size: 2.3rem;
    }
  }

  &__list {
    margin: 30px 0 -15px;
    font-size: 1.4rem;

    @include mq(md) {
      font-size: 1.8rem;
    }
  }

  &__list-item {
    position: relative;
    padding-bottom: 15px;
    padding-left: 17px;
    font-weight: bold;
    line-height: (40/28);

    &::before {
      content: "・";
      position: absolute;
      left: 0;
    }
  }

  &__foot {
    margin-top: 40px;
    text-align: right;

    @include mq(md) {
      margin-top: 80px;
    }
  }

  &__ceo {
    margin-top: 75px;

    @include mq(md) {
      flex-shrink: 0;
      width: 330px;
      margin-top: -60px;
      margin-left: (152/1100 * 100%);
    }
  }

  &__ceo-name {
    position: relative;
    padding: 80px 50px 30px;
    font-size: 1.9rem;
    font-weight: bold;
    background: url("/assets/img/page/about/ceo_image.png") no-repeat top left;
    background-size: 284px;

    @include mq(md) {
      padding: 130px 30px 40px;
      font-size: 2rem;
      background: url("/assets/img/page/about/ceo_image_pc.png") no-repeat top
        left;
      background-size: 315px;
    }

    &--title {
      display: block;
      font-size: 1.2rem;
      font-weight: normal;
      color: $color-gray-700;

      @include mq(md) {
        font-size: 1.3rem;
      }
    }
  }

  &__bio-wrap {
    padding: 35px 20px 30px;
    border: 1px solid $color-gray-300;

    @include mq(md) {
      padding: 30px 25px;
    }
  }

  &__bio-links {
    display: flex;
    margin: 0 -3px;
  }

  &__bio-link {
    padding: 0 3px;
  }

  &__bio {
    margin: 25px 0 -9px;
    font-size: 1.2rem;

    @include mq(md) {
      margin-bottom: -15px;
      font-size: 1.5rem;
    }
  }

  &__bio-item {
    display: flex;
    padding-bottom: 9px;

    @include mq(md) {
      padding-bottom: 15px;
    }
  }

  &__bio-year {
    padding-right: 20px;
    color: $color-gray-700;

    @include mq(md) {
      padding-right: 30px;
    }

    &--till {
      display: block;
      position: relative;
      padding-top: 7px;

      &::before {
        content: "";
        position: absolute;
        top: 3px;
        left: 10px;
        width: 1px;
        height: 4px;
        background-color: $color-gray-700;
      }
    }
  }

  &__pdfs {
    margin-top: 40px;
    padding: 30px 20px;
    border: 1px solid $color-gray-300;

    @include mq(md) {
      padding: 30px 27px;
    }
  }

  &__pdfs-text {
    font-size: 1.2rem;
    font-weight: 500;

    @include mq(md) {
      line-height: (23/12);
    }
  }

  &__button {
    display: block;
    margin-top: 20px;
    padding: 20px 15px;
    text-align: center;
    //font-size: 1.3rem;
    font-size: 12px;
    font-weight: bold;
    background-color: $color-secondary;

    &--blue {
      background-color: $color-primary;
      color: #fff;
    }
  }

  &__button-title {
    display: block;
    //font-size: 1.8rem;
    font-size: 17px;
    font-weight: 900;
  }
}

//以下 English Ver. 記述

.en {
  .p-message {
    &__headline {
      font-size: 2.5rem;
      font-family: $enHeadingFont;
      line-height: 1.5;
      @include mq(md) {
        font-size: 4.2rem;
      }
    }
    &__text {
      letter-spacing: 0.016em;
      font-size: 1.6rem;
      line-height: 1.8;

      @include mq(md) {
        font-size: 2rem;
      }
    }
    &__ceo-name {
      font-size: 2rem;
      padding: 72px 2px 30px;

      @include mq(md) {
        padding: 130px 2px 40px;
      }

      &--title {
        font-size: 1.5rem;
      }
    }
    &__bio {
      font-size: 1.5rem;
      line-height: 1.5;
    }
    &__pdfs-text {
      font-size: 1.2rem;
      line-height: 1.5;
    }
    &__button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 1.2rem;
      line-height: 1.5;
      min-height: 86px;
      padding: 0 15px;
    }
    &__button-title {
      font-size: 1.7rem;
      line-height: 1.5;
    }
  }
}
