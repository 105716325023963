// Display
// ex) Class Name: .u-dsp-block, .u-dsp-none
// --------------------------------------------------

.u-dsp- {
  &block {
    display: block;
  }

  &none {
    display: none;
  }

  &inline {
    display: inline;
  }

  &ib {
    display: inline-block;
  }
}

// SPで表示させない
.u-no-sp {
  display: none;

  @include mq(md) {
    display: block;
  }
}

// SPでのみ表示させる
.u-sp-view {
  display: block;

  @include mq(md) {
    display: none;
  }
}
