.c-button {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 17px 20px;
  font-family: $font-ratin, $font-jp;
  font-size: 1.3rem;
  font-weight: bold;
  background-color: $color-secondary;
  color: $color-black;

  @include mq(md) {
    padding: 20px 60px 20px 20px;
    font-size: 1.6rem;
    font-weight: 500;
  }

  // COLOR
  &--black {
    background-color: $color-black;
    color: #fff;
  }

  &--transparent {
    border: 1px solid #fff;
    background-color: rgba(256, 256, 256, 0);
    color: #fff;
  }

  // SIZE
  &--small {
    padding: 14px 20px;
    letter-spacing: 0.01em;
    font-size: 1.1rem;
    font-weight: 500;
  }

  &--pc-m {
    @include mq(md) {
      padding: 26px 80px 26px 26px;
    }
  }

  &--pc-l {
    @include mq(md) {
      padding: 30px 80px 30px 30px;
    }
  }

  &--fw-m {
    font-weight: 500;
  }

  // SHAPE
  &--round {
    max-width: 343px;
    padding: 17px 0;
    font-family: $font-jp;
    font-size: 1.6rem;
    font-weight: 900;
    line-height: 35.2px;
    border: 1px solid $color-black;
    border-radius: 50px;
    transition: background-color 0.2s ease, color 0.2s ease;

    @include mq(md) {
      max-width: 566px;
      padding: 21px 179px 22px 187px;
      font-size: 2.6rem;
      line-height: 57.2px;
    }

    @include mq(xxl) {
      max-width: 566px;
      padding: 18px 175px;
      font-size: 2.8rem;
      line-height: 61.2px;
    }

    &:hover {
      border: 1px solid $color-black;
      background-color: transparent;
      color: $color-black;
    }

    .c-button__icon {
      display: none;
    }
  }

  // z-index
  &--z3 {
    z-index: 3;
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 20px;
    width: 30px;
    height: 30px;
    transform: translateY(-50%);

    @include mq(md) {
      right: 20px;
      width: 35px;
      height: 35px;
    }

    .c-button:hover & {
      @include mq(md) {
        animation: arrow-right 0.5s ease forwards;
      }
    }

    .c-button--black &,
    .c-button--transparent & {
      fill: #fff;
    }

    .c-button--pc-l & {
      @include mq(md) {
        right: 30px;
      }
    }
  }
}

//以下 English Ver. 記述

.en {
  .c-button {
    font-family: $enBaseFontFamily;
    font-size: 1.5rem;
    @include mq(md) {
      font-size: 1.8rem;
    }

    &--round {
      padding: 17px 0;
    }
  }

  .p-top-cv-button__block {
    .c-button {
      font-family: $enBaseFontFamily;
      font-size: 1.8rem;
      line-height: 1.5;
      font-weight: 700;

      @include mq(md) {
        font-size: 3rem;
      }

      &--round {
        padding: 21px 0;
      }
    }
  }
}
