.c-list-work {
  margin-top: 20px;

  @include mq(md) {
    display: flex;
    flex-wrap: wrap;
    margin: 40px -46px -70px;
  }

  &__item {
    @include mq(md) {
      width: 33.333%;
      padding: 0 46px 70px;
    }

    & + & {
      margin-top: 44px;

      @include mq(md) {
        margin-top: 0;
      }
    }

    &.is-hidden {
      display: none;
    }
  }

  &__link {
    @include mq(md) {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &__picture {
    position: relative;
    padding-bottom: (376/336 * 100%);

    &::before {
      @include mq(md) {
        content: "";
        visibility: hidden;
        position: absolute;
        top: -10%;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("/assets/img/share/tamkun_icon_01.svg") no-repeat center
          center;
        background-size: contain;
        opacity: 0;
        transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
      }
    }

    &:hover {
      &::before {
        @include mq(md) {
          visibility: visible;
          opacity: 1;
          transform: scale(1.08);
          transform-origin: center;
        }
      }
    }
  }

  &__image {
    display: block;
    position: absolute;

    @include mq(md) {
      transition: all 0.3s ease;
    }

    .c-list-work__picture:hover & {
      @include mq(md) {
        transform: scale(0.87);
        -moz-transform-origin: center;
      }
    }
  }

  &__body {
    margin-top: 10px;

    @include mq(md) {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &__client {
    font-size: 1.2rem;
    color: $color-primary;

    @include mq(md) {
      font-size: 1.4rem;
    }
  }

  &__title {
    margin-top: 10px;
    font-size: 1.5rem;
    font-weight: 500;

    @include mq(md) {
      margin-bottom: 10px;
      font-size: 2rem;
    }
  }

  &__category {
    margin-top: 10px;
    font-size: 1.2rem;
    color: $color-gray-700;

    @include mq(md) {
      margin-top: auto;
      font-size: 1.4rem;
    }
  }
}

//以下 English Ver. 記述

.en {
  .c-list-work {
    &__client {
      font-size: 1.4rem;
      line-height: 1.5;

      @include mq(md) {
        font-size: 1.6rem;
      }
    }
    &__title {
      font-size: 1.8rem;
      line-height: 1.5;

      @include mq(md) {
        font-size: 2.2rem;
      }
    }
    &__category {
      font-size: 1.4rem;
      line-height: 1.5;

      @include mq(md) {
        font-size: 1.6rem;
      }
    }
  }
}
