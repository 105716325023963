@import "~reset-css";
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400,500,700,900&display=swap&subset=japanese");
@import url("https://fonts.googleapis.com/css?family=Barlow+Semi+Condensed:300,400,500,600,700,900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap");

* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  font-family: $baseFontFamily;
  font-size: $baseFontSize;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
}

body {
  width: 100%;
  height: 100%;
  min-width: 320px;
  letter-spacing: 0.05em;
  font-size: 1.6rem;
  line-height: 1.6;
  background-color: #fff;
  color: $color-black;
  font-kerning: normal;
  -webkit-tap-highlight-color: rgba(#000, 0);

  @include mq(md) {
    min-width: 1024px;
  }

  &.is-menu-open {
    overflow: hidden;
  }

  &.no-scroll {
    overflow: hidden; // ここでスクロールを禁止にする
  }
}

a {
  text-decoration: none;
  color: inherit;

  &:visited,
  &:active {
    text-decoration: none;
  }
}

img {
  width: 100%;
  height: auto;
}

button {
  border: none;

  &:focus {
    outline: none;
  }
}

input,
select,
textarea {
  border: none;
  border-radius: 0;
  background-color: rgba(256, 256, 256, 0);
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    outline: none;
  }
}

//- for ie11
select::-ms-expand {
  display: none;
}

.icon {
  width: 100%;
  height: 1.6rem;
}

// ロード時のVueテンプレートを非表示にする
[v-cloak] {
  display: none;
}

//英語版

html.en {
  font-family: $enBaseFontFamily;
}

.en {
  body {
    letter-spacing: $enBaseLetterSpacing;
  }
}
