.p-work-iframe {
  width: 100%;

  @include mq(md) {
    display: flex;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
  }

  &--display {
    display: none;

    @include mq(md) {
      display: block;
    }
  }

  iframe {
    width: 100%;
    min-height: 600px;

    @include mq(md) {
      width: 320px;
      min-height: 500px;
      margin-right: 1rem;
    }
  }
}

.p-work-iframe__slide {
  position: relative;
  overflow: hidden;
  max-width: 1000px;
  height: 0;
  margin: 0 auto;
  padding-top: 56.25%;
}

.p-work-iframe__slide iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
