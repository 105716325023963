.p-error {
  @include mq(md) {
    display: flex;
    margin-top: 50px;
  }

  &__image {
    margin-top: 30px;
    text-align: center;

    @include mq(md) {
      flex-shrink: 0;
      margin: 0 80px 0 0;
    }
  }

  &__image-svg {
    width: 136px;
    height: 237px;

    @include mq(md) {
      width: 218px;
      height: 382px;
    }
  }

  &__body {
    margin-top: 30px;

    @include mq(md) {
      margin-top: 50px;
    }
  }
}
