.p-top-stories {
  margin-top: 75px;

  @include mq(md) {
    margin-top: 160px;
  }

  &__title {
    font-family: $font-ratin;
    font-size: 3.5rem;
    font-weight: bold;

    @include mq(md) {
      font-size: 6rem;
    }
  }

  &__title-yellow {
    color: $color-secondary;
  }

  &__title-blue {
    color: $color-primary;
  }

  &__lead {
    margin-top: 15px;

    @include mq(md) {
      display: flex;
      align-items: center;
      padding: 20px 24px;
      border: 1px solid $color-gray-300;
    }
  }

  &__lead-title {
    display: inline-block;
    position: relative;
    padding-right: 40px;
    font-size: 1.5rem;
    font-weight: bold;

    @include mq(md) {
      flex-shrink: 0;
      margin-right: 20px;
      padding-right: 70px;
      font-size: 2rem;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      width: 30px;
      height: 1px;
      background-color: $color-black;
      transform: translateY(-50%);

      @include mq(md) {
        width: 60px;
        height: 2px;
      }
    }
  }

  &__lead-text {
    margin-top: 10px;
    letter-spacing: 0.01em;
    font-size: 1.2rem;
    font-weight: bold;

    @include mq(md) {
      margin-top: 0;
      font-size: 1.6rem;
    }
  }

  &__list-wrap {
    position: relative;
    margin: 30px 0 0;
    padding: 0 (10 / 375 * 100%);

    @include mq(md) {
      margin: 40px 0 0;
      padding: 0 20px;
    }

    @include mq(pc-content) {
      padding: 0 calc((100vw - 1200px) / 2);
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -25px;
    padding-bottom: 17px;

    @include mq(md) {
      flex-wrap: nowrap;
      margin-bottom: 0;
      padding: 0 0 50px 0;
    }
  }

  &__list-item {
    flex-shrink: 0;
    position: relative;
    width: 50%;
    padding: 0 5px 25px;

    @include mq(md) {
      width: 270px;
      padding: 0;
    }
  }

  &__list-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
    width: 26px;
    height: 26px;
    border-radius: 13px;
    background: $color-secondary url("/assets/img/share/stories_icon_01.svg") no-repeat center
      center;
    background-size: 46%;

    &.press-report,
    &.press-event {
      background: $color-secondary url("/assets/img/share/stories_icon_02.svg") no-repeat center
        center;
      background-size: 50%;
    }

    &.fb-report,
    &.fb-event-dojo,
    &.fb-event-pwa,
    &.fb-seminar {
      background: $color-secondary url("/assets/img/share/stories_icon_03.svg") no-repeat center
        center;
      background-size: 28%;
    }
  }

  &__list-link {
    display: block;
  }

  &__list-figure {
    position: relative;
    padding-bottom: (177 / 266 * 100%);
  }

  &__list-image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s ease;

    &--border {
      border: 1px solid $color-gray-100;
    }

    .p-top-stories__list-figure:hover & {
      @include mq(md) {
        transform: scale(0.9);
        transform-origin: center;
      }
    }
  }
  &__list-date {
    display: block;
    margin-top: 10px;
    font-family: $font-ratin;
    font-size: 1.3rem;
    font-weight: 500;
    color: $color-gray;
    padding-left: 20px;

    @include mq(md) {
      margin-top: 12px;
      font-size: 1.4rem;
    }
  }

  &__list-category {
    position: relative;
    margin-top: 5px;
    padding-left: 20px;
    font-size: 1.3rem;
    font-weight: 500;
    color: $color-primary;

    @include mq(md) {
      font-size: 1.4rem;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 10.5px;
      left: 0;
      width: 12px;
      height: 1px;
      background-color: $color-primary;

      @include mq(md) {
        top: 11.5px;
        height: 2px;
      }
    }

  }

  &__list-abstract {
    margin-top: 10px;
    padding-left: 20px;
    font-size: 1.2rem;
    line-height: (42/24);

    @include mq(md) {
      font-size: 1.3rem;
    }
  }

  &__link-button {
    margin-top: 20px;

    @include mq(md) {
      max-width: 564px;
      margin: 80px auto 0;
    }
  }
}

//以下 English Ver. 記述
.en {
  .p-top-stories {
    &__lead-title {
      font-family: $enBaseFontFamily;
      font-size: 1.7rem;
      line-height: 1.5;

      @include mq(md) {
        font-size: 2rem;
      }
    }

    &__lead-text {
      font-family: $enBaseFontFamily;
      font-size: 1.4rem;
      line-height: 1.5;

      @include mq(md) {
        font-size: 1.6rem;
      }
    }
  }
}

