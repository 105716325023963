.c-headline {
  font-size: 2.3rem;
  font-weight: 900;

  @include mq(md) {
    font-size: 3.6rem;
    line-height: (56/36);
  }

  &--about {
    font-weight: bold;

    @include mq(md) {
      font-size: 4rem;
    }
  }

  // margin-top
  & + .c-text {
    margin-top: 36px;
  }
}

//以下 English Ver. 記述

.en {
  .c-headline {
    &--work {
      font-family: $enHeadingFont;
      font-size: 2.5rem;
      line-height: 1.5;
      font-weight: 700;

      @include mq(md) {
        font-size: 3.8rem;
      }
    }

    &--about {
      font-family: $enHeadingFont;
      font-size: 2.5rem;
      line-height: 1.5;

      @include mq(md) {
        font-size: 4.2rem;
      }
    }
  }
}
