.c-section-service {
  margin-top: 60px;
  margin-bottom: 60px;

  &__figure {
    @include mq(md) {
      margin-top: 80px;
      margin-right: -120px;
      padding-left: 40px;
    }
  }

  &__headline {
    margin-top: -40px;
    padding: 0 (36 / 375 * 100%);
    font-family: $font-ratin;
    font-size: 6.5rem;
    font-weight: bold;
    line-height: (125/130);
    color: $color-secondary-200;
    -webkit-text-fill-color: rgba(256, 256, 256, 0);
    -webkit-text-stroke-color: $color-secondary-200;
    -webkit-text-stroke-width: 1px;

    @include mq(md) {
      margin-top: -100px;
      padding: 0 0 0 31px;
      font-size: 17.7rem;
    }
  }

  &__inner {
    margin-top: 35px;
    padding: 0 (36 / 375 * 100%);

    @include mq(md) {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 140px;
      padding: 0 40px;
    }
  }

  &__leader {
    position: relative;
    padding: 20px 25px 10px 25px;

    @include mq(md) {
      flex-shrink: 0;
      width: 180px;
      margin-right: 135px;
      padding: 0;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 5px;
      height: 100%;
      border: 1px solid $color-gray-350;

      @include mq(md) {
        border: none;
      }
    }

    &::before {
      left: 0;
      border-width: 1px 0 1px 1px;
    }

    &::after {
      right: 0;
      border-width: 1px 1px 1px 0;
    }
  }

  &__leader-picture {
    flex-shrink: 0;
    width: 90px;
    margin-right: 20px;

    @include mq(md) {
      width: 160px;
      margin-right: 0;
    }
  }

  &__leader-inner {
    display: grid;
    line-height: (42/24);
    grid-template-columns: 110px auto;
    grid-template-rows: 30px minmax(60px, auto) auto;

    @include mq(md) {
      display: flex;
      flex-direction: column;
    }
  }

  &__leader-name {
    font-size: 1.5rem;
    font-weight: bold;

    @include mq(md) {
      margin-top: 25px;
      font-size: 1.8rem;
    }
  }

  &__leader-job {
    letter-spacing: -0.05em;
    font-size: 1.2rem;
    grid-column: 2/3;
    -ms-text-kashida-space: (41/24);

    @include mq(md) {
      order: 3;
      margin-top: 10px;
      line-height: (24/12);
    }
  }

  &__leader-bio {
    margin-top: 10px;
    letter-spacing: -0.01em;
    font-size: 1.2rem;
    grid-column: 1/3;

    @include mq(md) {
      order: 2;
    }
  }

  &__leader-links {
    display: inline-block;
    vertical-align: middle;
    margin-top: 10px;

    @include mq(md) {
      margin-top: 20px;
    }
  }

  &__leader-link {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 30px;
    height: 30px;
    border: 1px solid $color-black;
    border-radius: 15px;

    @include mq(md) {
      width: 36px;
      height: 36px;
      border-radius: 18px;
    }

    & + & {
      margin-left: 8px;
    }
  }

  &__leader-sub-link {
    display: inline-block;
    font-size: 1.2rem;
    line-height: (42/24);

    &::after {
      content: "";
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-left: 5px;
      background: url("/assets/img/share/link_blank.svg") no-repeat center
        center;
    }
  }

  &__leader-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 13px;
    height: 13px;
    transform: translate(-50%, -50%);
    fill: $color-black;

    @include mq(md) {
      width: 15px;
      height: 15px;
    }
  }

  &__leader-button-wrap {
    margin-top: 20px;
  }

  &__leader-button {
    display: block;
    width: 100%;
    height: 44px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 44px;
    background-color: $color-black;
    color: #fff;

    @include mq(md) {
      width: 180px;
      height: 60px;
      font-size: 1.4rem;
      line-height: 60px;
    }
  }

  &__contents {
    margin-top: 75px;

    @include mq(md) {
      width: calc(100% - 315px);
      margin-top: 0;
    }

    & > .c-section-service__section:nth-child(1) {
      @include mq(md) {
        margin-top: 0;
      }
    }
  }

  &__section {
    margin-top: 70px;

    @include mq(md) {
      margin-top: 150px;
    }
  }

  &__title {
    font-size: 2.5rem;
    font-weight: bold;
    line-height: (70/50);

    @include mq(md) {
      font-size: 4rem;
      line-height: (6/4);
    }

    &--m {
      margin-top: 30px;
      letter-spacing: -0.01em;
      font-size: 1.9rem;
      line-height: (58/38);

      @include mq(md) {
        margin-top: 50px;
        font-size: 2.2rem;
      }
    }

    &--s {
      margin-top: 25px;
      font-size: 1.4rem;
      font-weight: normal;
      color: $color-primary;

      @include mq(md) {
        margin-top: 40px;
        font-size: 1.8rem;
      }

      // margin-top
      & + .c-section-service__text {
        margin-top: 10px;

        @include mq(md) {
          margin-top: 20px;
        }
      }
    }
  }

  &__text {
    margin-top: 30px;
    letter-spacing: -0.01em;
    font-size: 1.4rem;
    line-height: (52/28);

    @include mq(md) {
      margin-top: 60px;
      letter-spacing: 0.05em;
      font-size: 1.8rem;
      line-height: (38/18);
    }

    & + & {
      margin-top: 20px;

      @include mq(md) {
        margin-top: 2em;
      }
    }

    // margin-top
    & + .c-section-service__title--s {
      margin-top: 30px;

      @include mq(md) {
        margin-top: 60px;
      }
    }
  }

  &__section-figure {
    width: 100%;
    margin-top: 40px;

    @include mq(md) {
      margin-top: 80px;
    }
  }

  &__section-figcaption {
    margin-top: 7px;
    font-size: 1.1rem;
    font-weight: bold;

    @include mq(md) {
      margin-top: 20px;
      font-size: 1.6rem;
    }
  }

  // バナー
  &__banners {
    margin-top: 50px;

    @include mq(md) {
      display: flex;
      flex-wrap: wrap;
      margin: 100px -13px -26px;
    }
  }

  &__banner {
    @include mq(md) {
      width: 50%;
      padding: 0 13px 26px;
    }

    & + & {
      margin-top: 16px;

      @include mq(md) {
        margin-top: 0;
      }
    }
  }

  &__banner-link {
    display: block;
    overflow: hidden;
    padding: 15px;
    letter-spacing: -0.01em;
    border: 1px solid $color-gray-300;

    @include mq(md) {
      height: 100%;
    }
  }

  &__banner-image {
    float: right;
    width: 90px;
    margin-left: 10px;

    @include mq(md) {
      width: 120px;
    }
  }

  &__banner-title {
    font-size: 1.1rem;
    font-weight: bold;
    color: $color-primary;

    @include mq(md) {
      font-size: 1.2rem;
    }
  }

  &__banner-text {
    overflow: hidden;
    margin-top: 5px;
    font-size: 1.2rem;

    @include mq(md) {
      font-size: 1.3rem;
    }
  }
}

//以下 English Ver. 記述

.en {
  .c-section-service {
    &__leader-name {
      font-size: 1.4rem;
      line-height: 1.5;

      @include mq(md) {
        font-size: 1.8rem;
      }
    }
    &__leader-button {
      font-size: 1.4rem;
    }

    &__leader-bio,
    &__leader-job {
      font-size: 1.4rem;
      line-height: 1.5;
      letter-spacing: $enBaseLetterSpacing;

      @include mq(md) {
        font-size: 1.2rem;
      }
    }
    &__leader-sub-link {
      font-size: 1.4rem;
      line-height: 1.5;
      letter-spacing: $enBaseLetterSpacing;
      @include mq(md) {
        font-size: 1.2rem;
      }
    }
    &__title {
      font-family: $enHeadingFont;
      font-size: 2.7rem;
      font-weight: bold;
      line-height: 1.3;

      @include mq(md) {
        font-size: 4.2rem;
      }

      &--m {
        font-family: $enBaseFontFamily;
        font-size: 2.1rem;
        line-height: 1.5;
        letter-spacing: 0.0152em;

        @include mq(md) {
          font-size: 2.2rem;
        }
      }

      &--s {
        font-family: $enBaseFontFamily;
        font-size: 1.6rem;
        line-height: 1.5;
        font-weight: 400;

        @include mq(md) {
          font-size: 1.8rem;
        }
      }
    }
    &__text {
      font-size: 1.6rem;
      line-height: 1.8;
      letter-spacing: $enBaseLetterSpacing;

      @include mq(md) {
        font-size: 1.8rem;
      }
    }
    &__banner-title {
      font-size: 1.2rem;
      line-height: 1.2;

      @include mq(md) {
        font-size: 1.2rem;
      }
    }
    &__banner-text {
      font-size: 1.3rem;
      line-height: 1.3;

      @include mq(md) {
        font-size: 1.3rem;
      }
    }
  }
}
