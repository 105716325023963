.c-form {
  &__item {
    position: relative;
    border-top: 1px solid $color-primary-200;
    transition: background-color 0.3s ease;

    .c-form__item-twin & {
      @include mq(md) {
        width: 50%;
      }

      & + .c-form__item {
        &::before {
          @include mq(md) {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 2;
            width: 1px;
            height: 15px;
            background-color: rgba($color-primary-200, 0.5);
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  &__item-twin {
    @include mq(md) {
      display: flex;
    }
  }

  &__item-textarea {
    position: relative;
    border-top: 1px solid $color-primary-200;
  }

  &__item-select {
    position: relative;
    border-top: 1px solid $color-primary-200;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 10px;
      z-index: 1;
      width: 10px;
      height: 20px;
      background: url("/assets/img/share/select_icon.svg") no-repeat center
        center;
      transform: translateY(-50%);
    }
  }

  &__label {
    position: absolute;
    top: 20px;
    left: 10px;
    z-index: 0;
    font-size: 1.2rem;
    transition: all 0.1s ease;

    @include mq(md) {
      font-size: 1.4rem;
    }

    // for chrome
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      .c-form__input:-webkit-autofill + & {
        top: 10px;
        z-index: 2;
        font-size: 1rem;
        color: $color-primary-200;
      }
    }

    .c-form__input:focus + &,
    .c-form__input.is-focus + & {
      top: 10px;
      z-index: 2;
      font-size: 1rem;
      color: $color-primary-200;
    }
  }

  &__input,
  &__textarea {
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 22px 10px;
    font-family: $font-jp;
    font-size: 1.2rem;
    color: #fff;
    caret-color: #fff;

    &:focus {
      z-index: 0;
      background-color: #3378b3;
    }

    .is-invalid & {
      padding-bottom: 50px;
    }

    // for chrome
    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px $color-primary inset;
      -webkit-text-fill-color: #fff;

      &:focus {
        box-shadow: 0 0 0 1000px #3378b3 inset;
      }
    }
  }

  &__input {
    transition: all 0.1s ease;

    &:focus,
    &.is-focus {
      padding-top: 30px;
    }

    .c-form__item-twin .is-invalid + .c-form__item & {
      @include mq(md) {
        padding-bottom: 50px;
      }
    }
  }

  &__textarea {
    padding-top: 50px;
    border-bottom: 1px solid $color-primary-200;
    caret-color: currentColor;
  }

  &__select {
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 22px 10px;
    font-family: $font-jp;
    font-size: 1.2rem;
    color: #fff;

    @include mq(md) {
      font-size: 1.4rem;
    }

    // for firefox
    option {
      background-color: $color-primary;
    }
  }

  // Validation
  &__error {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 2;
    padding: 5px 10px;
    font-size: 1.1rem;
    font-weight: 500;
    background-color: $color-gray-200;
    color: #f00;

    .c-form__item-textarea.is-invalid & {
      bottom: 15px;
    }
  }

  &__note {
    position: relative;
    margin-top: 10px;
    padding-left: 1.5em;
    font-size: 1.1rem;

    @include mq(md) {
      margin-top: 20px;
      font-size: 1.2rem;
    }

    &::before {
      content: "※";
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__privacy {
    margin-top: 10px;

    @include mq(md) {
      margin-top: 20px;
    }
  }

  &__privacy-check {
    display: none;
  }

  &__privacy-checkbox {
    display: block;
    flex-shrink: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    border: 1px solid #fff;
    border-radius: 9px;
    background-color: $color-primary;

    @include mq(md) {
      width: 23px;
      height: 23px;
      border-radius: 12px;
    }

    &::before {
      content: "";
      display: none;
      position: absolute;
      top: 4px;
      left: 4px;
      width: 7px;
      height: 3px;
      border: 2px solid #fff;
      border-width: 2px 2px 0 0;
      transform: rotate(140deg);
      transform-origin: center;

      @include mq(md) {
        top: 6px;
        left: 5px;
        width: 9px;
        height: 4px;
      }
    }

    .c-form__privacy-check:checked + .c-form__privacy-label > & {
      &::before {
        display: block;
      }
    }
  }

  &__privacy-label {
    display: block;
    position: relative;
    padding-left: 30px;
    font-size: 1.1rem;
    line-height: 18px;
    cursor: pointer;

    @include mq(md) {
      font-size: 1.2rem;
      line-height: 23px;
    }
  }

  &__textlink {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__submit-button {
    margin-top: 30px;

    @include mq(md) {
      max-width: 286px;
      margin: 60px auto 0;
    }

    &--left {
      @include mq(md) {
        margin: 60px auto 0 0;
      }
    }
  }

  &__submit {
    width: 100%;
    padding: 16px;
    font-family: $font-jp;
    font-size: 1.3rem;
    font-weight: 500;
    background-color: $color-secondary;
    cursor: pointer;

    @include mq(md) {
      font-size: 1.6rem;
    }

    &:disabled {
      background-color: $color-gray;
      color: #777;
      cursor: default;
    }
  }

  &__submitted {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__submitted-group {
    position: relative;
    height: 100%;
    margin-top: 40px;
    text-align: center;

    @include mq(md) {
      margin-top: 0;
      text-align: left;
    }
  }

  &__submitted-title {
    font-size: 2rem;
    font-weight: bold;

    @include mq(md) {
      margin-top: 8px;
      font-size: 2.6rem;
    }
  }

  &__submitted-text {
    margin-top: 10px;
    font-size: 1.4rem;

    @include mq(md) {
      margin-top: 20px;
      font-size: 1.8rem;
    }
  }

  &__submitted-image {
    width: 90px;
    margin-top: 20px;

    @include mq(md) {
      position: absolute;
      right: 0;
      bottom: -150px;
      width: 160px;
      margin-top: 0;
    }
  }
}

// axios transition
.fadeform-enter-active,
.fadeform-leave-active {
  transition: opacity 0.5s;
}

.fadeform-enter,
.fadeform-leave-to {
  height: 0;
  opacity: 0;
}

.fademessage-enter-active,
.fademessage-leave-active {
  transition: opacity 0.5s 0.5s, height 0.5s;
}

.fademessage-enter,
.fademessage-leave-to {
  height: 0;
  opacity: 0;
}

//以下 English Ver. 記述

.en {
  .c-form {
    &__select {
      font-family: $enBaseFontFamily;
      font-size: 1.4rem;
      line-height: 1.5;

      @include mq(md) {
        font-size: 1.6rem;
      }
    }
    &__label {
      font-size: 1.4rem;
      line-height: 1.5;

      @include mq(md) {
        font-size: 1.6rem;
      }

      // for chrome
      @media screen and (-webkit-min-device-pixel-ratio: 0) {
        .c-form__input:-webkit-autofill + & {
          font-size: 1.2rem;
        }
      }

      .c-form__input:focus + &,
      .c-form__input.is-focus + & {
        font-size: 1.2rem;
      }
    }

    &__input,
    &__textarea {
      font-family: $enBaseFontFamily;
      font-size: 1.4rem;
      line-height: 1.5;
    }

    &__privacy-label {
      font-size: 1.3rem;
      line-height: 1.5;

      @include mq(md) {
        font-size: 1.4rem;
      }
    }
    &__submit {
      font-family: $enBaseFontFamily;
      font-size: 1.5rem;
      line-height: 1.5;

      @include mq(md) {
        font-size: 1.8rem;
      }
    }
    // Validation
    &__error {
      font-size: 1.3rem;
      line-height: 1.5;
    }
  }

  .p-top-contact {
    .c-form {
      &__privacy-label {
        font-size: 1.4rem;
        line-height: 1.5;

        @include mq(md) {
          font-size: 1.2rem;
        }
      }

      &__submit {
        font-size: 1.5rem;

        @include mq(md) {
          font-size: 1.6rem;
        }
      }
    }
  }

}
