.c-headline-section {
  margin-top: 1em;

  @include mq(md) {
    margin-top: 60px;
    padding: 0 40px;
  }

  &--service {
    margin-top: 20px;

    @include mq(md) {
      margin-top: 50px;
    }
  }

  &__text {
    font-size: 3.2rem;
    font-weight: 900;
    line-height: (90/64);

    @include mq(md) {
      font-size: 6rem;
      line-height: (8/6);
    }

    .c-headline-section--service & {
      font-size: 2.6rem;
      line-height: (82/52);

      @include mq(md) {
        font-size: 4.6rem;
        line-height: (76/46);
      }
    }
  }
}

//以下 English Ver. 記述

.en {
  .c-headline-section {
    &__text {
      font-family: $enHeadingFont;
      font-size: 3.2rem;
      line-height: 1.2;

      @include mq(md) {
        font-size: 5.4rem;
      }
    }
    &__text--work {

      @include mq(md) {
        font-size: 6rem;
      }
    }
  }
}
