@charset "UTF-8";

// Media Query メディアクエリ
// ex) @include mq(md){};
// --------------------------------------------------

// Breakpoint ブレイクポイント
$breakpoints: (
  "sp": "screen and (min-width: 375px)",
  "spl": "screen and (min-width: 610px)",
  "md": "screen and (min-width: 769px)",
  "sp-only": "screen and (max-width: 768px)",
  "pc": "screen and (min-width: 1024px)",
  "pc-content": "screen and (min-width: 1240px)",
  "lg": "screen and (min-width: 1440px)",
  "xl": "screen and (min-width: 1600px)",
  "xxl": "screen and (min-width: 1920px)",
) !default;

// @mixinでメディアクエリを呼び出す
@mixin mq($breakpoint) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

// Size Auto Calculation サイズ自動計算
// --------------------------------------------------

// vw function SPデザイン幅基準のサイズ(767)
// ex) width: get_vw(100);
@function get_vw($size, $viewport: 767) {
  $rate: 100 / $viewport;

  @return $rate * $size * 1vw;
}

// font-size mixin
// ex) @include fz_vw(20);
@mixin fz_vw($font_size: 10) {
  font-size: get_vw($font_size);
}

// margin-bottom mixin
// ex) @include mgbtm_vw(20);
@mixin mgbtm_vw($mg_btm: 10) {
  margin-bottom: get_vw($mg_btm);
}

// padding-bottom mixin
// ex) @include pdbtm_vw(20);
@mixin pdbtm_vw($pd_btm: 10) {
  padding-bottom: get_vw($pd_btm);
}

// Form Reset フォームリセット
// --------------------------------------------------
@mixin form_reset {
  outline: none;
  border: none;
  border-radius: 0;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

// Form Placeholder Color フォームプレイスホルダーカラー
// --------------------------------------------------
@mixin placeholder_color($color) {
  &:placeholder-shown {
    color: $color;
  }

  &::-webkit-input-placeholder {
    color: $color;
  }

  &:-moz-placeholder {
    color: $color;
    opacity: 1;
  }

  &::-moz-placeholder {
    color: $color;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: $color;
  }
}

// Hide Text テキスト非表示
// --------------------------------------------------
@mixin text_hidden {
  overflow: hidden;
  text-indent: 200%;
  white-space: nowrap;
}

// Button Reset フォームリセット
// --------------------------------------------------
@mixin button_reset {
  outline: none;
  border: none;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
