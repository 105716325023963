.c-title-section {
  position: relative;
  padding-left: 30px;
  font-size: 1.6rem;
  font-weight: bold;
  color: $color-primary;

  @include mq(md) {
    padding-left: 50px;
    font-size: 2.4rem;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 20px;
    height: 1px;
    background-color: $color-primary;
    transform: translateY(-50%);

    @include mq(md) {
      width: 28px;
      height: 2px;
    }
  }
}

//以下 English Ver. 記述

.en {
  .c-title-section {
    font-family: $enHeadingFont;
    font-size: 1.8rem;
    line-height: 1.3;

    @include mq(md) {
      font-size: 2.6rem;
    }
  }
}
