.c-nav {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  overflow-y: scroll;
  width: 100%;
  height: 100vh;
  opacity: 0;
  transition: all 0.3s ease;

  @include mq(md) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.is-visible {
    visibility: visible;
    opacity: 1;
  }

  &__bg {
    position: fixed;
    top: 38px;
    right: 38px;
    z-index: 3;
    width: 2400px;
    height: 2400px;
    margin-top: -1200px;
    margin-right: -1200px;
    border-radius: 1200px;
    background-color: rgba($color-primary, 0.95);
    transition: transform 0.4s ease-in;
    transform: scale(0.0183333);

    @include mq(md) {
      top: 56px;
      right: 56px;
      width: ((100vw * 2) + 56);
      height: ((100vw * 2) + 56);
      margin-top: -(((100vw * 2) + 56)/2);
      margin-right: -(((100vw * 2) + 56)/2);
      border-radius: (((100vw * 2) + 56)/2);
      transform: scale(0.01777);
    }

    .c-nav.is-visible & {
      transform: scale(1);
    }
  }

  &__inner {
    position: absolute;
    z-index: 4;
    width: 100%;
    padding: 80px 38px;
    opacity: 0;
    transition: opacity 0.5s ease 0.3s;

    @include mq(md) {
      display: flex;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      max-width: 1016px;
      margin: 0 auto;
      padding: 80px 20px;
      transform: translate(-50%, -50%);
    }

    .c-nav.is-visible & {
      opacity: 1;
    }
  }

  &__list-wrap {
    @include mq(md) {
      width: 50%;
      padding: 0 58px;
    }
  }

  &__list {
    border-bottom: 1px solid $color-primary-200;

    @include mq(md) {
      width: 100%;
      padding: 0;
    }
  }

  &__item {
    display: block;
    padding: 15px 0;
    border-top: 1px solid $color-primary-200;

    @include mq(md) {
      padding: 20px 0 20px 16px;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-right: 30px;
  }

  &__link-icon {
    position: absolute;
    top: 50%;
    right: 0;
    width: 25px;
    height: 20px;
    transform: translateY(-50%);
    fill: #fff;

    @include mq(md) {
      width: 30px;
      height: 30px;
    }

    .c-nav__link:hover & {
      @include mq(md) {
        animation: arrow-right 0.5s ease forwards;
      }
    }
  }

  &__title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;

    @include mq(md) {
      font-size: 2rem;
    }
  }

  &__sub-title {
    font-family: $font-ratin;
    font-size: 10px;
    font-weight: bold;
    color: $color-secondary;

    @include mq(md) {
      font-size: 1.4rem;
    }
  }

  &__sub-menu {
    @include mq(md) {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: 0 58px;
    }
  }

  &__text {
    margin-top: 30px;
    font-size: 1.2rem;
    color: #fff;

    @include mq(md) {
      margin-top: 50px;
      font-size: 1.5rem;
    }
  }

  &__link-button {
    max-width: 300px;
    margin: 24px auto 0;

    @include mq(md) {
      width: 100%;
      max-width: 100%;
    }
  }

  &__sns {
    position: relative;
    margin-top: 40px;
    padding: 16px;

    @include mq(md) {
      margin-top: 64px;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border: 1px solid $color-primary-200;

      @include mq(md) {
        width: 12px;
        height: 12px;
      }
    }

    &::before {
      top: 0;
      right: 0;
      border-width: 1px 1px 0 0;
    }

    &::after {
      right: 0;
      bottom: 0;
      border-width: 0 1px 1px 0;
    }
  }

  &__sns-text {
    text-align: center;
    font-family: $font-ratin;
    font-size: 1.4rem;
    font-weight: bold;
    color: #fff;

    @include mq(md) {
      font-size: 1.8rem;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border: 1px solid $color-primary-200;

      @include mq(md) {
        width: 12px;
        height: 12px;
      }
    }

    &::before {
      bottom: 0;
      left: 0;
      border-width: 0 0 1px 1px;
    }

    &::after {
      top: 0;
      left: 0;
      border-width: 1px 0 0 1px;
    }
  }

  &__sns-list {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  &__sns-item {
    padding: 0 12px;
  }

  &__sns-link {
    display: block;
    max-width: 30px;
    height: 100%;
    fill: #fff;
  }

  &__sns-icon {
    height: 30px;
  }
}

//以下 English Ver. 記述

.en {
  .c-nav {
    &__title {
      font-size: 1.4rem;
      @include mq(md) {
        font-size: 2.2rem;
      }
    }
    &__text {
      font-size: 1.4rem;
      @include mq(md) {
        font-size: 1.7rem;
      }
    }
  }
}
