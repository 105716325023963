.c-button-rich {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  padding: 17px 20px;
  font-size: 1.3rem;
  font-weight: bold;
  border: 1px solid $color-black;
  background-color: #fff;
  color: $color-black;
  transition: background-color 0.2s ease, color 0.2s ease;

  @include mq(md) {
    padding: 20px 60px 20px 20px;
    font-size: 1.6rem;
    font-weight: 500;
  }

  &:hover {
    background-color: $color-black;
    color: #fff;
  }

  // COLOR
  &--black {
    background-color: $color-black;
    color: #fff;

    &:hover {
      background-color: #fff;
      color: $color-black;
    }
  }

  &--transparent {
    border: 1px solid #fff;
    background-color: rgba(256, 256, 256, 0);
    color: #fff;

    &:hover {
      background-color: #fff;
      color: $color-black;
    }
  }

  &--gray {
    border: 1px solid $color-gray-300;
  }

  // SIZE
  &--small {
    padding: 14px 20px;
    letter-spacing: 0.01em;
    font-size: 1.1rem;
    font-weight: 500;
  }

  &--pc-m {
    @include mq(md) {
      padding: 26px 80px 26px 26px;
    }
  }

  &--pc-l {
    @include mq(md) {
      padding: 30px 80px 30px 30px;
    }
  }

  &--fw-m {
    font-weight: 500;
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 20px;
    width: 30px;
    height: 30px;
    transition: fill 0.2s ease;
    transform: translateY(-50%);

    @include mq(md) {
      right: 20px;
      width: 35px;
      height: 35px;
    }

    .c-button-rich--black &,
    .c-button-rich--transparent & {
      fill: #fff;
    }

    .c-button-rich:hover & {
      fill: #fff;
    }

    .c-button-rich--black:hover &,
    .c-button-rich--transparent:hover & {
      fill: $color-black;
    }

    .c-button-rich--gray:hover & {
      @include mq(md) {
        fill: #fff;
      }
    }

    .c-button-rich--pc-l & {
      @include mq(md) {
        right: 30px;
        width: 40px;
        height: 40px;
      }
    }
  }
}

//以下 English Ver. 記述

.en {
  .c-button-rich {
    font-size: 1.5rem;
    font-family: $enBaseFontFamily;
    line-height: 1.5;

    @include mq(md) {
      font-size: 1.8rem;
    }
  }
}
