.p-top-cv-button {
  &__block {
    width: 100%;
    padding: 48px 0 48px;
    text-align: center;
    background-color: $color-secondary;

    @include mq(md) {
      padding: 73px 0 80px;
    }

    @include mq(xl) {
      padding: 64px 0 87px;
    }
  }

  &__block-inner {
    margin: 0 auto;
    padding: 0 5px;

    @include mq(md) {
      margin: 0 auto;
    }
  }

  &__text {
    margin-bottom: 33px;
    letter-spacing: -1px;
    font-family: $font-jp;
    font-size: 1.7rem;
    font-weight: 900;
    line-height: 34px;

    @include mq(md) {
      letter-spacing: 1px;
      font-size: 2.4rem;
      line-height: 48px;
    }
  }
}

//以下 English Ver. 記述
.en {
  .p-top-cv-button {
    &__text {
      font-family: $enBaseFontFamily;
      font-size: 2rem;
      line-height: 1.8;
      font-weight: 700;
      letter-spacing: 0.4px;

      @include mq(md) {
        font-size: 2.6rem;
      }
    }
  }
}
