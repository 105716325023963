.c-link-button {
  display: inline-block;
  position: relative;
  padding-right: 3.4rem;
  font-family: $font-ratin, $font-jp;
  font-size: 1.2rem;

  @include mq(md) {
    font-size: 1.4rem;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    width: 2em;
    height: 1em;
    background: url("/assets/img/share/arrow_01.svg") no-repeat center center;
    background-size: 2em;
    transform: translateY(-50%);
  }

  &:hover {
    &::after {
      @include mq(md) {
        animation: arrow-right 0.5s ease forwards;
      }
    }
  }

  &[target="_blank"] {
    padding-right: 2rem;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      width: 1em;
      height: 1em;
      background: url("/assets/img/share/link_blank.svg") no-repeat center center;
      transform: translateY(-50%);
    }

    &:hover {
      &::after {
        @include mq(md) {
          animation: none;
        }
      }
    }
  }

  &--white {
    &::after {
      background: url("/assets/img/share/arrow_02.svg") no-repeat center center;
    }
  }

  &--s {
    font-size: 1rem;
  }
}

//以下 English Ver. 記述

.en {
  .p-work-credit__list-item--foot {
    .c-link-button {
      font-family: $enBaseFontFamily;
      font-size: 1.6rem;
      line-height: 1.5;
    }
  }
}
