.p-about-brochure {
  margin-top: 50px;

  @include mq(md) {
    margin-top: 140px;
  }

  &__link {
    display: block;
    max-width: 300px;
    margin: 0 auto;
    padding: 12px;
    background: #ffbd1a url("/assets/img/page/about/brochure_01.png?v=2410") no-repeat;
    background-position-x: calc(100% + 30px);
    background-position-y: -50px;
    background-size: 180px;
    color: #fff;

    @include mq(md) {
      position: relative;
      max-width: 1000px;
      padding: 50px;
      background-position-x: 360px;
      background-position-y: -140px;
      background-size: 430px;
    }
  }

  &__title {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    line-height: 1;

    &--en,
    &--jp {
      display: inline-block;
      vertical-align: middle;
    }

    &--en {
      margin-right: 3px;
      font-family: $font-ratin;
      font-size: 2.5rem;
      font-weight: bold;
      color: $color-primary;

      @include mq(md) {
        margin-right: 10px;
        font-size: 5.4rem;
      }
    }

    &--jp {
      font-size: 1.8rem;
      font-weight: 900;

      @include mq(md) {
        font-size: 4rem;
      }
    }
  }

  &__text {
    display: inline-block;
    position: relative;
    margin-right: 30px;
    padding: 3px 6px;
    font-family: $font-ratin;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.1;

    @include mq(md) {
      margin: 15px 0 0 0;
      padding: 7px 15px;
      font-size: 1.5rem;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 2px;
      height: 16px;
      border: 1px solid #fff;

      @include mq(md) {
        width: 5px;
        height: 29px;
      }
    }

    &::before {
      left: 0;
      border-right-width: 0;
    }

    &::after {
      right: 0;
      border-left-width: 0;
    }
  }

  &__button {
    display: inline-block;
    position: relative;
    margin-top: 5px;
    padding: 3px 30px 3px 5px;
    font-size: 0.9rem;
    font-weight: bold;
    border: 1px solid #fff;
    border-radius: 20px;

    @include mq(md) {
      position: absolute;
      top: 50%;
      right: 38px;
      width: 127px;
      height: 127px;
      margin: 0;
      padding: 30px 0 0 0;
      text-align: center;
      font-size: 1.4rem;
      font-weight: 900;
      border: 1px solid #fff;
      border-radius: 50%;
      transform: translateY(-50%);
    }

    &--en {
      @include mq(md) {
        display: block;
        width: 100%;
        font-size: 1.8rem;
        line-height: 1;
      }
    }
  }

  &__button-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 15px;
    height: 15px;
    transform: translateY(-50%);
    fill: #fff;

    @include mq(md) {
      top: auto;
      right: auto;
      bottom: 20px;
      left: 50%;
      width: 35px;
      height: 35px;
      transform: translate(-50%, 0);
    }
  }
}

//以下 English Ver. 記述

.en {
  .p-about-brochure {

    &__title {
      &--jp {
        font-size: 1.6rem;

        @include mq(md) {
          font-size: 4rem;
        }
      }
    }

    &__text {
      font-size: 0.7rem;
      padding: 3px 13px;

      @include mq(md) {
        font-size: 1.5rem;
        padding: 7px 15px;
      }
    }
  }
}
