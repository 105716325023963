.p-stories-special {
  // margin-top: -14px;
  // background-image: url(/assets/img/page/stories/coopkobe/special/bg.png);
  // background-repeat: no-repeat;
  background-color: #F2F2F2;
  width:100%;
  height:auto;
  background-position: 45% 49.22%;
  background-image: linear-gradient(#cccccc75 1px, transparent 1px), linear-gradient(to right, #cccccc75 1px, #F2F2F2 1px);
  background-size: 70px 70px;
  // padding-top: 14px;
  font-family: "Noto Sans JP","Barlow Semi Condensed","Yu Gothic",yugothic,sans-serif;
  @include mq(md) {
    background-position: 50.6% 52.2%;
    background-size: 120px 120px;
    padding-top: 20px;
  }
  img {
    vertical-align: middle;
  }
  button {
    background: none;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  *:focus {
    outline: none;
  }

  // &__container {
  //   margin: 30px auto 0;
  //   // margin: 0 auto;
  //   padding: 0 (15 / 375 * 100%);
  //   @include mq(md) {
  //     margin: -10vw auto 0;
  //     // max-width: 840px;
  //     padding: 0 20px;
  //   }
  // }
  &__mv {
    position: relative;
    margin-right: calc(50% - 50vw); //画面いっぱい表示
    margin-left: calc(50% - 50vw);
    @include mq(md) {
      margin: 0;
      // max-width: 1280px;
      // margin-left: auto;
      // margin-right: auto;
    }
  }
  &__mv-title {
    position: absolute;
    display: flex;
    justify-content: center;
    // align-items: center;
    width: (87/375*100vw);
    height: (87/375*100vw);
    background-color: #fff;
    bottom: -21%;
    left: 3%;
    border-radius: 50%;
    @include mq(md) {
      width: (233/800*100%);
      height: (233/419*100%);
      bottom: 68%;
      left: -12.5%;
    }
  }
  &__mv-title-img {
    width: (73/87*100%);
    margin-top: 2.2rem;
    @include mq(md) {
      width: (194/233*100%);
      margin-top: 5.5rem;
    }
  }

  &__about {
    margin-bottom: 45px;
    @include mq(md) {
      margin-bottom: 148px;
    }
  }
  &__about-mv {
    margin-bottom: 45px;
    // @include mq(md) {
    //   margin-bottom: 45px;
    // }
  }
  &__about-texts {
    @include mq(md) {
      max-width: 1080px;
      margin-left: auto;
      margin-right: auto;
      letter-spacing: 0;
    }
    &+.p-stories-special__about-lead {
      @include mq(md) {
        margin-top: 140px;
      }
    }
  }
  &__about-text {
    font-size: 1.4rem;
    line-height: (29/14);
    letter-spacing: 0.09em;
    @include mq(md) {
      font-size: 1.8rem;
      line-height: (30/18);
      letter-spacing: inherit;
    }
    &+& {
      margin-top: 29px;
      @include mq(md) {
        margin-top: 30px;
      }
    }
  }
  &__about-lead {
    position: relative;
    font-size: 1.2rem;
    line-height: (23/12);
    letter-spacing: 0.09em;
    padding: 24px 122px 22px 20px;
    background-color: #f7f7f7;
    margin-top: 74px;
    letter-spacing: .015em;

    @include mq(md) {
      padding: 39px 194px 35px 56px;
      font-size: 1.6rem;
      line-height: 1.9;
      margin-top: 140px;
    }
    &::after {
      content: "";
      position: absolute;
      background: url(/assets/img/page/stories/coopkobe/01/bg_about-lead.png)  no-repeat center center;
      background-size: contain;
      width: 121px;
      height: 204px;
      bottom: 0;
      right: -1rem;
      @include mq(md) {
        width: 142px;
        height: 238px;
        right: 1rem;
      }
    }
  }
  &__about-list {
    padding-bottom: 24px;
    border-bottom: 1px solid #E2E2E2;
    margin-top: 20px;
    @include mq(md) {
      margin-top: 35px;
      padding-left: (52/800*100%);
      padding-bottom: 54px;
    }
  }
  &__about-item {
    font-size: 1.4rem;
    @include mq(md) {
    }
    &+& {
      margin-top: 20px;
      @include mq(md) {
        margin-top: 17px;
      }
    }
  }

  &__company-list {
    @include mq(md) {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }
    .p-stories-special__about-item--coop & {
      @include mq(sp-only) {
        display: flex;
      }
    }
  }
  &__company-term {
    display: flex;
    align-items: center; /* 垂直中心 */
    font-weight: 500;
    @include mq(md) {
      width: 145px;
    }
    &::before {
      content: "";
      display: inline-block;
      border-top: 1px solid;
      // border-color: $color-blue;
      width: 15px; /* 線の長さ */
      margin-right: 12px;

      .p-stories-special__about-item.is-blue & {
        border-color: $color-blue;
      }
    }
    .p-stories-special__about-item--coop & {
      @include mq(sp-only) {
        min-width: (27px+160px);
      }
    }
  }
  &__company-description {
    margin-left: (15px+12px);
    margin-top: 5px;
    @include mq(md) {
      margin-top: 0;
      margin-left: 0;
      display: flex;
    }
    .p-stories-special__about-item--coop & {
      @include mq(sp-only) {
        margin-top: 0;
        margin-left: 32px;
      }
    }
  }
  &__people-list {
    display: flex;
    flex-wrap: wrap;
    .p-stories-special__about-item--tam & {
      @include mq(sp-only) {
        margin-bottom: 3px;
      }
    }
  }
  &__people-wrap {
    // width: 252px;
    @include mq(md) {
      // width: 260px;
    }
    &+& {
      @include mq(md) {
        margin-left: 45px;
      }
    }
  }
  &__people-term {
    @include mq(md) {
      margin-right: 32px;
      width: 157px;
    }
    .p-stories-special__about-item--coop & {
      margin-right: 20px;
    }
    .p-stories-special__about-item--tam & {
      @include mq(sp-only) {
        min-width: 160px;
      }
    }
    .p-stories-special__people-wrap--second & {
      @include mq(md) {
        margin-right: 4px;
      }
    }
    @include mq(md) {
      width: auto;
      margin-right: 12px;
    }
  }
  &__people-description {
    @include mq(md) {
      margin-left: auto;
    }
    .p-stories-special__about-item--tam & {
      @include mq(sp-only) {
        margin-left: 33px;
      }
    }
    .p-stories-special__people-wrap--second & {
      @include mq(md) {
        margin-left: 0;
      }
    }
  }
  &__about-video-wrap {
    margin-top: 64px;
    @include mq(md) {
      margin-top: 95px;
    }
  }
  &__about-image-wrap {
    margin-top: 29px;
    @include mq(md) {
      margin-top: 64px;
    }
  }
  &__toc {
    padding: 60px 0 68px;
    @include mq(md) {
      padding: 98px 0 106px;
    }
  }
  &__toc-title {
    font-size: 1.7rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    @include mq(md) {
      font-size: 2rem;
    }
  }
  &__toc-list{
    margin-top: 17px;
    @include mq(md) {
      margin-top: 25px;
    }
  }
  &__toc-item {
    &+& {
      margin-top: 15px;
    }
    @include mq(md) {
      &+& {
        margin-top: 17px;
      }
    }
  }
  &__toc-link {
    display: flex;
    align-items: flex-start;
    background-color: #f7f7f7;
    border: 1px solid #e2e2e2;
    padding: 15px 16px 10px 18px;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    line-height: 1.55;
    @include mq(md) {
      padding: 15px;
      font-size: 1.5rem;
      align-items: center;
    }
    &--mark {
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      width: 47px;
      height: 47px;
      border-radius: 50%;
      color: #fff;
      background-color: $color-blue;
      font-size: 1.1rem;
      margin-right: 14px;

      @include mq(md) {
        font-size: 1.3rem;
        width: 55px;
        height: 55px;
        margin-right: 21px;
      }
      .is-active & {
        color: $color-blue;
        background-color: $color-secondary;
      }
    }
    &--text {
      .p-stories-special__toc-link:hover & {
        @include mq(md) {
          text-decoration: underline;
        }
      }
    }
  }
  &__contents {
    // margin-top: 65px;
    @include mq(md) {
      // margin-top: 100px;
    }
  }
  &__contents-lead {
    color: $color-blue;
    font-size: 1.4rem;
    line-height: (29/14);
    margin-bottom: 38px;
    @include mq(md) {
      font-size: 1.5rem;
      line-height: (33/15);
      margin-bottom: 45px;
    }
  }
  &__contents-link-wrap {
    margin-top: 48px;
    @include mq(md) {
      margin-top: 77px;
    }
  }
  &__contents-section {
    &+& {
      margin-top: 86px;
    @include mq(md) {
      margin-top: 130px;
    }
    }
  }
  // &__contents-image-wrap {
  //   margin-bottom: 53px;
  //   @include mq(md) {
  //     margin-bottom: 75px;
  //   }
  // }
  &__contents-section-link-wrap {
    margin-top: 50px;
    @include mq(md) {
      margin-top: 55px;
    }
  }
  &__contents-title-matome {
    position: relative;
    margin-bottom: 38px;
    padding-bottom: 51vw;
    overflow: hidden;
    background: url(/assets/img/page/stories/coopkobe/03/img_matome.png) center bottom no-repeat;
    background-size: 90% auto;
    @include mq(md) {
      margin-bottom: 45px;
      padding: 0;
      background: url(/assets/img/page/stories/coopkobe/03/img_matome.png) 412px 10px no-repeat;
      background-size: 322px auto;
    }
    &::before {
      content: "";
      display: none;
      float: left;
      width: 10px;
      height: 213px;
      border-top: 1px solid #dbdbdb;
      border-bottom: 1px solid #dbdbdb;
      border-left: 1px solid #dbdbdb;
      @include mq(md) {
        display: block;
        width: 10px;
        height: 193px;
      }
    }
    &::after {
      content: "";
      display: none;
      float: right;
      width: 10px;
      height: 213px;
      border-top: 1px solid #dbdbdb;
      border-bottom: 1px solid #dbdbdb;
      border-right: 1px solid #dbdbdb;
      @include mq(md) {
        display: block;
        width: 10px;
        height: 193px;
      }
    }
    .matome-box {
      left: calc((100% - 250px)/2);
      box-sizing: border-box;
      background: #FFCA36;
      color: #0070B3;
      font-size: 10px;
      text-align: center;
      width: 60%;
      margin: 0 auto;
      line-height: 36px;
      border-radius: 18px;
      span {
        font-size: 16px;
      }
      @include mq(md) {
        float: left;
        width: auto;
        margin: 66px 0 0 52px;
        padding: 0 2em;
        font-size: 15px;
        line-height: 50px;
        border-radius: 25px;
        span {
          font-size: 22px;
        }
      }
    }
  }
  &__contents-yokoku {
    margin-top: 48px;
    text-align: center;
    @include mq(md) {
      margin-top: 77px;
    }
    p.yokoku-text {
      width: 20em;
      margin: 0 auto;
      padding: 150px 0 30px 0;
      @include mq(md) {
        width: auto;
        margin: 0;
        padding: 77px 0 0 36%;
      }
    }
  }
  &__additional {
    margin-top: 90px;
    @include mq(md) {
      margin-top: 170px;
    }
    .p-stories-special__contents-image-wrap {
      margin-top: 53px;
      text-align: center;
      @include mq(md) {
        margin-top: 75px;
      }
      img {
        width: 90%;
        @include mq(md) {
          width: auto;
        }
      }
    }
  }
  &__additional-image-wrap {
    width: (277/345*100%);
    margin: 0 auto;
    @include mq(md) {
      width: 380px;
    }
  }
  &__additional-lead {
    color: $color-blue;
    line-height: 1.8;
    margin-top: 66px;
    @include mq(md) {
      line-height: (30/15);
      margin-top: 70px;
      padding-left: 155px;
    }
  }
  &__additional-cards {
    margin-top: 41px;
    @include mq(md) {
      margin-top: 48px;
    }
  }
  &__additional-card {
    @include mq(md) {
      display: flex;

    }
    &+& {
      margin-top: 55px;
      @include mq(md) {
        margin-top:79px;
      }
    }
  }
  &__additional-head {
    display: flex;
    align-items: center;
    @include mq(md) {
      display: block;
      margin-right: 42px;
      margin-top: 10px;
    }
  }
  &__additional-head-img {
    width: 98px;
    height: 108px;
    margin-right: 17px;
    @include mq(md) {
      width: 113px;
      height: 125px;
      margin-right: 0;
    }
  }
  &__additional-head-text {
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 20px;
    @include mq(md) {
      text-align: center;
      margin-top: 7px;
    }
    &--sm {
      display: block;
      font-size: 1.1rem;
    }
  }
  &__additional-body {
    margin-top: 26px;
    @include mq(md) {
      margin-top: 0;
    }
  }
  &__additional-body-text {
    font-size: 1.4rem;
    line-height: (29/14);
    letter-spacing: 0.09em;
    @include mq(md) {
      font-size: 1.3rem;
      line-height: (28/13);
    }
    &+& {
      margin-top: 30px;
    }
  }
  &__additional-link {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: (21/14);
    color: $color-blue;
    padding: 22px 17px 19px 20px;
    border: solid 1px #e2e2e2;
    background-color: #f7f7f7;
    margin-top: 22px;
    letter-spacing: 0.09em;
    @include mq(md) {
      font-size: 1.5rem;
      line-height: (23/15);
      padding: 23px 23px 17px 30px;
      margin-top: 34px;
    }
  }
  &__additional-link.mb {
    margin-bottom: 22px;
    @include mq(md) {
      margin-bottom: 34px;
    }
  }
  &__additional-link-text {
    text-decoration: underline;
    @include mq(md) {
    }
    &:hover {
      @include mq(md) {
        text-decoration: none;
      }
    }
  }










//special記述
&__container {
  margin: 10px auto 0;
  padding: 0 (15 / 375 * 100%);
  @include mq(md) {
    margin-top: -128px;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 23.8px;
    }
  }


  &__contents-wrap {
    display: flex;
    flex-direction: column;
    @include mq(md) {
      flex-direction: row;
      transform: rotate(0deg);
    }
  }

  // &__contents-image-wrap-2 {
  //   width: 311px;
  //   margin:  0 auto;
  //   @include mq(md) {
  //     width: 372px;
  //     margin: 0 19.5px 0 0;
  //   }
  // }

  &__contents-image-wrap {
    display: flex;
    justify-content: center;
    @include mq(md) {
      padding: 0 20px 0 0;
    }

    &.is-row-rev {
      @include mq(md) {
        padding: 0 0 0 20px;
      }
      .p-stories-special__contents_image-text {
        transform: rotate(7deg);
        margin-left: -23px;
        @include mq(md) {
          margin-left: -28px;
          margin-right: 12px;
        }
      }
      .p-stories-special__contents-image {
        margin-left: 0;
        @include mq(md) {

        }
      }
    }
  }

  &__contents_image-text {
    width: 140px;
    height: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #000;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.16);
    transform: rotate(-7deg);
    margin-left: 2px;
    margin-right: 3px;
    text-align: center;
    > span {
      font-size: 1.4rem;
      letter-spacing: .09em;
      font-weight: 500;
      line-height: calc(29 / 14);
      @include mq(md) {
        font-size: 1.6rem;
        letter-spacing: initial;
        line-height: calc(24 / 14);
      }
    }
    @include mq(md) {
      margin-left: 10px;
      margin-right: 4px;
    }
  }

  &__contents-image {
    margin-left: -30px;
    margin-top: 17px;
    > img {
      width: 186.5px;
      height: 186.5px;
      @include mq(md) {
        width: 248.5px;
        height: 248.5px;
      }
    }
  }

  &__contents-texts {
    background: #fff;
    padding: 21px 24px 27px 24px;
    margin-top: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.16);
    @include mq(md) {
      width: 640px;
      padding: 48px 43px 45px 48px;
      margin-top: 68px;
    }
  }

  &__contents-text {
    font-size: 1.4rem;
    line-height: (29/14);
    letter-spacing: 0.09em;
    @include mq(md) {
      font-size: 1.8rem;
      line-height: (30/18);
      letter-spacing: 0;
    }
    &+& {
      margin-top: 29px;
      @include mq(md) {
        margin-top: 31px;
      }
    }
  }

  &__contents-bg01 {
    width: 211px;
    margin-left: -4%;
    margin-top: 32px;
    @include mq(md) {
      // width: 515px;
      // margin-left: -23.8px;
      width: 669px;
      margin-left: calc(-153px + -23.8px);
      margin-top: -225px;
    }
  }
  &__contents-bg02 {
    // width: 100vw;
    // max-width: 375px;
    // margin: 0 calc(50% - 50vw);
    margin: 40px -4% 0;
    display: flex;
    justify-content: flex-end;
    img {
      width: 375px;
      @include mq(md) {
        width: 1514px;
      }
    }
    @include mq(md) {
      justify-content: flex-start;
      margin: -69px 0 0 calc(107px - 23.8px);
    }
  }

  &__contents-bg03 {
    margin: 23px -4% -6px;
    display: flex;
    justify-content: flex-end;
    img {
      width: 267px;
      @include mq(md) {
        width: 996px;
      }
    }
    @include mq(md) {
      justify-content: flex-start;
      margin: -360px 0 21px calc(826px - 23.8px);
    }
  }

  &__contents-bg04 {
    width: 324px;
    margin-left: -4%;
    margin-top: 48px;
    @include mq(md) {
      width: 1315px;
      margin-left: calc(-741px + -23.8px);
      margin-top: -338px;
    }
  }

  &__bana {
    margin-top: 48px;
    @include mq(md) {
      width: 800px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 200px;
    }
  }

  &__bg {
    background-color: #fff;
    height: 60px;
    margin-top: 40px;
    @include mq(md) {
     display: none;
    }
  }

  .c-section-contact {
    background-color: #fff;
    margin-top: 0;
    @include mq(md) {
      background-color: transparent;
    }
  }

  .has-underline {
    background: linear-gradient(transparent 70%, #f4ce43ab 70%);
  }

  .u-mt46 {
    margin-top: 46px;
    @include mq(md) {
      margin-top: -53px;
    }
  }

  .u-mt57 {
    margin-top: 57px;
    @include mq(md) {
      margin-top: 161px;
    }
  }

  .u-mt80 {
    margin-top: 80px;
    @include mq(md) {
      margin-top: 253px;
    }
  }

  .u-mt81 {
    margin-top: 81px;
    @include mq(md) {
      margin-top: 171px;
    }
  }
  .u-mt82 {
    margin-top: 82px;
    @include mq(md) {
      margin-top: 181px;
    }
  }

  .u-display-sp {
    display: block;
    @include mq(md) {
      display: none;
    }
  }

  .u-display-pc {
    display: none;
    @include mq(md) {
      display: block;
    }
  }

  .is-row-rev {
    flex-direction: row-reverse;
  }
  .is-row-rev-pc {
    @include mq(md) {
      flex-direction: row-reverse;
    }
  }

  .is-bg-yellow {
    background-color: #fff8b5;
  }

  .is-bg-blue {
    background-color: #D3E9FF;
  }

  .is-bg-pink {
    background-color: #FFEBF2;
  }

  .is-bg-green {
    background-color: #E9FFB9;
  }

  .is-lh-22-14 {
    line-height: calc(22 / 14);
    @include mq(md) {
      line-height: calc(20 / 14);
    }
  }








  //共通コンポーネント
  &-toc-list {
    visibility: visible;
    opacity: 1;
    position: fixed;
    z-index: 1;
    bottom: 20px;
    right: 15px;
    transition: opacity .3s;
    &.is-hide {
      visibility: hidden;
      opacity: 0;
    }
    @include mq(sp-only) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-toc-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    color: #fff;
    background-color: $color-blue;
    font-weight: 500;
    @include mq(sp-only) {
      transition: transform .3s;
      position: absolute;
    }
    &:nth-of-type(1) {
      .p-stories-sanpo-toc-list.is-spread & {
        @include mq(sp-only) {
          transform: translateY(-150px);
        }
      }
    }
    &:nth-of-type(2) {
      .p-stories-sanpo-toc-list.is-spread & {
        @include mq(sp-only) {
          transform: translateY(-100px);
        }
      }
    }
    &:nth-of-type(3) {
      .p-stories-sanpo-toc-list.is-spread & {
        @include mq(sp-only) {
          transform: translateY(-50px);
        }
      }
    }
    &:nth-of-type(4) {
      position: relative;
    }

    &.is-active {
      background-color: $color-secondary;
      z-index: 1;
      width: 46px;
      height: 46px;
      // &::before {
      //   @include mq(md) {
      //     content: "";
      //     position: absolute;
      //     background: url(/assets/img/page/stories/coopkobe/01/icon_foot.svg)  no-repeat center center;
      //     background-size: contain;
      //     width: 34px;
      //     height: 58px;
      //     z-index: 5;
      //     top: -17px;
      //     left: -39px
      //   }
      // }
    }

    &+& {
      @include mq(md) {
        margin-top: 5px;
      }
    }
  }
  &-toc-link {
    display: block;
    font-size: 1rem;
    font-weight: 700;
    -webkit-tap-highlight-color:rgba(0,0,0,0);
    cursor:pointer;
    outline: none;
    .p-stories-special-toc-item.is-active & {
      color: $color-blue;
    }
  }
  //見出し
  &-heading--lev1 {
    font-size: 2.2rem;
    font-weight: 900;
    line-height: (35/22);
    letter-spacing: 0.09em;
    // margin-bottom: 35px;
    margin-bottom: 38px;
    color: #2F6EAE;
    @include mq(md) {
      font-size: 3.6rem;
      line-height: (56/36);
      margin-top: 20px;
      margin-bottom: 94px;
      text-align: center;
    }
  }
  &-heading--lev2 {
    font-size: 1.8rem;
    line-height: (26/18);
    font-weight: 700;
    margin-bottom: 44px;
    letter-spacing: 0.09em;
    color: #2f6eae;
    text-align: center;
    @include mq(md) {
      font-size: 4.2rem;
      line-height: (62/42);
      margin-bottom: 101px;
      letter-spacing: inherit;
    }
  }
  &-heading--lev3 {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    line-height: (24/16);
    font-weight: 900;
    margin-bottom: 35px;
    letter-spacing: 0.1em;
    @include mq(md) {
      font-size: 2rem;
      line-height: (36/20);
      margin-bottom: 37px;
    }
    &::before {
      content: "";
      display: inline-block;
      border-top: 2px solid;
      width: 5px;
      margin-right: 5px;
    }
    &.has-icon {
      &::before {
        display: block;
        flex-shrink: 0;
        content: "";
        background: url(/assets/img/share/tamkun_icon_01.svg)  no-repeat center center;
        background-size: contain;
        width: 36px;
        height: 40px;
        border: none;
        margin-right: 16px;
      }
    }
    .p-stories-special__additional & {
      margin-bottom: 26px;
      @include mq(md) {
        margin-bottom: 30px;
      }
    }
  }
  &-figcaption {
    font-size: 1.1rem;
    line-height: (17/11);
    color: #666;
    letter-spacing: 0.1em;
    text-align: center;
    margin-top: 12px;
    @include mq(md) {
      font-size: 1.2rem;
      margin-top: 10px;
      letter-spacing: 0.13em;
    }
  }
  &-speaker {
    font-size: 1.4rem;
    font-weight: 700;
    @include mq(md) {
      font-size: 1.5rem;
    }
    &+.p-stories-special-answer {
      margin-top: 10px;
    }
  }
  &-answer {
    font-size: 1.4rem;
    line-height: 2.1;
    letter-spacing: 0.1em;
    @include mq(md) {
      font-size: 1.5rem;
      line-height: 2.2;
    }
    &+.p-stories-special-speaker {
      margin-top: 30px;
      @include mq(md) {
        margin-top: 36px;
      }
    }
    &+.p-stories-special__contents-image-wrap {
      margin-top: 50px;
    }
  }
  &-anotation {
    color: #666;
    border: solid 1px #e2e2e2;
    padding: 20px 20px 18px;
    font-size: 1.3rem;
    margin-top: 16px;
    letter-spacing: 0.1em;
    line-height: 2;
    @include mq(md) {
      margin-top: 28px;
      padding: 25px 22px 24px 34px;
      font-size: 1.4rem;
    }
  }
  &-link {
    display: block;
    color: #fff;
    background-color: $color-blue;
    padding: 24.2px 21px 20.9px 18px;
    font-size: 1.4rem;
    line-height: (22/14);
    font-weight: 500;
    @include mq(md) {
      padding: 27px 18px 27px 29px;
      display: flex;
      align-items: center;
    }
  }
  &-link-mark {
    display: inline-block;
    color: $color-blue;
    background-color: $color-secondary;
    padding: 6px 15px 5px;
    border-radius: 15px;
    font-size: 1.2rem;
    margin: 0 0 7px 0;
    @include mq(md) {
      display: block;
      padding: 6px 15px 5px;
      margin: 0 15px 0 0;
    }
  }
  &-link-text {
    display: block;
    letter-spacing: 0.15em;
    .p-stories-special-link:hover & {
      @include mq(md) {
        text-decoration: underline;
      }
    }
  }
  .p-micromodal.is-open {
    z-index: 10;
    position: relative;
  }
  .p-micromodal__trigger-btn-wrap {
    position: absolute;
    right: 2%;
    bottom: 2.5%;
    @include mq(md) {
      right: 2%;
      bottom: 3%;
    }
  }
  .p-stories-special__contents-image-contents {
    position: relative;
  }
  .p-micromodal__trigger-btn {
    width: 40px;
    height: 40px;
    background-color: #ffca36;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq(md) {
      width: 53px;
      height: 53px;
      cursor: pointer;
    }
    img {
      width: 18px;
      height: 18px;
      @include mq(md) {
        width: 23px;
        height: 23px;
      }
    }
  }
  .p-micromodal__overlay {
    background: rgba(0,0,0,.85);
  }
  .p-micromodal__container {
    padding: 25% 0 0;
    @include mq(md) {
      padding: 56px 2rem 0;
      max-width: 1440px;
    }
  }
  .p-micromodal__inner {
    padding: 0;
    // width: 100%;
    @include mq(md) {
      width: (1180/1440*100%);
      margin: 0 auto;
    }
  }
  .p-micromodal__btn-wrap {
    position: absolute;
    top: 1.5%;
    right: 1.5%;
    @include mq(md) {
      top: -1.4%;
      right: 1.5%;
    }
  }
  .p-micromodal__btn--icon {
    width: 44px;
    height: 44px;
    background-color: #fff;
    border-radius: 100%;
    margin-bottom: 30px;
    &::before,
    &::after {
      height: 15px;
      background-color: #000;
      @include mq(md) {
        height: 19px;
      }
    }
    @include mq(md) {
      width: 64px;
      height: 64px;

    }
  }
  .p-micromodal__text {
    color: #fff;
    font-size: 1.3rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    &::before {
      content: "";
      display: inline-block;
      background-image: url("/assets/img/page/stories/coopkobe/03/icon_scale_hand.svg");
      width: 30px;
      height: 33px;
      margin-right: 12px;
    }
    @include mq(md) {
      display: none;
    }
  }

  .is-blue {
    color: $color-blue;
  }
  .u-pst-relative {
    position: relative;
  }
}
.page-stories-special {
  &.is-scrollFix {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
