/* ~~~~~~~~~~~~~~
  旧MV
  ~~~~~~~~~~~~~~ */
.p-top-hero {
  position: relative;

  @include mq(md) {
    margin-top: 40px;
  }

  &__headline-wrap {
    @include mq(md) {
      max-width: 1240px;
      margin: 0 auto;
      padding: 0 20px;
    }
  }

  &__headline {
    padding: 0 (36 / 375 * 100%);
    font-family: $font-ratin;
    font-size: 6.1rem;
    font-weight: bold;
    line-height: 1.2;

    @include mq(md) {
      padding: 0;
      font-size: 13.2rem;
      line-height: 1;
    }
  }

  &__headline-one {
    display: block;
    position: relative;
    width: 180px;
    color: rgba(256, 256, 256, 0);

    @include mq(md) {
      width: 430px;
    }
  }

  &__headline-one-base,
  &__headline-one-over {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
    width: 0;
    height: 73px;
    white-space: nowrap;
    line-height: 73.2px;

    @include mq(md) {
      height: 135px;
      line-height: 132px;
    }
  }

  &__headline-one-base {
    color: $color-primary;
    -webkit-text-fill-color: #fff;
    -webkit-text-stroke-color: $color-primary;
    -webkit-text-stroke-width: 1px;
  }

  &__headline-one-over {
    -webkit-text-fill-color: $color-primary;
    -webkit-text-stroke-color: $color-primary;
    -webkit-text-stroke-width: 1px;
  }

  &__headline-two {
    @include mq(md) {
      display: block;
      margin-top: 7px;
    }
  }

  &__headline-blue {
    position: relative;
    color: rgba(256, 256, 256, 0);
  }

  &__headline-blue-lay {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 0;
    height: 1em;
    white-space: nowrap;
    line-height: 73.2px;
    color: $color-primary;

    @include mq(md) {
      height: 132px;
      line-height: 132px;
    }
  }

  &__headline-yellow {
    position: relative;
    color: rgba(256, 256, 256, 0);
  }

  &__headline-yellow-lay {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 0;
    height: 1em;
    white-space: nowrap;
    line-height: 73.2px;
    color: $color-secondary;

    @include mq(md) {
      line-height: 132px;
    }
  }

  &__lead {
    padding: 35px 0;
    background: url("/assets/img/page/top/hero_bg_01.svg");
    background-size: 500px;

    @include mq(md) {
      padding: 50px 0;
      letter-spacing: 0.01em;
      background-size: 640px;
    }
  }

  &__lead-inner {
    padding: 0 (36 / 375 * 100%);
    opacity: 0;
    transition: opacity 1s ease, transform 1s ease;
    transition-delay: 1.8s;
    transform: translateY(-25px);

    @include mq(md) {
      max-width: 1240px;
      margin: 0 auto;
      padding: 0 20px;
    }

    .is-start & {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__lead-title {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: (66/40);

    @include mq(md) {
      font-size: 2.6rem;
    }
  }

  &__lead-text {
    margin-top: 24px;
    font-size: 1.3rem;
    line-height: (48/26);

    @include mq(md) {
      margin-top: 30px;
      font-size: 1.5rem;
      line-height: (33/15);
    }
  }

  &__picture {
    position: absolute;
    top: 10px;
    left: calc((220 / 375 * 100vw));
    width: (421/749 * 100%);

    @include mq(spl) {
      left: calc(100% - 260px);
      max-width: 350px;
    }

    @include mq(md) {
      left: calc((245 / 375 * 100%));
      width: (812/1440 * 100%);
      max-width: 912px;
    }

    @include mq(pc) {
      left: calc((236 / 375 * 100vw));
      width: (912/1440 * 100%);
      max-width: 912px;
    }
  }

  /* ~~~~~~~~~~~~~~
  アニメーション
  ~~~~~~~~~~~~~~ */
  // 1- タムくん
  &__picture {
    opacity: 0;
    transition: opacity 0.8s ease;

    .is-start & {
      opacity: 1;
      animation: hero-picture-bounce 0.9s ease forwards;

      @include mq(md) {
        animation: hero-picture-bounce-pc 0.9s ease forwards;
      }
    }
  }

  // 2- HELLO,
  // →
  &__headline-one-base {
    .is-start & {
      animation: width-max 0.3s ease-out forwards 0.3s;

      @include mq(md) {
        animation: width-max 0.3s ease-out forwards 0.3s;
      }
    }
  }

  // ←
  &__headline-one-over {
    .is-start & {
      animation: width-max-min 0.6s ease-out forwards 0.3s;

      @include mq(md) {
        animation: width-max-min 0.6s ease-out forwards 0.3s;
      }
    }
  }

  // 3- WE ARE
  &__headline-blue-lay {
    transition: width 0.3s ease-out 0.9s;

    .is-start & {
      width: 200px;

      @include mq(md) {
        width: 450px;
      }
    }
  }

  // 4- TAM
  &__headline-yellow-lay {
    transition: width 0.3s ease-out 1.2s;

    @include mq(md) {
      transition-delay: 1.2s;
    }

    .is-start & {
      width: 200px;

      @include mq(md) {
        width: 230px;
      }
    }
  }

  // 5- リード文
  &__lead-inner {
    opacity: 0;
    transition: opacity 1s ease, transform 1s ease;
    transition-delay: 1.5s;
    transform: translateY(-25px);

    .is-start & {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

/* ~~~~~~~~~~~~~~
  新MV
  ~~~~~~~~~~~~~~ */
.p-top-hero {
  position: relative;
  z-index: 1;
  margin-top: -76px;

  @include mq(md) {
    margin-top: -110px;
  }

  &__copy {
    display: flex;
    position: absolute;
    top: 78px;
    left: 0;
    z-index: 3;
    overflow: hidden;
    height: auto;

    @include mq(md) {
      top: 112px;
      left: auto;
      overflow: visible;
      width: 100%;
      padding: 0 25px;

      svg {
        width: 100%;
        height: auto;
      }
    }

    @include mq(xxl) {
      top: 148px;
      padding: 0 35px;
    }
  }

  &__copy--sp {
    display: flex;
    width: 100%;

    @include mq(md) {
      display: none;
    }
  }

  &__copy--pc {
    display: none;

    @include mq(md) {
      display: block;
      width: 100%;
    }
  }

  &__hashtag-list-wrapper--sp {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    position: absolute;
    top: 153px;
    right: 18px;
    z-index: 3;

    @include mq(md) {
      display: none;
    }
  }

  &__hashtag-list-wrapper--pc {
    display: none;

    @include mq(md) {
      display: block;
      position: absolute;
      top: 257px;
      right: auto;
      z-index: 3;
      padding-left: 25px;
    }

    @include mq(xxl) {
      top: 343.3px;
      padding-left: 26px;
    }
  }

  &__hashtag-list {
    width: 30px;

    @include mq(md) {
      width: 404px;
    }

    svg {
      display: block;

      @include mq(xxl) {
        width: 535px;
        height: 59px;
      }
    }
  }

  &__hashtag-list:first-child {
    margin-left: 8px;

    @include mq(md) {
      width: 374px;
      margin-bottom: 20.36px;
      margin-left: 0;
    }

    @include mq(xxl) {
      width: 493px;
      margin-bottom: 27.14px;
    }

    svg {
      @include mq(xxl) {
        width: 493px;
        height: 59px;
      }
    }
  }

  // swiper
  .swiper-slide {
    margin-right: 0;

    img {
      display: block;
      width: 100%;
    }
  }

  .swiper-pagination {
    display: flex;
    position: absolute;
    right: 7px;
    bottom: 10.4px;
    z-index: 3;

    @include mq(md) {
      display: block;
      bottom: 16px;
      left: 20px;
    }
  }

  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 4px;

    @include mq(md) {
      margin: 0 0 3px;
    }
  }

  /* pagenation */
  .swiper-pagination-bullet {
    display: block;
    position: relative;
    width: 20px;
    height: 20px;
    cursor: pointer;

    @include mq(md) {
      width: 32px;
      height: 32px;
      margin-top: 10px;
      margin-right: 0;
    }

    .circle_inner {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 10;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #aaa;
      transform: translate(-50%, -50%);

      @include mq(md) {
        width: 8px;
        height: 8px;
      }
    }
  }

  .swiper-pagination-bullet {
    .circle_inner {
      svg {
        display: none;
      }
    }
  }

  .swiper-pagination-bullet-active {
    background-color: transparent;

    .circle_inner {
      display: block;
      background-color: #0d0a0a;

      svg {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
