.p-stories-sanpo {
  // margin-top: -14px;
  @include mq(md) {
    margin-top: 40px;
  }
  img {
    vertical-align: middle;
  }
  button {
    background: none;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  *:focus {
    outline: none;
  }

  &__container {
    margin: 30px auto 0;
    // margin: 0 auto;
    padding: 0 (15 / 375 * 100%);
    @include mq(md) {
      margin: -21vw auto 0;
      max-width: 840px;
      padding: 0 20px;
    }
  }
  &__mv {
    position: relative;
    margin-right: calc(50% - 50vw); //画面いっぱい表示
    margin-left: calc(50% - 50vw);
    @include mq(md) {
      margin: 0;
    }
  }
  &__mv-title {
    position: absolute;
    display: flex;
    justify-content: center;
    // align-items: center;
    width: (87/375*100vw);
    height: (87/375*100vw);
    background-color: #fff;
    bottom: -21%;
    left: 3%;
    border-radius: 50%;
    @include mq(md) {
      width: (233/800*100%);
      height: (233/419*100%);
      bottom: 68%;
      left: -12.5%;
    }
  }
  &__mv-title-img {
    width: (73/87*100%);
    margin-top: 2.2rem;
    @include mq(md) {
      width: (194/233*100%);
      margin-top: 5.5rem;
    }
  }

  &__about {
    // margin-top: 49px;
    @include mq(md) {
      margin-top: 0;
    }
  }
  &__about-mv {
    margin-bottom: 45px;
    // @include mq(md) {
    //   margin-bottom: 45px;
    // }
  }
  &__about-texts {
    @include mq(md) {
      // margin-top: 38px;
    }
    &+.p-stories-sanpo__about-lead {
      @include mq(md) {
        margin-top: 140px;
      }
    }
  }
  &__about-text {
    font-size: 1.4rem;
    line-height: (29/14);
    letter-spacing: 0.09em;
    @include mq(md) {
      font-size: 1.5rem;
      line-height: (33/15);
      letter-spacing: 0.09em;
    }
    &+& {
      margin-top: 29px;
      @include mq(md) {
        margin-top: 34px;
      }
    }
  }
  &__toc &__about-text {
    margin-top: 12px;
    margin-bottom: 36px;
      @include mq(md) {
        margin-top: 0;
        margin-bottom: 0;
      }
  }
  &__about-lead {
    position: relative;
    font-size: 1.2rem;
    line-height: 1.75;
    letter-spacing: 0.09em;
    padding: 14px 122px 12px 20px;
    background-color: #f7f7f7;
    margin-top: 74px;
    letter-spacing: .015em;

    @media (min-width:429px) {
      margin-top: 120px;
    }

    @include mq(md) {
      padding: 39px 194px 35px 56px;
      font-size: 1.6rem;
      line-height: 1.9;
      margin-top: 140px;
    }
    &::after {
      content: "";
      position: absolute;
      background: url(/assets/img/page/stories/coopkobe/01/bg_about-lead.png)  no-repeat center center;
      background-size: contain;
      width: 121px;
      height: 204px;
      bottom: 0;
      right: -1rem;
      @include mq(md) {
        width: 142px;
        height: 238px;
        right: 1rem;
      }
    }
  }
  &__about-list {
    padding-bottom: 24px;
    border-bottom: 1px solid #E2E2E2;
    margin-top: 20px;
    @include mq(md) {
      margin-top: 35px;
      padding-left: (52/800*100%);
      padding-bottom: 54px;
    }
  }
  &__about-list.no-border {
    padding-bottom: 0;
    border-bottom: 0;
    margin-top: 20px;
    @include mq(md) {
      padding-bottom: none;
    }
  }
  &__about-item {
    font-size: 1.4rem;
    @include mq(md) {
    }
    &+& {
      margin-top: 20px;
      @include mq(md) {
        margin-top: 17px;
      }
    }
    &.comment, &.comment dt, &.comment dd {
      margin-top: 0;
      color: #ADADAD;
      @include mq(md) {
      }
    }
  }

  &__company-list {
    @include mq(md) {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }
    .p-stories-sanpo__about-item--coop & {
      @include mq(sp-only) {
        display: flex;
      }
    }
  }
  
  &__company-term {
    display: flex;
    align-items: center; /* 垂直中心 */
    font-weight: 500;
    @include mq(md) {
      width: 145px;
    }
    &::before {
      content: "";
      display: inline-block;
      border-top: 1px solid;
      // border-color: $color-blue;
      width: 15px; /* 線の長さ */
      margin-right: 12px;

      .p-stories-sanpo__about-item.is-blue & {
        border-color: $color-blue;
      }
    }
    .p-stories-sanpo__about-item.comment &::before {
      border: none;
    }
    .p-stories-sanpo__about-item--coop & {
      @include mq(sp-only) {
        min-width: (27px+160px);
      }
    }
  }
  &__company-description {
    margin-left: (15px+12px);
    margin-top: 5px;
    @include mq(md) {
      margin-top: 0;
      margin-left: 0;
      display: flex;
    }
    .p-stories-sanpo__about-item--coop & {
      @include mq(sp-only) {
        margin-top: 0;
        margin-left: 32px;
      }
    }
  }
  &__people-list {
    display: flex;
    flex-wrap: wrap;
    .p-stories-sanpo__about-item--tam & {
      @include mq(sp-only) {
        margin-bottom: 3px;
      }
    }
  }
  &__people-wrap {
    // width: 252px;
    @include mq(md) {
      // width: 260px;
    }
    &+& {
      @include mq(md) {
        margin-left: 45px;
      }
    }
  }
  &__people-term {
    @include mq(md) {
      margin-right: 32px;
      width: 157px;
    }
    .p-stories-sanpo__about-item--coop & {
      margin-right: 20px;
    }
    .p-stories-sanpo__about-item--tam & {
      @include mq(sp-only) {
        min-width: 160px;
      }
    }
    .p-stories-sanpo__people-wrap--second & {
      @include mq(md) {
        margin-right: 12px;
      }
    }
    @include mq(md) {
      width: auto;
      margin-right: 12px;
    }
  }
  &__people-description {
    @include mq(md) {
      margin-left: auto;
    }
    .p-stories-sanpo__about-item--tam & {
      @include mq(sp-only) {
        margin-left: 33px;
      }
    }
    .p-stories-sanpo__people-wrap--second & {
      @include mq(md) {
        margin-left: 0;
      }
    }
  }
  &__about-video-wrap {
    margin-top: 64px;
    @include mq(md) {
      margin-top: 95px;
    }
  }
  &__about-image-wrap {
    margin-top: 29px;
    @include mq(md) {
      margin-top: 64px;
    }
  }
  &__toc {
    padding: 60px 0 68px;
    @include mq(md) {
      padding: 98px 0 106px;
    }
  }
  &__toc.single {
    padding: 30px 0 68px;
    @include mq(md) {
      padding: 60px 0 106px;
    }
  }
  &__toc.single.bottom {
    margin: 53px 0 63px;
    padding: 0;
    @include mq(md) {
      margin: 75px 0 85px;
      padding: 0;
    }
  }
  &__toc-title {
    font-size: 1.7rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    @include mq(md) {
      font-size: 2rem;
    }
  }
  &__toc-list{
    margin-top: 17px;
    padding: 5px 20px;
    background: #f7f7f7;
    @include mq(md) {
      margin-top: 25px;
      padding: 25px 35px;
    }
  }
  &__toc.single &__toc-list{
    margin-top: 0;
    margin-bottom: 30px;
    @include mq(md) {
      margin-top: 0;
      margin-bottom: 30px;
    }
  }
  &__toc-item {
    &+& {
      margin-top: 0;
    }
    @include mq(md) {
      &+& {
        margin-top: 0;
      }
    }
  }
  &__toc-link {
    display: flex;
    align-items: flex-start;
    position: relative;
    background-color: #f7f7f7;
    border-bottom: 1px solid #e2e2e2;
    padding: 15px 38px 10px 55px;
    font-size: 1.4rem;
    font-weight: 500;
    color: #0070B3;
    line-height: 1.55;
    @include mq(md) {
      padding-left: 0;
      font-size: 1.4rem;
      align-items: center;
    }
    
    &.is-active {
      color: #0D0A0A;
    }
    
    .p-stories-sanpo__toc-item:last-child & {
      border-bottom: none;
    }
    
    &--mark {
      position: absolute;
      top: calc(50% - 13px);
      left: 0;
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      width: 42px;
      height: 30px;
      border-radius: 15px;
      color: #fff;
      background-color: $color-blue;
      font-size: 1.1rem;

      @include mq(md) {
        position: relative;
        top: auto;
        left: auto;
        display: flex;
        font-size: 1.3rem;
        width: 55px;
        height: 30px;
        margin-top: 0;
        margin-right: 15px;
      }
      .is-active & {
        color: $color-blue;
        background-color: $color-secondary;
      }
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 55%;
      right: 0;
      width: 28px;
      height: 1em;
      background: url("/assets/img/share/arrow_03.svg") no-repeat center center;
      background-size: 28px auto;
      transform: translateY(-50%);
      @include mq(md) {
      }
    }
    &.is-active::after {
      background-image: url("/assets/img/share/arrow_01.svg");
    }
    .p-stories-sanpo__toc.single &::after {
      display: none;
      @include mq(md) {
        display: none;
      }
    }

    &:hover {
      &::after {
        @include mq(md) {
          animation: arrow-right 0.5s ease forwards;
        }
      }
    }
    .p-stories-sanpo__toc.single & {
      display: block;
    }
    .p-stories-sanpo__toc.single &--mark {
      display: inline-block;
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      width: auto;
      height: auto;
      margin-left: 1em;
      color: $color-blue;
      font-size: 11px;
      background: none;
      @include mq(md) {
        top: auto;
        right: auto;
        width: auto;
        height: 30px;
        margin-left: 1.5em;
        font-size: 18px;
      }
    }

    &--text {
      .p-stories-sanpo__toc-link:hover & {
        @include mq(md) {
          text-decoration: underline;
        }
      }
    }
    .p-stories-sanpo__toc.single &--text {
      font-size: 11px;
      color: $color-black;
      @include mq(md) {
        font-size: 18px;
      }
    }
  }
  &__toc.single &__toc-link {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 0;
    @include mq(md) {
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 0;
    }
  }
  &__toc.single &__toc-link:hover &__toc-link--text {
    text-decoration: none;
    @include mq(md) {
      text-decoration: none;
    }
  }
  &__toc.single &__toc-link:hover &__toc-link--mark {
    text-decoration: none;
  }
  &__toc.single &__toc-link:hover &__toc-link--mark span {
    text-decoration: underline;
  }
  &__toc-banner {
    a {
      position: relative;
      margin-top: 45px;
      overflow: hidden;
      display: block;
      @include mq(md) {
        margin-top: 30px;
        background: #0070B3;
      }
    }
  }
  &__toc-banner--image {
    display: block;
    @include mq(md) {
      float: left;
      width: 340px;
    }
    img {
      width: 100%;
    }
  }
  &__toc-banner--body {
    display: block;
    margin: 15px 0 0 0;
    font-family: "Noto Sans JP",sans-serif;
    @include mq(md) {
      float: right;
      width: calc(100% - 340px);
      margin: 0 0 0 0;
      padding: 63px 0 0 25px;
    }
  }
  &__toc-banner--mark {
    display: block;
    float: left;
    width: 72px;
    height: 30px;
    line-height: 30px;
    margin: 0 15px 0 0;
    border-radius: 15px;
    background: #0070B3;
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    @include mq(md) {
      width: 75px;
      height: 34px;
      line-height: 34px;
      margin: 0 18px 0 0;
      border-radius: 17px;
      background: #fff;
      color: #0070B3;
    }
  }
  &__toc-banner--text {
    display: block;
    float: left;
    width: calc(100% - 87px);
    font-size: 19px;
    font-weight: bold;
    color: #0070B3;
    @include mq(md) {
      float: left;
      width: calc(100% - 93px);
      color: #fff;
      font-size: 21px;
      font-weight: bold;
      line-height: 34px;
    }
    a:hover & {
      text-decoration: underline;
    }
    &::after {
      content: "";
      display: inline-block;
      width: 36px;
      height: 1em;
      margin: 0 0 0 5px;
      background: url("/assets/img/share/arrow_03.svg") no-repeat center center;
      background-size: 36px auto;
      transform: translateY(-50%);
      vertical-align: -35%;
      @include mq(md) {
        position: absolute;
        bottom: 12px;
        right: 30px;
        width: 50px;
        height: 1em;
        margin: 0;
        background: url("/assets/img/share/arrow_02.svg") no-repeat center center;
        background-size: 50px auto;
        transform: translateY(-50%);
      }
    }
    a:hover &::after {
      @include mq(md) {
        animation: arrow-right 0.5s ease forwards;
      }
    }
  }
  &__contents {
    // margin-top: 65px;
    @include mq(md) {
      // margin-top: 100px;
    }
  }
  &__contents-lead {
    color: $color-blue;
    font-size: 1.4rem;
    line-height: (29/14);
    margin-bottom: 38px;
    @include mq(md) {
      font-size: 1.5rem;
      line-height: (33/15);
      margin-bottom: 45px;
    }
  }
  &__contents-link-wrap {
    margin-top: 48px;
    @include mq(md) {
      margin-top: 77px;
    }
  }
  &__contents-section {
    &+& {
      margin-top: 86px;
    @include mq(md) {
      margin-top: 130px;
    }
    }
  }
  &__contents-image-wrap {
    margin-bottom: 53px;
    @include mq(md) {
      margin-bottom: 75px;
    }
  }
  &__contents-section-link-wrap {
    margin-top: 50px;
    @include mq(md) {
      margin-top: 55px;
    }
  }
  &__contents-title-matome {
    position: relative;
    margin-bottom: 38px;
    padding-bottom: 51vw;
    overflow: hidden;
    background: url(/assets/img/page/stories/coopkobe/03/img_matome.png) center bottom no-repeat;
    background-size: 90% auto;
    @include mq(md) {
      margin-bottom: 45px;
      padding: 0;
      background: url(/assets/img/page/stories/coopkobe/03/img_matome.png) 412px 10px no-repeat;
      background-size: 322px auto;
    }
    &::before {
      content: "";
      display: none;
      float: left;
      width: 10px;
      height: 213px;
      border-top: 1px solid #dbdbdb;
      border-bottom: 1px solid #dbdbdb;
      border-left: 1px solid #dbdbdb;
      @include mq(md) {
        display: block;
        width: 10px;
        height: 193px;
      }
    }
    &::after {
      content: "";
      display: none;
      float: right;
      width: 10px;
      height: 213px;
      border-top: 1px solid #dbdbdb;
      border-bottom: 1px solid #dbdbdb;
      border-right: 1px solid #dbdbdb;
      @include mq(md) {
        display: block;
        width: 10px;
        height: 193px;
      }
    }
    .matome-box {
      left: calc((100% - 250px)/2);
      box-sizing: border-box;
      background: #FFCA36;
      color: #0070B3;
      font-size: 10px;
      text-align: center;
      width: 60%;
      margin: 0 auto;
      line-height: 36px;
      border-radius: 18px;
      span {
        font-size: 16px;
      }
      @include mq(md) {
        float: left;
        width: auto;
        margin: 66px 0 0 52px;
        padding: 0 2em;
        font-size: 15px;
        line-height: 50px;
        border-radius: 25px;
        span {
          font-size: 22px;
        }
      }
    }
  }
  &__contents-yokoku {
    margin-top: 48px;
    text-align: center;
    @include mq(md) {
      margin-top: 77px;
    }
    p.yokoku-text {
      width: 20em;
      margin: 0 auto;
      padding: 150px 0 30px 0;
      @include mq(md) {
        width: auto;
        margin: 0;
        padding: 77px 0 0 36%;
      }
    }
    a:hover img {
      opacity: 0.7;
    }
  }
  &__additional {
    margin-top: 90px;
    @include mq(md) {
      margin-top: 170px;
    }
    .p-stories-sanpo__contents-image-wrap {
      margin-top: 53px;
      text-align: center;
      @include mq(md) {
        margin-top: 75px;
      }
      img {
        width: 90%;
        @include mq(md) {
          width: auto;
        }
      }
    }
  }
  &__additional-image-wrap {
    width: (277/345*100%);
    margin: 0 auto;
    @include mq(md) {
      width: 380px;
    }
  }
  &__additional-lead {
    color: $color-blue;
    line-height: 1.8;
    margin-top: 66px;
    @include mq(md) {
      line-height: (30/15);
      margin-top: 70px;
      padding-left: 155px;
    }
  }
  &__additional-cards {
    margin-top: 41px;
    @include mq(md) {
      margin-top: 48px;
    }
  }
  &__additional-card {
    @include mq(md) {
      display: flex;

    }
    &+& {
      margin-top: 55px;
      @include mq(md) {
        margin-top:79px;
      }
    }
  }
  &__additional-head {
    display: flex;
    align-items: center;
    @include mq(md) {
      display: block;
      margin-right: 42px;
      margin-top: 10px;
    }
  }
  &__additional-head-img {
    width: 98px;
    height: 108px;
    margin-right: 17px;
    @include mq(md) {
      width: 113px;
      height: 125px;
      margin-right: 0;
    }
  }
  &__additional-head-text {
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 20px;
    @include mq(md) {
      text-align: center;
      margin-top: 7px;
    }
    &--sm {
      display: block;
      font-size: 1.1rem;
    }
  }
  &__additional-body {
    margin-top: 26px;
    @include mq(md) {
      margin-top: 0;
    }
  }
  &__additional-body-text {
    font-size: 1.4rem;
    line-height: (29/14);
    letter-spacing: 0.09em;
    @include mq(md) {
      font-size: 1.3rem;
      line-height: (28/13);
    }
    &+& {
      margin-top: 30px;
    }
  }
  &__additional-link {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: (21/14);
    color: $color-blue;
    padding: 22px 17px 19px 20px;
    border: solid 1px #e2e2e2;
    background-color: #f7f7f7;
    margin-top: 22px;
    letter-spacing: 0.09em;
    @include mq(md) {
      font-size: 1.5rem;
      line-height: (23/15);
      padding: 23px 23px 17px 30px;
      margin-top: 34px;
    }
  }
  &__additional-link.mb {
    margin-bottom: 22px;
    @include mq(md) {
      margin-bottom: 34px;
    }
  }
  &__additional-link-text {
    text-decoration: underline;
    @include mq(md) {
    }
    &:hover {
      @include mq(md) {
        text-decoration: none;
      }
    }
  }

  //共通コンポーネント
  &-toc-list {
    visibility: visible;
    opacity: 1;
    position: fixed;
    z-index: 1;
    bottom: 20px;
    right: 15px;
    transition: opacity .3s;
    &.is-hide {
      visibility: hidden;
      opacity: 0;
    }
    @include mq(sp-only) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-toc-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    color: #fff;
    background-color: $color-blue;
    font-weight: 500;
    @include mq(sp-only) {
      transition: transform .3s;
      position: absolute;
    }
    &:nth-of-type(1) {
      .p-stories-sanpo-toc-list.is-spread & {
        @include mq(sp-only) {
          transform: translateY(-150px);
        }
      }
    }
    &:nth-of-type(2) {
      .p-stories-sanpo-toc-list.is-spread & {
        @include mq(sp-only) {
          transform: translateY(-100px);
        }
      }
    }
    &:nth-of-type(3) {
      .p-stories-sanpo-toc-list.is-spread & {
        @include mq(sp-only) {
          transform: translateY(-50px);
        }
      }
    }
    &:nth-of-type(4) {
      position: relative;
    }

    &.is-active {
      background-color: $color-secondary;
      z-index: 1;
      width: 46px;
      height: 46px;
      // &::before {
      //   @include mq(md) {
      //     content: "";
      //     position: absolute;
      //     background: url(/assets/img/page/stories/coopkobe/01/icon_foot.svg)  no-repeat center center;
      //     background-size: contain;
      //     width: 34px;
      //     height: 58px;
      //     z-index: 5;
      //     top: -17px;
      //     left: -39px
      //   }
      // }
    }

    &+& {
      @include mq(md) {
        margin-top: 5px;
      }
    }
  }
  &-toc-link {
    display: block;
    font-size: 1rem;
    font-weight: 700;
    -webkit-tap-highlight-color:rgba(0,0,0,0);
    cursor:pointer;
    outline: none;
    .p-stories-sanpo-toc-item.is-active & {
      color: $color-blue;
    }
  }
  //見出し
  &-heading--lev1 {
    font-size: 2.2rem;
    font-weight: 900;
    line-height: (35/22);
    letter-spacing: 0.09em;
    // margin-bottom: 35px;
    margin-bottom: 45px;
    @include mq(md) {
      font-size: 3.6rem;
      line-height: (56/36);
      margin-top: 38px;
      margin-bottom: 60px;
    }
  }
  &-heading--lev2 {
    font-size: 1.8rem;
    line-height: (27/18);
    font-weight: 900;
    margin-bottom: 40px;
    letter-spacing: 0.09em;
    @include mq(md) {
      font-size: 2.8rem;
      line-height: (42/28);
      margin-bottom: 48px;
    }
  }
  &-heading--lev3 {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    line-height: (24/16);
    font-weight: 900;
    margin-bottom: 35px;
    letter-spacing: 0.1em;
    @include mq(md) {
      font-size: 2rem;
      line-height: (36/20);
      margin-bottom: 37px;
    }
    &::before {
      content: "";
      display: inline-block;
      border-top: 2px solid;
      width: 5px;
      margin-right: 5px;
    }
    &.has-icon {
      &::before {
        display: block;
        flex-shrink: 0;
        content: "";
        background: url(/assets/img/share/tamkun_icon_01.svg)  no-repeat center center;
        background-size: contain;
        width: 36px;
        height: 40px;
        border: none;
        margin-right: 16px;
      }
    }
    .p-stories-sanpo__additional & {
      margin-bottom: 26px;
      @include mq(md) {
        margin-bottom: 30px;
      }
    }
  }
  &-figcaption {
    font-size: 1.1rem;
    line-height: (17/11);
    color: #666;
    letter-spacing: 0.1em;
    text-align: center;
    margin-top: 12px;
    @include mq(md) {
      font-size: 1.2rem;
      margin-top: 10px;
      letter-spacing: 0.13em;
    }
  }
  &-speaker {
    font-size: 1.4rem;
    font-weight: 700;
    @include mq(md) {
      font-size: 1.5rem;
    }
    &+.p-stories-sanpo-answer {
      margin-top: 10px;
      a {
        text-decoration: underline;
      }
      a:hover {
        text-decoration: none;
      }
    }
  }
  main.p-stories-sanpo.with-icon &-speaker {
    position: relative;
    padding-left: 60px;
    @include mq(md) {
      padding-left: 105px;
    }
    &+.p-stories-sanpo-answer {
      padding-left: 60px;
      @include mq(md) {
        padding-left: 105px;
      }
    }
  }
  &-speaker .face-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    @include mq(md) {
      width: 75px;
    }
  }
  &-answer {
    font-size: 1.4rem;
    line-height: 2.1;
    letter-spacing: 0.1em;
    @include mq(md) {
      font-size: 1.5rem;
      line-height: 2.2;
    }
    &+.p-stories-sanpo-speaker {
      margin-top: 30px;
      @include mq(md) {
        margin-top: 36px;
      }
    }
    main.p-stories-sanpo.with-icon &+.p-stories-sanpo-answer {
      padding-left: 60px;
      @include mq(md) {
        padding-left: 105px;
      }
    }
    &+.p-stories-sanpo__contents-image-wrap {
      margin-top: 50px;
    }
  }
  &-anotation {
    color: #666;
    border: solid 1px #e2e2e2;
    padding: 20px 20px 18px;
    font-size: 1.3rem;
    margin-top: 16px;
    letter-spacing: 0.1em;
    line-height: 2;
    @include mq(md) {
      margin-top: 28px;
      padding: 25px 22px 24px 34px;
      font-size: 1.4rem;
    }
    a {
      text-decoration: underline;
    }
    a:hover {
      text-decoration: none;
    }
  }

  &-link {
    display: block;
    color: #fff;
    background-color: $color-blue;
    padding: 24.2px 21px 20.9px 18px;
    font-size: 1.4rem;
    line-height: (22/14);
    font-weight: 500;
    @include mq(md) {
      padding: 27px 18px 27px 29px;
      display: flex;
      align-items: center;
    }
  }
  &-link-mark {
    display: inline-block;
    color: $color-blue;
    background-color: $color-secondary;
    padding: 6px 15px 5px;
    border-radius: 15px;
    font-size: 1.2rem;
    margin: 0 0 7px 0;
    @include mq(md) {
      display: block;
      padding: 6px 15px 5px;
      margin: 0 15px 0 0;
    }
  }
  &-link-text {
    display: block;
    letter-spacing: 0.15em;
    .p-stories-sanpo-link:hover & {
      @include mq(md) {
        text-decoration: underline;
      }
    }
  }
  .p-micromodal.is-open {
    z-index: 10;
    position: relative;
  }
  .p-micromodal__trigger-btn-wrap {
    position: absolute;
    right: 2%;
    bottom: 2.5%;
    @include mq(md) {
      right: 2%;
      bottom: 3%;
    }
  }
  .p-stories-sanpo__contents-image-contents {
    position: relative;
  }
  .p-micromodal__trigger-btn {
    width: 40px;
    height: 40px;
    background-color: #ffca36;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq(md) {
      width: 53px;
      height: 53px;
      cursor: pointer;
    }
    img {
      width: 18px;
      height: 18px;
      @include mq(md) {
        width: 23px;
        height: 23px;
      }
    }
  }
  .p-micromodal__overlay {
    background: rgba(0,0,0,.85);
  }
  .p-micromodal__container {
    padding: 25% 0 0;
    @include mq(md) {
      padding: 56px 2rem 0;
      max-width: 1440px;
    }
  }
  .p-micromodal__inner {
    padding: 0;
    // width: 100%;
    @include mq(md) {
      width: (1180/1440*100%);
      margin: 0 auto;
    }
  }
  .p-micromodal__btn-wrap {
    position: absolute;
    top: 1.5%;
    right: 1.5%;
    @include mq(md) {
      top: -1.4%;
      right: 1.5%;
    }
  }
  .p-micromodal__btn--icon {
    width: 44px;
    height: 44px;
    background-color: #fff;
    border-radius: 100%;
    margin-bottom: 30px;
    &::before,
    &::after {
      height: 15px;
      background-color: #000;
      @include mq(md) {
        height: 19px;
      }
    }
    @include mq(md) {
      width: 64px;
      height: 64px;

    }
  }
  .p-micromodal__text {
    color: #fff;
    font-size: 1.3rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    &::before {
      content: "";
      display: inline-block;
      background-image: url("/assets/img/page/stories/coopkobe/03/icon_scale_hand.svg");
      width: 30px;
      height: 33px;
      margin-right: 12px;
    }
    @include mq(md) {
      display: none;
    }
  }
  .is-blue {
    color: $color-blue;
  }
  .u-pst-relative {
    position: relative;
  }
}
.page-stories-sanpo {
  &.is-scrollFix {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
main.p-stories-sanpo p.p-stories-sanpo__about-text strong.gallery {
  display: block;
  padding-bottom: 1em;
  font-weight: bold;  
}
