.c-title {
  font-family: $font-ratin;
  font-size: 3.5rem;
  font-weight: bold;
  line-height: 1;
  color: $color-secondary;

  @include mq(md) {
    font-size: 6rem;
  }

  &--blue {
    color: $color-primary;
  }
}
