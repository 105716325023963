.c-text {
  letter-spacing: 0.0104em;
  font-size: 1.3rem;
  line-height: (50/26);

  &.comment {
    margin-left: 0.75em;
    text-indent: -0.75em;
    font-size: 1.2rem;
    line-height: (43/24);
  }

  @include mq(md) {
    max-width: 800px;
    font-size: 1.5rem;
    line-height: (33/15);

    &.comment {
      font-size: 1.3rem;
      line-height: (21/13);
    }
  }

  &--m {
    font-size: 1.4rem;

    @include mq(md) {
      font-size: 1.7rem;
    }
  }

  & + & {
    margin-top: 1.5em;
  }

  &--lead {
    @include mq(md) {
      max-width: 625px;
    }
  }

  &--wide {
    @include mq(md) {
      max-width: 100%;
    }
  }

  &--with-figure {
    @include mq(md) {
      max-width: 1000px;
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
    }
  }

}

//以下 English Ver. 記述

.en {
  .c-text {
    &--work {
      font-family: $enBaseFontFamily;
      font-size: 1.6rem;
      line-height: 1.8;

      @include mq(md) {
        font-size: 1.7rem;
        line-height: 2;
      }
    }
  }
}
