.c-headline-line {
  position: relative;
  margin-top: 20px;
  padding: 0 (14 / 375 * 100%);

  @include mq(md) {
    display: flex;
    align-items: center;
    padding: 0;
  }

  &__en {
    font-family: $font-ratin, $font-jp;
    font-size: 7.4rem;
    font-weight: bold;
    line-height: 1.1;
    color: $color-secondary;

    @include mq(md) {
      margin-right: 35px;
      font-size: 11rem;
    }

    .c-headline-line--s & {
      font-size: 5.5rem;
      line-height: (102/110);

      @include mq(md) {
        font-size: 11rem;
      }
    }
  }

  &__jp {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 5px;
    font-size: 1.6rem;
    font-weight: bold;
    color: $color-primary;

    @include mq(md) {
      width: 100%;
      font-size: 2.4rem;
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: $color-gray-300;

      @include mq(md) {
        width: calc(100% - 130px);
      }
    }
  }

  &__jp-text {
    flex-shrink: 0;
    padding-right: 10px;
  }
}

//以下 English Ver. 記述

.en {
  .c-headline-line {
    &__jp {
      font-size: 1.8rem;
      line-height: 1.5;
      @include mq(md) {
        font-size: 2.4rem;
      }
    }

    &__jp--recruit {

      &::after {
        display: none;

        @include mq(md) {
          content: "";
          width: 100%;
          height: 1px;
          background-color: $color-gray-300;
          display: block;
        }
      }
    }
  }
}
