.p-top-section {
  margin-top: 40px;

  @include mq(md) {
    margin-top: 136px;
  }

  & + & {
    margin-top: 100px;

    @include mq(md) {
      margin-top: 200px;
    }
  }

  &__inner {
    padding: 0 (36 / 375 * 100%);

    @include mq(md) {
      padding: 0;
    }

    .p-top-section--medium & {
      @include mq(md) {
        max-width: 1240px;
        margin: 0 auto;
        padding: 0 20px;
      }
    }
  }

  &__text {
    margin-top: 35px;
    font-size: 1.2rem;
    line-height: (42/24);

    @include mq(md) {
      margin-top: 0;
      padding-left: (320 / 1440 * 100%);
      font-size: 1.5rem;
      line-height: (33/15);
    }

    .is-start & {
      opacity: 1;
      transform: translateY(0);
    }

    &--work {
      @include mq(md) {
        max-width: 612px;
      }

      @include mq(pc) {
        max-width: calc((542 / 1440 * 100vw) + 22.222%);
      }
    }

    &--service {
      @include mq(md) {
        padding-left: (360 / 1440 * 100%);
      }
    }

    &--about {
      margin-top: 0;
      text-align: center;
      letter-spacing: 0;
      font-size: 1.2rem;

      @include mq(md) {
        position: relative;
        z-index: 2;
        padding: 30px 0 0 0;
        font-size: 1.5rem;
      }
    }
  }
}

//以下 English Ver. 記述

.en {
  .p-top-section {
    &__text {
      font-size: 1.4rem;
      line-height: 1.8;

      @include mq(md) {
        font-size: 1.5rem;
      }
    }

    &__text--about {
      font-size: 1.4rem;
      line-height: 2;

      @include mq(md) {
        font-size: 1.7rem;
      }
    }
  }
}
