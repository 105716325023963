.p-footer {
  position: relative;
  padding: 35px 35px 60px;

  @include mq(md) {
    max-width: 1240px;
    margin: 0 auto;
    padding: 70px 20px 150px;
  }

  &__inner {
    @include mq(md) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__list {
    border-bottom: 1px solid $color-gray-300;

    @include mq(md) {
      display: flex;
      margin: 0 -15px;
      border: none;
    }
  }

  &__list-item {
    border-top: 1px solid $color-gray-300;

    @include mq(md) {
      padding: 0 15px;
      border: none;
    }
  }

  &__list-link {
    display: block;
    position: relative;
    padding: 15px 22px 15px 0;
    font-size: 1.2rem;
    font-weight: 500;

    @include mq(md) {
      padding: 0 0 5px 0;
      white-space: nowrap; // for Safari
      font-size: 1.4rem;
    }

    &::before,
    &::after {
      @include mq(md) {
        content: "";
        display: block;
        position: absolute;
        bottom: -1px;
        width: 0;
        height: 2px;
        background-color: $color-black;
        transition: width 0.3s ease;
      }
    }

    &::before {
      right: 50%;
    }

    &::after {
      left: 50%;
    }

    &:hover {
      &::before,
      &::after {
        @include mq(md) {
          width: 50%;
        }
      }
    }
  }

  &__list-icon {
    position: absolute;
    top: 50%;
    right: 0;
    width: 22px;
    height: 22px;
    transform: translateY(-50%);

    @include mq(md) {
      display: none;
    }
  }

  &__sub-list {
    margin-top: 30px;

    @include mq(md) {
      margin: 10px -0.5em 0;
    }
  }

  &__sub-list-item {
    @include mq(md) {
      display: inline-block;
      position: relative;
      padding: 0 0.5em;
    }

    & + & {
      margin-top: 0.5em;

      @include mq(md) {
        margin-top: 0;
      }

      &::before {
        @include mq(md) {
          content: "|";
          position: absolute;
          left: -5px;
          color: $color-gray-200;
        }
      }
    }
  }

  &__sub-list-link {
    position: relative;
    padding-right: 30px;
    letter-spacing: -0.02em;
    font-size: 1.2rem;
    color: $color-gray-500;

    @include mq(md) {
      padding: 0 0 3px 0;
      white-space: nowrap; // for Safari
      font-size: 1.4rem;
    }

    &::before,
    &::after {
      @include mq(md) {
        content: "";
        display: block;
        position: absolute;
        bottom: -1px;
        width: 0;
        height: 1px;
        background-color: $color-gray-200;
        transition: width 0.3s ease;
      }
    }

    &::before {
      right: 50%;
    }

    &::after {
      left: 50%;
    }

    &:hover {
      &::before,
      &::after {
        @include mq(md) {
          width: 50%;
        }
      }
    }
  }

  &__sub-list-icon {
    position: absolute;
    top: 50%;
    right: 0;
    width: 22px;
    height: 22px;
    transform: translateY(-50%);
    fill: $color-gray-500;

    @include mq(md) {
      display: none;
    }
  }

  &__sns {
    width: 200px;
    margin-top: 20px;

    @include mq(md) {
      display: flex;
      align-items: center;
      width: 240px;
      margin-top: 0;
    }
  }

  &__sns-inner {
    @include mq(md) {
      width: 100%;
      margin-right: 32px;
    }
  }

  &__sns-title {
    display: inline-block;
    position: relative;
    padding-right: calc(100% - 72px);
    font-family: $font-ratin;
    font-size: 1.4rem;
    font-weight: bold;

    @include mq(md) {
      width: 100%;
      padding-right: 50px;
      font-size: 1.5rem;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      width: calc(100% - 90px);
      height: 1px;
      background-color: $color-black;
      transform: translateY(-50%);

      @include mq(md) {
        width: 38px;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    gap: 25px;
    @include mq(md) {
      gap: 23px;
    }
  }

  &__sns-list {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    @include mq(md) {
      margin: 10px -8px 0;
    }
  }

  &__sns-item {
    @include mq(md) {
      padding: 0 8px;
    }
  }

  &__sns-link {
    display: block;
  }

  &__sns-icon {
    width: 30px;
    height: 30px;

    @include mq(md) {
      width: 20px;
      height: 20px;
    }
  }

  &__privacy {
    position: absolute;
    right: 35px;
    bottom: 40px;
    width: 50px;

    @include mq(md) {
      flex-shrink: 0;
      position: static;
      width: 74px;
    }
  }

  &__copy {
    margin-top: 56px;
    font-size: 1rem;
    color: $color-gray-500;

    @include mq(md) {
      margin-top: -33px;
      font-size: 1.1rem;
    }
  }

  .c-language-swicher {
    @include mq(md) {
      display: flex;
      justify-content: end;
    }
  }
}

//以下 English Ver. 記述

.en {
  .p-footer {
    &__list-link {
      font-size: 1.4rem;
      @include mq(md) {
        font-size: 1.6rem;
      }
    }
    &__sub-list-link {
      font-size: 1.4rem;
      font-weight: 400;
      letter-spacing: $enBaseLetterSpacing;
      @include mq(md) {
        font-size: 1.6rem;
      }
    }
  }
}
