.c-language-swicher {
  padding-bottom: 0.1em;
  @include mq(md) {
    padding-bottom: 0.5em;
  }
  @include mq(md) {
    padding-bottom: 1em;
  }
  &__list {
    display: flex;
  }
  &__item {
    display: flex;
    align-items: center;
  }
  //JPとENの間に縦線を入れる
  &__item:first-child::after {
    content: "";
    display: inline-block;
    background-color: $color-black;
    width: 1px;
    height: 12px;
    margin: 0 8px;
  }
  &__link {
    position: relative;
    font-family: $enBaseFontFamily;
    letter-spacing: $enBaseLetterSpacing;
    font-size: 1.4rem;
    font-weight: 700;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: -1px;
      width: 0;
      height: 1px;
      background-color: $color-black;
      transition: width 0.3s ease;
    }

    &::before {
      right: 50%;
    }

    &::after {
      left: 50%;
    }

    &:hover {
      &::before,
      &::after {
        width: 50%;
      }
    }
  }

  &__text {
    position: relative;
    font-family: $enBaseFontFamily;
    letter-spacing: $enBaseLetterSpacing;
    font-size: 1.4rem;
    font-weight: 700;
    color: $color-primary;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 1px;
      background-color: $color-primary;
    }
  }
}
