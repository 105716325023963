// Micromodal.jsモーダル標準モジュール
// --------------------------------------------------
.p-micromodal__overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, .6);
  overflow-y: auto;
}

.p-micromodal__container {
  // overflow-y: auto;
  box-sizing: border-box;
  // max-width: 500px;
  // max-height: 100vh;
  padding: 0 0 30px;
  border-radius: 4px;
  // background-color: #fff;
  position: absolute;
  top: 0;
  @media screen and ( min-width : 767px ) and ( max-width : 980px ){
    padding: 80px 0 130px;
  }
  @include mq(md) {
    padding: 80px 100px 130px;
    margin-top: 30px;
  }
}

.p-micromodal__inner {
  width: 100%;
  // max-width: 980px;
  max-height: auto !important;
  overflow-y: visible;
  // background-image: url('../img/kashikiri/bg_kashikiri_white.png');
  background-repeat: repeat;
  background-size: cover;
  padding: get_vw(80, 710) get_vw(60, 710) get_vw(120, 710);
  @media screen and ( min-width : 767px ) and ( max-width : 980px ){
    max-width: 980px;
    width: auto;
  }
  @include mq(md) {
    width: 980px;
    padding: 80px 100px;
  }
}

.p-micromodal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-micromodal__title {
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.25;
  color: #00449e;
}

.p-micromodal__close {
  border: 0;
  background: transparent;
}

.p-micromodal__header .p-micromodal__close::before {
  content: "\2715";
}

.p-micromodal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, .8);
}

.p-micromodal__btn-wrap {
  display: flex;
  justify-content: flex-end;
}

.p-micromodal__btn--icon {
  width: 50px;
  height: 50px;
  background-color: #ba9d73;
  position: relative;
  cursor: pointer;
  transition: opacity 0.3s;
  @include mq(md) {
    width: 70px;
    height: 70px;
  }
  &:hover {
    opacity: 0.9;
  }
  &::before,
  &::after {
    content:'';
    width: 1px;
    height: 20px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include mq(md) {
      height: 28px;
    }
  }
  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.p-micromodal__btn--round {
  width: get_vw(550, 710);
  height: 60px;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ba9d73;
  margin: get_vw(60, 710) auto 0;
  cursor: pointer;
  @include mq(md) {
    width: 400px;
    margin: 30px auto 0;
    height: 70px;
  }
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.9;
  }

  span {
    color: #fff;
    font-size: get_vw(30, 710);
    position: relative;
    @include mq(md) {
      font-size: 2.1rem;
    }
    &::before,
    &::after {
      content:'';
      width: 1px;
      height: 12px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left:  get_vw(-167, 710);
      @include mq(md) {
        height: 24px;
        left: -110px;
      }
    }
    &::before {
      transform: translateY(-50%) rotate(-45deg);
    }
    &::after {
      transform: translateY(-50%) rotate(45deg);
    }
  }
}

.p-micromodal__btn {
  overflow: visible;
  margin: 0;
  padding-top: .5rem;
  padding-right: 1rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  text-transform: none;
  font-size: .875rem;
  line-height: 1.15;
  border-width: 0;
  border-style: none;
  border-radius: .25rem;
  background-color: #e6e6e6;
  color: rgba(0, 0, 0, .8);
  cursor: pointer;
  transition: -webkit-transform .25s ease-out;
  transition: transform .25s ease-out;
  transition: transform .25s ease-out, -webkit-transform .25s ease-out;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-appearance: button;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  will-change: transform;
}

.p-micromodal__btn:focus,
.p-micromodal__btn:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.p-micromodal__btn-primary {
  background-color: #00449e;
  color: #fff;
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10%);
  }
}

.p-micromodal-slide {
  display: none;
}

.p-micromodal.is-open {
  display: block;
}

.p-micromodal-slide[aria-hidden="false"] .p-micromodal__overlay {
  animation: mmfadeIn .3s cubic-bezier(0, 0, .2, 1);
}

.p-micromodal-slide[aria-hidden="false"] .p-micromodal__container {
  animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.p-micromodal-slide[aria-hidden="true"] .p-micromodal__overlay {
  animation: mmfadeOut .3s cubic-bezier(0, 0, .2, 1);
}

.p-micromodal-slide[aria-hidden="true"] .p-micromodal__container {
  animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.p-micromodal-slide .p-micromodal__container,
.p-micromodal-slide .p-micromodal__overlay {
  will-change: transform;
}
