.p-header {
  position: relative;
  z-index: 4;

  &__inner {
    display: flex;
    align-items: center;
    height: 44px;
    margin: 16px 0;
    padding: 0  82px 0 16px;
    @include mq(sp-only) {
      justify-content: space-between;
    }

    @include mq(md) {
      height: 110px;
      margin: 0;
      padding: 0 138px 0 26px;
    }
  }

  &__logo-link {
    display: block;
    width: 40px;

    @include mq(md) {
      width: 85px;
    }
  }

  &__menu-button {
    position: fixed;
    top: 16px;
    right: 16px;
    z-index: 5;
    width: 44px;
    height: 44px;
    border-radius: 22px;
    background-color: $color-secondary;
    cursor: pointer;

    @include mq(md) {
      top: 15px;
      right: 24px;
      width: 64px;
      height: 64px;
      border-radius: 32px;
    }

    &.is-open {
      background-color: #fff;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      width: 13px;
      height: 1px;
      background-color: $color-black;
      transform: translate(-50%, -50%);
      transform-origin: center;

      @include mq(md) {
        width: 18px;
        height: 2px;
      }
    }

    &::before {
      top: calc(50% - 3px);

      @include mq(md) {
        top: calc(50% - 4px);
      }
    }

    &::after {
      top: calc(50% + 4px);

      @include mq(md) {
        top: calc(50% + 5px);
      }
    }

    &.is-open {
      &::before {
        top: calc(50%);
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        top: calc(50%);
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  &__nav {
    margin-left: auto;
    padding-right: 80px;
    @include mq(md) {
      padding-right: (45 / 1440 * 100vw);
    }
  }

  &__nav-list {
    display: flex;
    margin: 15px -23px 0;

    @include mq(md) {
      margin-top: 0;
    }
  }

  &__nav-item {
    padding: 0 18px;
  }

  &__nav-link {
    display: block;
    position: relative;
    padding-bottom: 1em;
    font-size: 1.4rem;
    font-weight: 500;

    &::before,
    &::after {
      @include mq(md) {
        content: "";
        display: block;
        position: absolute;
        bottom: -1px;
        width: 0;
        height: 2px;
        background-color: $color-black;
        transition: width 0.3s ease;
      }
    }

    &::before {
      right: 50%;
    }

    &::after {
      left: 50%;
    }

    &:hover {
      &::before,
      &::after {
        @include mq(md) {
          width: 50%;
        }
      }
    }
  }
}

//以下 English Ver. 記述

.en {
  .p-header {
    .p-header__nav-link {
      font-size: 1.6rem;
    }
  }
}
