.c-scroll-fadein {
  opacity: 0;
  transition: opacity 1s ease, transform 1s ease;
  transform: translateY(50px);

  &--down {
    transform: translateY(30px);
  }

  &.is-show {
    opacity: 1;
    transform: translateY(0);
  }
}
