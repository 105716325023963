.lazyload,
.lazyloading {
  opacity: 0;
  transition: all 0.3s ease;
}

.lazyloaded {
  opacity: 1;
  transition: all 0.3s ease;
}
