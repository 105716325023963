.c-section-contact {
  position: relative;
  margin-top: 60px;
  padding-top: 150px;

  @include mq(md) {
    overflow: hidden;
    margin: 100px -20px 0;
  }

  &__tamkun {
    position: absolute;
    top: 0;
    left: (60/375 * 100%);
    width: (120/375 * 100%);
    max-width: 120px;

    @include mq(md) {
      top: 120px;
      left: 96px;
      z-index: 3;
      width: 311px;
      max-width: 311px;
    }
  }

  &__logo {
    position: absolute;
    top: 0;
    left: (200 / 375 * 100%);
    width: (150/375 * 100%);
    max-width: 150px;

    @include mq(md) {
      top: 70px;
      right: auto;
      left: 410px;
      z-index: 3;
      width: 204px;
      max-width: 204px;
    }
  }

  &__inner {
    position: relative;
    padding: 50px (36 / 375 * 100%);
    background-color: $color-primary;
    color: #fff;

    @include mq(md) {
      padding: 70px 12.29167% 70px
        calc(311px + (110 / 1440 * 100%) + (200 / 1440 * 100%));
    }
  }

  &__title {
    font-size: 1.9rem;
    font-weight: bold;

    @include mq(md) {
      font-size: 2.4rem;
    }
  }

  &__text {
    margin-top: 20px;
    font-size: 1.2rem;
    line-height: (42/24);

    @include mq(md) {
      font-size: 1.4rem;
      line-height: (28/14);
    }
  }

  &__link-button {
    max-width: 300px;
    margin: 30px auto 0;

    @include mq(md) {
      max-width: 372px;
      margin: 50px 0 0 0;
    }
  }
}

//以下 English Ver. 記述

.en {
  .c-section-contact {
    &__inner {
      padding: 50px (34 / 375 * 100%);

      @include mq(md) {
        padding: 70px 9% 70px
          calc(311px + (110 / 1440 * 100%) + (200 / 1440 * 100%));
      }
    }

    &__title {
      font-size: 1.9rem;
      font-weight: 700;

      @include mq(md) {
        font-size: 2.6rem;
      }
    }
    &__text {
      font-size: 1.4rem;

      @include mq(md) {
        font-size: 1.6rem;
      }
    }
  }
}
