.p-stories-list {
  margin-top: 20px;
  @include mq(md) {
    margin-top: 108px;
  }
  // @include button_reset;
  button {
    background: none;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: $baseFontFamily;
  }
  &__container {
    padding: 0 (15 / 375 * 100%);
  }

  &__tab {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @include mq(sp-only) {
      width: (320/345 * 100%);
      margin: 0 auto;
    }
    @include mq(md) {
    }
  }
  &__tab-item {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 22px;
    border: 1px solid #ededed;
    @include mq(sp-only) {
      width: (157/320 * 100%);
      margin-right: (6/320 * 100%);
      margin-top: 12px;
    }
    & + .p-stories-list__tab-item {
      @include mq(md) {
        margin-left: 8px;
      }
    }
    &:nth-of-type(2n) {
      @include mq(sp-only) {
        margin-right: 0;
      }
    }
    &:nth-of-type(-n + 2) {
      @include mq(sp-only) {
        margin-top: 0;
      }
    }
    &:hover {
      @include mq(md) {
        text-decoration: underline;
      }
    }
    &.is-active {
      background-color: $color-primary;
      color: #fff;
      border: 1px solid $color-primary;
    }
  }
  &__tab-btn {
    display: block;
    font-weight: 500;
    font-size: 1.1rem;
    letter-spacing: 0.08em;
    padding: 11px 5px;
    color: $color-black;
    @include mq(md) {
      font-size: 1.2rem;
      padding: 7px 15px;
      cursor: pointer;
      letter-spacing: 0.1em;
    }
    .p-stories-list__tab-item.is-active & {
      color: #fff;
    }
    &:hover {
      @include mq(md) {
        text-decoration: underline;
      }
    }
  }
  &__item-wrap {
    @include mq(md) {
    }
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    // margin-top: 40px;
    margin: 40px -5px -25px;
    @include mq(md) {
      margin-top: 24px;
      margin: 24px -20px -25px;
    }
  }
  &__item {
    width: 50%;
    padding: 0 5px 25px;
    @include mq(md) {
      // width: (270/1200 * 100%);
      width: (1/4 * 100%);
      padding: 0 10px 64px;
      // margin: 64px (40/1200 * 100%) 0 0;
    }
    // &:nth-of-type(4n) {
    //   @include mq(md) {
    //     margin-right: 0;
    //   }
    // }
    // &:nth-of-type(-n + 4) {
    //   @include mq(md) {
    //     margin-top: 0;
    //   }
    // }
  }
  &__item-link {
    @include mq(md) {
    }
  }
  &__item-figure {
    margin-bottom: 13px;
    @include mq(md) {
      margin-bottom: 20px;
    }
  }
  &__item-image {
    vertical-align: middle;
    transition: transform 0.3s ease;

    &--border {
      border: 1px solid $color-gray-100;
    }
    .p-stories-list__item-figure:hover & {
      @include mq(md) {
        transform: scale(0.95);
        transform-origin: center;
      }
    }
  }
  &__item-date {
    font-family: "Barlow Semi Condensed";
    color: #c4c4c4;
    font-weight: 500;
    font-size: 1.2rem;
    @include mq(sp-only) {
      display: block;
    }
    @include mq(md) {
      display: block;
      margin-top: 15px;
      font-size: 1.4rem;
      margin-right: 14px;
    }
    @include mq(pc-content) {
      display: inline;
    }
  }
  &__item-tag {
    font-size: 1rem;
    border: 1px solid $color-primary;
    padding: 1px 2px;
    color: $color-primary;
    font-weight: 500;
    display: inline-block;
    margin-top: 5px;
    letter-spacing: 0.05em;
    @include mq(md) {
      display: inline;
      margin-top: 0;
    }
  }
  &__item-title {
    margin-top: 8px;
    font-size: 1.3rem;
    font-weight: 500;
    @include mq(md) {
      font-size: 1.8rem;
      margin-top: 17px;
    }
  }
  &__item-text {
    color: #999999;
    font-size: 1rem;
    margin-top: 8px;
    @include mq(md) {
      font-size: 1.2rem;
      margin-top: 16px;
    }
  }
  &__pager {
    margin-top: 80px;
  }
  &__link-button {
    max-width: 300px;
    margin: 40px auto 0;
    @include mq(md) {
      max-width: 396px;
      margin: 80px auto 0;
    }
  }
}
